import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  selected: null,
  kinds: null,
  published: null,
  post: null,
  list: null
};

const slice = createSlice({
  name: 'cms',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedPost(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    setSinglePost(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    setPostsSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    setPublishedPostsSuccess(state, action) {
      state.isLoading = false;
      state.published = action.payload;
    },

    setPostsKinds(state, action) {
      state.isLoading = false;
      state.kinds = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedPost } = slice.actions;

export function getPublishedPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/contents/published', {
        params: { paging: false }
      });

      dispatch(slice.actions.setPublishedPostsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPosts(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/contents', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.setPostsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPost(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/contents/${id}`);
      dispatch(slice.actions.setSelectedPost(response.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPublishedPost(tag) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/contents/read/${tag}`);
      dispatch(slice.actions.setSinglePost(response.data));
      dispatch(slice.actions.stopLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePost(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/contents/${id}`);
      dispatch(getPosts());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdatePost(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === null || params.id === '') {
        await axios.post('/api/contents', { content: params });
      } else {
        await axios.put(`/api/contents/${params.id}`, { content: params });
      }
      dispatch(getPosts());
      // dispatch(setSelectedPost(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPostKinds() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/contents/kinds');
      dispatch(slice.actions.setPostsKinds(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
