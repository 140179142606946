// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_ADMIN = '/admin';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  news: '/news',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  dataManagement: {
    dashboard: path(ROOTS_ADMIN, '/dashboard'),
    dataFiles: path(ROOTS_ADMIN, '/datafiles'),
    validation: path(ROOTS_ADMIN, '/validation'),
    invoices: path(ROOTS_ADMIN, '/invoices'),
    exports: path(ROOTS_ADMIN, '/exports'),
    samples: path(ROOTS_ADMIN, '/samples')
  },
  cms: {
    root: path(ROOTS_ADMIN, '/publish'),
    posts: path(ROOTS_ADMIN, '/publish/posts'),
    data: path(ROOTS_ADMIN, '/publish/data')
  },
  dataSettings: {
    root: path(ROOTS_ADMIN, '/settings'),
    states: path(ROOTS_ADMIN, '/settings/states'),
    elements: path(ROOTS_ADMIN, '/settings/elements'),
    elementGroups: path(ROOTS_ADMIN, '/settings/elementgroups'),
    dataSets: path(ROOTS_ADMIN, '/settings/datasets'),
    customFrequencies: path(ROOTS_ADMIN, '/settings/customfrequencies'),
    incentives: path(ROOTS_ADMIN, '/settings/incentives'),
    paymentRules: path(ROOTS_ADMIN, '/settings/paymentrules'),
    invoicesTypes: path(ROOTS_ADMIN, '/settings/invoicetypes'),
    workFlows: path(ROOTS_ADMIN, '/settings/workflows'),
    integration: path(ROOTS_ADMIN, '/settings/integration')
  },
  pyramidSettings: {
    root: path(ROOTS_ADMIN, '/pyramid'),
    groupSets: path(ROOTS_ADMIN, '/pyramid/grpsets'),
    groups: path(ROOTS_ADMIN, '/pyramid/groups'),
    banks: path(ROOTS_ADMIN, '/pyramid/banks'),
    levels: path(ROOTS_ADMIN, '/pyramid/levels'),
    entities: path(ROOTS_ADMIN, '/pyramid/entities')
  },
  aclSettings: {
    root: path(ROOTS_ADMIN, '/acl'),
    users: path(ROOTS_ADMIN, '/acl/users'),
    roles: path(ROOTS_ADMIN, '/acl/roles')
  }
};
