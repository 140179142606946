import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// material
import { useTheme } from '@mui/material/styles';
import { Stack, IconButton, InputAdornment, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { FormProvider, RHFTextField } from '../hook-form';

import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useLocales from '../../hooks/useLocales';
import Iconify from '../Iconify';
import { isNilOrBlank } from '../../utils/core';

// ----------------------------------------------------------------------

LoginForm.propTypes = {
  isConfirming: PropTypes.bool,
  isResetting: PropTypes.bool
};

export default function LoginForm({ isConfirming, isResetting }) {
  const theme = useTheme();
  const { logIn } = useAuth();
  const { translate: t } = useLocales();
  const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t('login.invalidEmailFormat')).required(t('login.invalidEmailRequired')),
    password: Yup.string()
      .notOneOf([Yup.ref('email')], t('login.passwordEmailSame'))
      .min(
        6,
        t('login.passwordLengthError', {
          minNumber: 6
        })
      )
      .required(t('login.passwordRequired'))
  });

  const defaultValues = {
    email: isNilOrBlank(process.env.REACT_APP_USER_EMAIL) ? '' : process.env.REACT_APP_USER_EMAIL,
    password: isNilOrBlank(process.env.REACT_APP_USER_PASSWORD) ? '' : process.env.REACT_APP_USER_PASSWORD
  };

  const methods = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues
  });

  const {
    reset,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods;

  const onSubmit = async (values) => {
    if (isMountedRef.current) {
      await logIn({ email: values.email, password: values.password });
      reset();
    }
  };

  return (
    <Box
      sx={{
        margin: theme.spacing(0, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      }}
      children={
        <>
          <Typography
            component="div"
            variant="subtitle1"
            color="primary"
            sx={{
              marginBottom: theme.spacing(3),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignText: 'center'
            }}
          >
            {t('login.signInTitle')}
          </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <RHFTextField
                autoFocus
                fullWidth
                disabled={isSubmitting || isConfirming || isResetting}
                name="email"
                type="email"
                label={t('login.emailAddress')}
                error={errors.email}
              />

              <RHFTextField
                fullWidth
                disabled={isSubmitting || isConfirming || isResetting}
                name="password"
                label={t('login.password')}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={errors.password}
              />

              <LoadingButton
                sx={{ textTransform: 'initial' }}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loadingPosition="start"
                loading={isSubmitting || isConfirming || isResetting}
                startIcon={<Iconify icon={'simple-line-icons:login'} />}
              >
                {isSubmitting === false && isConfirming === false && isResetting === false && t('login.connectButton')}
                {isSubmitting === true && t('login.connectButtonPressed')}
                {isConfirming === true && t('login.confirmationLoading')}
                {isResetting === true && t('login.resettingLoading')}
              </LoadingButton>
            </Stack>
          </FormProvider>
        </>
      }
    />
  );
}
