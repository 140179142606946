import PropTypes from 'prop-types';
import { Tooltip, ToggleButton } from '@mui/material';
// hooks
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

LanguagePopover.propTypes = {
  availableLanguages: PropTypes.array
};

export default function LanguagePopover({ availableLanguages }) {
  const { allLangs, currentLang, onChangeLang } = useLocales();

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
  };

  return allLangs.map(
    (lang) =>
      availableLanguages.includes(lang.value) && (
        <Tooltip arrow placement="bottom-start" key={`lang-${lang.value}`} title={lang.label}>
          <ToggleButton
            value={currentLang.value}
            selected={currentLang.value === lang.value}
            onClick={() => handleChangeLang(lang.value)}
            sx={{
              width: 24,
              height: 24,
              margin: 0.1,
              padding: 0,
              border: 0,
              fontSize: currentLang.value === lang.value ? 11 : 10,
              fontWeight: currentLang.value === lang.value ? 'bold' : 'normal'
            }}
          >
            {lang.value.toUpperCase()}
          </ToggleButton>
        </Tooltip>
      )
  );

  /* return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && { bgcolor: 'action.selected' })
        }}
      >
        {currentLang.label}
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 }
        }}
      >
        <Stack spacing={0.75}>
          {allLangs.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => handleChangeLang(option.value)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  ); */
}
