import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: false,
  selected: null,
  currentPage: 1,
  exportsUsers: null,
  exportsTemplates: null,
  formulas: null,
  shared: 0,
  years: null,
  list: null,
  dataValueFormOpen: false,
  formulaValueFormOpen: false
};

const slice = createSlice({
  name: 'export',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setExportsUsers(state, action) {
      state.isLoading = false;
      state.exportsUsers = action.payload;
    },

    setExportsTemplates(state, action) {
      state.isLoading = false;
      state.exportsTemplates = action.payload;
    },

    setSelectedExport(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    setFormulas(state, action) {
      state.isLoading = false;
      state.formulas = action.payload;
    },

    setYears(state, action) {
      state.isLoading = false;
      state.years = action.payload;
    },

    getExportsSuccess(state, action) {
      state.isLoading = false;
      state.currentPage = action.payload.all.pager.current_page;
      state.list = action.payload.all;
      state.shared = action.payload.shared;
    },

    // SET FORMOPEN
    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload.open === false) {
        state.selected = null;
      }
      if (action.payload.type === 'dataValue') {
        state.dataValueFormOpen = action.payload.open;
      }
      if (action.payload.type === 'formulaValue') {
        state.formulaValueFormOpen = action.payload.open;
      }
    }
  }
});

// Reducer
export default slice.reducer;

export const { setFormOpen, setSelectedExport, years } = slice.actions;

export function getExport(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/exports/${id}`);
      dispatch(slice.actions.setSelectedExport(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteExport(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/exports/${id}`);
  };
}

export function duplicateExport(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.post('/api/exports/duplicate/', { export: { id } });
  };
}

export function getExports(params = undefined) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { currentPage } = getState().export;
    try {
      const response = await axios.get('/api/exports', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : currentPage,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });
      dispatch(slice.actions.getExportsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getExportsTemplates() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/exports/templates');

      dispatch(slice.actions.setExportsTemplates(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getExportsUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/exports/users');
      dispatch(slice.actions.setExportsUsers(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getYears() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/exports/years');
      dispatch(slice.actions.setYears(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFormulas() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/exports/formulas');
      dispatch(slice.actions.setFormulas(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function refreshExportFile(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get('/api/exports/refresh', {
        params: {
          id: params.id,
          mail: params.mail
        }
      });

      dispatch(getExports());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function shareExportFiles(id = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/exports/share', { export: { id: id !== undefined ? id : null } });

      dispatch(getExports());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateExports(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/exports', { export: params });
      } else {
        await axios.put(`/api/exports/${params.id}`, { export: params });
      }
      dispatch(getExports());
      dispatch(setFormOpen({ open: false, type: 'formulaValue' }));
      dispatch(setFormOpen({ open: false, type: 'dataValue' }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
