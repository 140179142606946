import { createSlice } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  filter: {
    formOpen: false,
    params: undefined,
    applyied: undefined
  },
  currentPage: 1,
  error: false,
  selected: null,
  list: null,
  userDataSets: null,
  wizard: null,
  validation: {
    isLoading: false,
    entities: null,
    kind: null,
    metro: {
      isLoading: false,
      params: { period: null, entity: { main: [], selectors: [] } },
      status: null
    }
  },
  simulation: {
    isLoading: false,
    data: null
  }
};

const slice = createSlice({
  name: 'dataFile',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getDataFileListSuccess(state, action) {
      state.isLoading = false;
      state.currentPage = action.payload.pager.current_page;
      state.list = action.payload;
    },

    startValidationParamsLoading(state) {
      state.isLoading = true;
      state.validation = { ...state.validation, isLoading: true };
    },

    startValidationSimulationDataLoading(state) {
      state.isLoading = true;
      state.simulation = { ...state.simulation, isLoading: true };
    },

    setValidationSimulationData(state, action) {
      state.isLoading = true;
      state.simulation = { ...state.simulation, isLoading: false, data: action.payload };
    },

    startValidationStatusLoading(state) {
      state.isLoading = false;
      state.validation = {
        ...state.validation,
        metro: { ...state.validation.metro, isLoading: true },
        isLoading: false
      };
    },

    getValidationParams(state, action) {
      state.isLoading = false;
      state.validation = {
        ...state.validation,
        isLoading: false,
        entities: action.payload.entities,
        user_steps: action.payload.user_steps
      };
    },

    getUserDataSetsSuccess(state, action) {
      state.userDataSets = action.payload;
    },

    setValidationType(state, action) {
      state.isLoading = false;
      state.validation = {
        ...state.validation,
        kind: action.payload
      };
    },

    setValidationMetroParams(state, action) {
      state.isLoading = false;
      state.validation = {
        ...state.validation,
        metro: { ...state.validation.metro, isLoading: false, params: action.payload },
        isLoading: false
      };
    },

    setValidationMetroStatus(state, action) {
      state.isLoading = false;
      state.validation = {
        ...state.validation,
        metro: {
          ...state.validation.metro,
          isLoading: false,
          status: {
            ...state.validation.metro.status,
            entities: orderBy(action.payload.entities, ['total_completed', 'name'], ['desc', 'asc'])
          }
        },
        isLoading: false
      };
    },

    setEntitySelectionStatus(state, action) {
      const entitiesList = [...new Set(state.validation.metro.status.entities.map((entity) => ({ ...entity })))];

      action.payload.entityIndexes.forEach((entityIndex) => {
        entitiesList[entityIndex] = {
          ...entitiesList[entityIndex],
          selected:
            action.payload.forceStatus === undefined ? !entitiesList[entityIndex].selected : action.payload.forceStatus
        };
      });

      state.validation = {
        ...state.validation,
        metro: {
          ...state.validation.metro,
          isLoading: false,
          status: {
            ...state.validation.metro.status,
            entities: entitiesList
          }
        },
        isLoading: false
      };
    },

    setFilter(state, action) {
      // state.isLoading = false;
      state.filter = action.payload;
    },

    // SET DATA ENTRY WIZARD
    setWizard(state, action) {
      state.isLoading = false;
      state.wizard = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setValidationMetroParams,
  setEntitySelectionStatus,
  startValidationStatusLoading,
  setValidationType,
  setFilter
} = slice.actions;

// ----------------------------------------------------------------------

// export function getDataFile(id) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get(`/api/data_files/${id}`);
//       dispatch(slice.actions.setSelectedRole(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function getDataFileList(params = undefined) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { filter, currentPage } = getState().dataFile;
    try {
      const response = await axios.get('/api/data_files', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : currentPage,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined,
          ...filter.applyied
        }
      });

      dispatch(slice.actions.getDataFileListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getValidationParams() {
  return async (dispatch) => {
    dispatch(slice.actions.startValidationParamsLoading());
    try {
      const response = await axios.get('/api/data_files/validation');
      dispatch(slice.actions.getValidationParams(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserDataSets() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/data_files/data_sets');
      dispatch(slice.actions.getUserDataSetsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getValidationSimulationData(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startValidationSimulationDataLoading());
    try {
      const response = await axios.post('/api/invoices/simulate', params);
      dispatch(slice.actions.setValidationSimulationData(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function simulateEntityData(params) {
  return async () => {
    try {
      const response = await axios.post('/api/invoices/simulate', params);
      return response.data;
    } catch (error) {
      return error;
    }
  };
}

function updateSingleEntityStatus(params) {
  return async () => {
    try {
      return await axios.put('/api/data_files/status', params);
    } catch (error) {
      return error;
    }
  };
}

export function getValidationStatus(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startValidationStatusLoading());
    try {
      const response = await axios.post('/api/data_files/status', params);
      dispatch(slice.actions.setValidationMetroStatus(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setValidationStatus(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startValidationParamsLoading());
    try {
      dispatch(updateSingleEntityStatus(params));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteDataFile(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/data_files/${id}`);
      dispatch(getDataFileList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateDataFile(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/data_files', params);
      } else {
        await axios.put(`/api/data_files/${params.id}`, { data_file: params });
      }
      dispatch(getDataFileList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unlockDataFile(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/data_files/complete', params);
      dispatch(getDataFileList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function loadDataFileWizard(params, tabIndex) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('api/data_files/wizard', params);
      const wizardResponse = response.data;

      if (wizardResponse !== undefined && wizardResponse.periods !== undefined) {
        const periodTabIndex = tabIndex === undefined ? wizardResponse.periods.length - 1 : tabIndex;
        wizardResponse.periodTabIndex = periodTabIndex;
        wizardResponse.currentPeriod = {
          label: wizardResponse.periods[periodTabIndex].label,
          short_label: wizardResponse.periods[periodTabIndex].short_label,
          month: wizardResponse.periods[periodTabIndex].month,
          year: wizardResponse.periods[periodTabIndex].year
        };
        dispatch(slice.actions.setWizard(wizardResponse));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setSingleEntityStatus(selectedStep, entity) {
  return async (dispatch, getState) => {
    const { validation } = getState().dataFile;
    const status = {
      month: validation.metro.params.period.month,
      quarter: validation.metro.params.period.quarter,
      year: validation.metro.params.period.year,
      rule_id: entity.rule_id,
      subjects: entity.subjects,
      entity_id: entity.id,
      step_id: selectedStep.id,
      comment: null,
      previous_step: entity.step_id,
      kind: 'changed'
    };

    const jobs = [dispatch(updateSingleEntityStatus([status]))];

    try {
      await Promise.all(jobs).then((resp) => {
        if (resp[0].data.success === true) {
          dispatch(
            getValidationStatus({
              period: validation.metro.params.period,
              entity: validation.metro.params.entity.selectors
            })
          );
        }
      });
    } catch (error) {
      console.log({ status: 401, message: error.message, code: 'error' });
    }
  };
}
