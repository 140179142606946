import { createSlice } from '@reduxjs/toolkit';
// utils
import { startsWith } from 'lodash';
import axios from '../../utils/axios';
import { isNilOrBlank, getCurrentOrDefaultPeriod, publishedPeriodFormatter, processChatParams } from '../../utils/core';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  mapIsFetching: false,
  currentEntityId: null,
  selectedPyramidNodes: null,
  widgets: null,
  currentPeriod: null,
  mapFeatures: null,
  publishedPeriods: null,
  currentEntity: null,
  boundingEntity: null,
  levelPictures: [],
  activeLeaf: null,
  pyramid: null,
  currentPath: null,
  previousPath: null,
  navigationParams: {},
  error: false
};

const slice = createSlice({
  name: 'frontend',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    startMapLoading(state) {
      state.mapIsFetching = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setPublishedPeriods(state, action) {
      state.isLoading = false;
      state.publishedPeriods = action.payload;
    },

    setCurrentPath(state) {
      const url = setUrl(state.currentEntity.id, state.currentPeriod);
      state.isLoading = false;
      state.currentPath = url;
    },

    setCurrentPeriod(state, action) {
      // state.isLoading = false;
      state.currentPeriod = action.payload;
    },

    setLevelPictures(state, action) {
      state.levelPictures = action.payload;
    },

    setWidgets(state, action) {
      state.widgets = action.payload;
    },

    setSelectedPyramidNodes(state, action) {
      state.selectedPyramidNodes = action.payload;
    },

    setPyramid(state, action) {
      state.pyramid = action.payload;
    },

    setMapFeatures(state, action) {
      state.mapIsFetching = false;
      state.mapFeatures = action.payload;
    },

    setCurrentEntity(state, action) {
      state.isLoading = false;
      state.activeLeaf = null;
      state.currentEntity = action.payload;
    },

    setActiveLeaf(state, action) {
      state.activeLeaf = action.payload;
    },

    setCurrentEntityId(state, action) {
      state.isLoading = false;
      state.currentEntityId = action.payload;
    },

    setBoundingEntity(state, action) {
      state.isLoading = false;
      state.mapIsFetching = false;
      state.boundingEntity = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  setCurrentPeriod,
  setCurrentEntityId,
  setCurrentEntity,
  setBoundingEntity,
  setCurrentPath,
  setActiveLeaf,
  setPublishedPeriods,
  setSelectedPyramidNodes,
  startMapLoading,
  startLoading,
  setLevelPictures
} = slice.actions;

// ----------------------------------------------------------------------

const setUrl = (entityId, period) => {
  // console.log(period);
  const url =
    period === undefined || period.start === undefined || period.end === undefined
      ? `/data/${entityId}`
      : `/data/${entityId}/${period.start.value}/${period.end.value}`;

  // window.history.pushState("", "", url);
  return url;
};

const getEntities = async (params) =>
  axios
    .get(
      `/api/data/entities${!isNilOrBlank(params.entityId) ? `?entityId=${params.entityId}` : ''}${
        !isNilOrBlank(params.quarter) ? `&quarter=${params.quarter}` : ''
      }${!isNilOrBlank(params.year) ? `&year=${params.year}` : ''}`
    )
    .then((response) => response.data)
    .catch((error) => error);

export function updateFrontEndChart(updates) {
  return (dispatch, getState) => {
    const { widgets } = getState().frontend;
    let updatedWidgets = [...widgets];
    updates.forEach((update) => {
      const itemIndex = updatedWidgets.findIndex((item) => item.id === update.itemId);
      const updatedItem = { ...updatedWidgets[itemIndex], [update.field]: update.value };
      updatedWidgets = Object.assign([], updatedWidgets, {
        [itemIndex]: updatedItem
      });
    });
    dispatch(slice.actions.setWidgets(updatedWidgets));
  };
}

export function getDashBoardBlock(params) {
  return async (dispatch) => {
    dispatch(updateFrontEndChart([{ value: true, field: 'isLoading', itemId: params.itemParams.id }]));
    try {
      const p = processChatParams(params.itemParams);

      if (
        p.entity_id !== undefined &&
        p.entity_id.length > 0 &&
        p.start_date !== undefined &&
        p.end_date !== undefined &&
        p.ids !== undefined
      ) {
        const response = await axios.post(`/api/data/chart`, { formula_value: p });

        dispatch(
          updateFrontEndChart([
            { value: response.data, field: 'data', itemId: params.itemParams.id },
            { value: false, field: 'isLoading', itemId: params.itemParams.id },
            { value: false, field: 'touched', itemId: params.itemParams.id }
          ])
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(
        updateFrontEndChart([
          { value: false, field: 'isLoading', itemId: params.itemParams.id },
          { value: false, field: 'touched', itemId: params.itemParams.id }
        ])
      );
    }
  };
}

export function getWidgets() {
  return async (dispatch) => {
    try {
      const response = await axios.get('/api/data/widgets');
      if (!isNilOrBlank(response) && !isNilOrBlank(response.data)) {
        dispatch(slice.actions.setWidgets(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPublishedPeriods() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { navigationParams } = getState().frontend;
    try {
      const response = await axios.get('/api/data/periods');
      if (!isNilOrBlank(response) && !isNilOrBlank(response.data)) {
        const formattedResp = response.data.map(publishedPeriodFormatter);
        const period = getCurrentOrDefaultPeriod(
          formattedResp.filter((resp) => resp.kind === 'DataElement'),
          'Range',
          navigationParams
        );
        dispatch(slice.actions.setPublishedPeriods(formattedResp));
        dispatch(
          setCurrentPeriod({
            start: period.start,
            end: period.end
          })
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRootEntity() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/api/data/root`);
      if (!isNilOrBlank(response) && !isNilOrBlank(response.data)) {
        const root = response.data;
        root.coordinates = JSON.parse(response.data.coordinates);
        dispatch(slice.actions.setCurrentEntity(root));
        dispatch(slice.actions.setCurrentEntityId(root.id));
        dispatch(slice.actions.setBoundingEntity(root));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPyramid() {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/api/data/pyramid`, {});
      if (!isNilOrBlank(response) && !isNilOrBlank(response.data)) {
        dispatch(slice.actions.setPyramid(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setExpandedPyramidNodes(nodeIds) {
  return (dispatch) => {
    const currentNode = nodeIds[0].split('-');
    currentNode.pop();
    const searchTerm = `${currentNode.join('-')}-`;
    const cutOffIndex = nodeIds.slice(1).findIndex((node) => startsWith(node, searchTerm));
    if (cutOffIndex === -1) {
      dispatch(slice.actions.setSelectedPyramidNodes(nodeIds));
    } else {
      dispatch(slice.actions.setSelectedPyramidNodes([nodeIds[0], ...nodeIds.slice(cutOffIndex + 2)]));
    }
  };
}

export function getMapFeatures(params) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startMapLoading());
    const { currentEntityId } = getState().frontend;
    const features = [];
    const resp = await getEntities(params);
    if (!isNilOrBlank(resp) && resp.length > 0) {
      resp.forEach((feature) => {
        if (feature.coordinates !== null) {
          features.push({
            type: 'Feature',
            properties: {
              id: feature.id,
              name: feature.name,
              description: feature.description,
              short_name: feature.short_name,
              full_name: feature.full_name,
              pictures: feature.pictures,
              address: feature.address,
              contact_person: feature.contact_person,
              email: feature.email,
              group: feature.group,
              status: feature.status // active, sibling, child, parent or leaf
            },
            geometry: feature.coordinates
          });
        }
      });

      const boundingEntity = resp.find((entity) => entity.status === 'active');
      const currentEntity = resp.find((entity) => entity.id === currentEntityId);
      dispatch(
        slice.actions.setMapFeatures({
          type: 'FeatureCollection',
          features
        })
      );

      dispatch(slice.actions.setBoundingEntity(boundingEntity));
      dispatch(
        setLevelPictures(
          resp
            .map((entity) => entity.pictures)
            .flat()
            .filter((p) => p)
        )
      );
      dispatch(slice.actions.setCurrentEntity(currentEntity === undefined ? null : currentEntity));
    }
  };
}
