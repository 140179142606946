import { forwardRef } from 'react';
import SimpleBar from 'simplebar-react';

import Box from '@mui/material/Box';

import { scrollbarClasses } from './classes';

// eslint-disable-next-line
export const Scrollbar = forwardRef(({ slotProps, children, fillContent, naturalScroll, sx, ...other }, ref) => (
  <Box
    component={SimpleBar}
    scrollableNodeProps={{ ref }}
    clickOnTrack={false}
    className={scrollbarClasses.root}
    sx={{
      minWidth: 0,
      minHeight: 0,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      '& .simplebar-wrapper': slotProps?.wrapper, // eslint-disable-line
      '& .simplebar-content-wrapper': slotProps?.contentWrapper, // eslint-disable-line
      '& .simplebar-content': {
        ...(fillContent && {
          minHeight: 1,
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column'
        }),

        ...slotProps?.content // eslint-disable-line
      },
      ...sx
    }}
    {...other}
  >
    {children}
  </Box>
));
