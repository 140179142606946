import PropTypes from 'prop-types';
import { useState } from 'react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListSubheader, ListItemButton, Divider } from '@mui/material';
import Iconify from '../../components/Iconify';
import useAdminMenuConfig from '../../hooks/useAdminMenuConfig';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.overline,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.primary
  })
);

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  height: 40,
  position: 'relative',
  // textTransform: 'capitalize',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  '&:before': {
    top: 0,
    left: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}));

// ----------------------------------------------------------------------

NavItem.propTypes = {
  isShow: PropTypes.bool,
  onItemClick: PropTypes.func,
  item: PropTypes.object
};

function NavItem({ item, onItemClick, isShow }) {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { title, path, info, children } = item;
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    '&:before': { display: 'block' }
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium'
  };

  if (children) {
    return (
      <>
        {open && <Divider />}
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Iconify
                icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
                width={16}
                height={16}
                sx={{ ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {children.map((item) => {
                const { title, path } = item;
                const isActiveSub = path ? !!matchPath({ path, end: false }, pathname) : false;

                return (
                  <ListItemStyle
                    key={title}
                    component={RouterLink}
                    to={path}
                    sx={{
                      ...(isActiveSub && activeSubStyle),
                      fontSize: 12,
                      height: 30
                    }}
                  >
                    <ListItemText sx={{ ml: 2 }} disableTypography primary={title} onClick={onItemClick} />
                  </ListItemStyle>
                );
              })}
            </List>
          </Collapse>
        )}
        {open && <Divider />}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} onClick={onItemClick} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

AdminMenu.propTypes = {
  isShow: PropTypes.bool,
  onItemClick: PropTypes.func
};

export default function AdminMenu({ onItemClick, isShow = true, ...other }) {
  const navConfig = useAdminMenuConfig();
  return (
    <Box {...other}>
      {navConfig.map((list) => {
        const { subheader, items } = list;
        return (
          items.length > 0 && (
            <List key={subheader} disablePadding>
              <ListSubheaderStyle>{subheader}</ListSubheaderStyle>
              {items.map((item) => (
                <NavItem key={item.title} item={item} onItemClick={onItemClick} isShow={isShow} />
              ))}
            </List>
          )
        );
      })}
    </Box>
  );
}
