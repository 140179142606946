// import axios from 'axios';
import { useEffect, useRef } from 'react';
import { isNil, trim } from 'lodash';
import { useTheme } from '@mui/material/styles';
import AWS from 'aws-sdk';
import { fNumber } from './formatNumber';

const getChartType = (type) => {
  switch (type) {
    case 'stackedBar':
    case 'verticalStackedBar':
    case 'column':
    case 'column_sort':
    case 'bar_sort':
      return 'bar';
    case 'spline':
    case 'mixed':
      return 'line';
    case 'radar':
      return 'radar';
    default:
      return type;
  }
};

export const isNilOrBlank = (variable) => isNil(variable) || trim(variable) === '';

export const setNewInvoiceUrl = (params) => {
  if (
    !isNilOrBlank(params) &&
    !isNilOrBlank(params.invoice_type_id) &&
    !isNilOrBlank(params.entity_id) &&
    !isNilOrBlank(params.year) &&
    !isNilOrBlank(params.kind) &&
    !isNilOrBlank(params[params.kind])
  ) {
    window.history.replaceState(
      '',
      '',
      `/admin/invoices/${params.invoice_type_id}/${params.entity_id}/${params[params.kind]}/${params.year}`
    );
  }
};

export const setReportValue = (variable, prefix = '', suffix = '') =>
  !isNilOrBlank(variable) && !Number.isNaN(variable)
    ? `${prefix}${Number(variable).toLocaleString('en-US')}${suffix}`
    : '';

export const dirkingWarn = (actionType, fileTypeName, entityName, periodName, t) => {
  if (
    window.confirm(
      t(`dataFiles.dirking${actionType}WarnMessage`, {
        fileTypeName: fileTypeName.toUpperCase(),
        entityName: entityName.toUpperCase(),
        periodName: periodName.toUpperCase()
      })
    )
  ) {
    return true;
  }
  return false;
};

export const isValidJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const invoicePeriodByRequest = (invoice, request) => {
  let period = { year: invoice.period.year, quarter: invoice.period.quarter, label: invoice.period.label };

  if (request.kind === 'month') {
    const month = invoice.period.months.find((m) => m.month === request.month);
    period = { ...period, ...month };
  }

  if (request.kind === 'quarter') {
    period = { ...period, months: invoice.period.months };
  }
  if (request.kind === 'semester') {
    period = { ...period, months: invoice.period.months };
  }

  return period;
};

export const invoiceFileNameByRequest = (invoice, request, entity) => {
  const invoicePeriod = invoicePeriodByRequest(invoice, request);

  const fileName =
    !isNilOrBlank(invoice) && !isNilOrBlank(invoice.template)
      ? `${entity.display_name}_${invoice.template.name}_${invoicePeriod.label}_${invoicePeriod.year}`
      : 'default_name';

  return fileName.replace(': ', '').replace(/[/\\?%*:|"<> -]/g, '_');
};

export const publishedPeriodFormatter = (p) => ({
  shortLabel: p.label,
  label: `${p.label} ${p.year}`,
  kind: p.kind,
  quarter: p.quarter,
  month: p.month,
  year: p.year,
  startDate: p.start_date,
  endDate: p.end_date
});

// getCurrentOrDefaultPeriod = (publishedPeriods, kind, paramsPeriod)
export const getCurrentOrDefaultPeriod = (publishedPeriods, sliderType) => {
  const realStartIndex = publishedPeriods.length - process.env.REACT_APP_NUM_PERIODS;
  const startIndex = publishedPeriods.length > 0 && realStartIndex > 0 ? realStartIndex - 1 : 0;

  const realEndIndex = publishedPeriods.length - 1;
  const endIndex = publishedPeriods.length > 0 && realEndIndex > 0 ? realEndIndex : 0;

  const start = sliderType === 'Range' ? publishedPeriods[startIndex] : publishedPeriods[endIndex];
  const end = publishedPeriods[endIndex];

  return {
    start: start !== undefined ? start : end,
    end
  };
};

export const getCurrentOrDefaultPeriodLabel = (period) => {
  let label = '';
  if (!isNilOrBlank(period) && !isNilOrBlank(period.start) && !isNilOrBlank(period.start.year)) {
    if (period.start.year === period.end.year) {
      label = `${period.start.shortLabel}  -  ${period.end.label}`;
      if (period.start.month !== undefined && period.start.month === period.end.month) {
        label = `${period.end.label}`;
      } else if (period.start.quarter !== undefined && period.start.quarter === period.end.quarter) {
        label = `${period.end.label}`;
      }
    } else {
      label = `${period.start.label}  -  ${period.end.label}`;
    }
  }
  return label;
};

export const getParentElements = (dataElements) =>
  dataElements === undefined && dataElements.length === 0
    ? []
    : dataElements.filter((dataElement) =>
        [...new Set(dataElements.map((dataElement) => dataElement.parent_id))]
          .filter((dataElement) => dataElement)
          .includes(dataElement.id)
      );

export const processChatParams = (params) => ({
  id: isNilOrBlank(params.id) ? undefined : params.id,
  format: params.format,
  kind: params.kind,
  fn: !isNilOrBlank(params.fn) ? params.fn : undefined,
  state_id: !isNilOrBlank(params.state_id) && params.state_id.length > 0 ? params.state_id : undefined,
  formula_id: !isNilOrBlank(params.formula_id) && params.formula_id.length > 0 ? params.formula_id : undefined,
  data_set_id: !isNilOrBlank(params.data_set_id) && params.data_set_id.length > 0 ? params.data_set_id : undefined,
  ids: params.ids !== undefined && params.ids !== null && params.ids !== '' ? params.ids : undefined,
  entity_id: !isNilOrBlank(params.entity_id) ? params.entity_id : undefined,
  start_date:
    !isNilOrBlank(params.period) && !isNilOrBlank(params.period.start) ? params.period.start.startDate : undefined,
  end_date: !isNilOrBlank(params.period) && !isNilOrBlank(params.period.end) ? params.period.end.endDate : undefined,

  start_quarter:
    !isNilOrBlank(params.period) && !isNilOrBlank(params.period.start) ? params.period.start.quarter : undefined,
  end_quarter: !isNilOrBlank(params.period) && !isNilOrBlank(params.period.end) ? params.period.end.quarter : undefined,
  start_year: !isNilOrBlank(params.period) && !isNilOrBlank(params.period.start) ? params.period.start.year : undefined,
  end_year: !isNilOrBlank(params.period) && !isNilOrBlank(params.period.start) ? params.period.end.year : undefined,

  cached: !isNilOrBlank(params.cached) ? params.cached : false,
  entity_group_by: !isNilOrBlank(params.entity_group_by) ? params.entity_group_by : 'Self',
  period_slider_type: !isNilOrBlank(params.period_slider_type) ? params.period_slider_type : 'Range',
  plot: !isNilOrBlank(params.plot) ? params.plot : 'entities_subject',
  period_type: !isNilOrBlank(params.period_type) ? params.period_type : 'quarter',
  chart_type: !isNilOrBlank(params.chart_type) ? params.chart_type : 'bar',
  plot_type: !isNilOrBlank(params.plot_type) ? params.plot_type : 'default',
  prefix: !isNilOrBlank(params.prefix) ? params.prefix : undefined,
  suffix: !isNilOrBlank(params.suffix) ? params.suffix : undefined,
  current_user: !isNilOrBlank(params.current_user) ? params.current_user : undefined
});

export const useChartColors = () => {
  const theme = useTheme();
  return [
    theme.palette.primary.main,
    theme.palette.primary.main === '#fda92d' ? theme.palette.chart.blue[1] : theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.error.main,
    theme.palette.success.main,
    theme.palette.warning.dark,
    theme.palette.success.darker,
    theme.palette.info.dark,
    theme.palette.info.darker,
    theme.palette.chart.yellow[0],
    theme.palette.chart.blue[0],
    theme.palette.chart.violet[0],
    theme.palette.chart.green[0],
    theme.palette.chart.red[0],
    ...colors
  ];
};

export const useChartParams = (params) => {
  const allColors = useChartColors();
  let chartParams = { series: null, categories: [] };
  if (isNilOrBlank(params.data)) {
    if (isNilOrBlank(params.cache)) {
      chartParams = { series: null, categories: [] };
    } else {
      chartParams = params.cache;
    }
  } else {
    chartParams = params.data;
  }

  const initChartOptions = {
    chart: {
      type: getChartType(params.chart_type),
      height: 420,
      stacked: ['verticalStackedBar', 'stackedBar'].includes(params.chart_type) ? true : undefined,
      stackType: ['verticalStackedBar', 'stackedBar'].includes(params.chart_type) ? '100%' : undefined
      // dropShadow: {
      //   enabled: true,
      //   blur: 1,
      //   left: 1,
      //   top: 1
      // }
    },
    title: {
      text: params.title
    },
    subtitle: {
      text: getCurrentOrDefaultPeriodLabel(params.period)
    },
    xaxis: {
      title: {
        text:
          !isNilOrBlank(params.chart_options) &&
          !isNilOrBlank(params.chart_options.x_axis) &&
          !isNilOrBlank(params.chart_options.x_axis.title)
            ? params.chart_options.x_axis.title
            : ''
      },
      categories: !isNilOrBlank(chartParams) && !isNilOrBlank(chartParams.categories) ? chartParams.categories : []
    },
    yaxis:
      params.chart_type !== 'mixed'
        ? {
            title: {
              text:
                !isNilOrBlank(params.chart_options) &&
                !isNilOrBlank(params.chart_options.y_axis) &&
                !isNilOrBlank(params.chart_options.y_axis.title)
                  ? params.chart_options.y_axis.title
                  : ''
            },
            min:
              !isNilOrBlank(params.chart_options) &&
              !isNilOrBlank(params.chart_options.y_axis) &&
              !isNilOrBlank(params.chart_options.y_axis.max)
                ? 0
                : undefined,
            max:
              !isNilOrBlank(params.chart_options) &&
              !isNilOrBlank(params.chart_options.y_axis) &&
              !isNilOrBlank(params.chart_options.y_axis.max)
                ? Number(params.chart_options.y_axis.max)
                : undefined,
            tickAmount:
              !isNilOrBlank(params.chart_options) &&
              !isNilOrBlank(params.chart_options.y_axis) &&
              !isNilOrBlank(params.chart_options.y_axis.max)
                ? 4
                : undefined,
            forceNiceScale: !(
              !isNilOrBlank(params.chart_options) &&
              !isNilOrBlank(params.chart_options.y_axis) &&
              !isNilOrBlank(params.chart_options.y_axis.max)
            ),
            labels: ['bar', 'bar_sort'].includes(params.chart_type)
              ? {
                  show: true,
                  align: 'right',
                  maxWidth: 500
                }
              : {}
          }
        : !isNilOrBlank(chartParams) &&
          !isNilOrBlank(chartParams.series) &&
          chartParams.series.length > 0 &&
          chartParams.series.map((s, sI) => ({
            seriesName: sI === 0 ? undefined : sI === 1 ? chartParams.series[0].name : s.name, // eslint-disable-line
            opposite: sI > 0,
            // max: sI === params.data.series.length - 1 ? 100 : undefined, // for 100%. SHould be improved to use the params max
            // forceNiceScale: sI === params.data.series.length - 1, // should force scale if any value is greater than the set max
            axisTicks: {
              show: true,
              color: allColors[sI]
            },
            axisBorder: {
              show: true,
              color: allColors[sI]
            },
            labels: {
              style: {
                colors: allColors[sI]
              },
              formatter(val) {
                return val.toFixed(0);
              }
            },
            title: {
              text: s.name,
              style: {
                color: allColors[sI]
              }
            }
          })),
    tooltip: {
      // enabled: true,
      shared:
        !isNilOrBlank(chartParams) &&
        !isNilOrBlank(chartParams.series) &&
        chartParams.series.length > 1 &&
        params.chart_type !== 'radar',
      intersect: !(
        !isNilOrBlank(chartParams) &&
        !isNilOrBlank(chartParams.series) &&
        chartParams.series.length > 1 &&
        params.chart_type !== 'radar'
      ),
      x: {
        show: true
      },
      y: {
        show:
          !isNilOrBlank(params.chart_options) &&
          !isNilOrBlank(params.chart_options.y_axis) &&
          !isNilOrBlank(params.chart_options.y_axis.value_label),
        formatter: (val) =>
          `${fNumber(val)} ${
            !isNilOrBlank(params.chart_options) &&
            !isNilOrBlank(params.chart_options.y_axis) &&
            !isNilOrBlank(params.chart_options.y_axis.value_label)
              ? params.chart_options.y_axis.value_label
              : ''
          }`
      }
    },
    dataLabels: {
      // enabled: !!['verticalStackedBar', 'stackedBar'].includes(params.chart_type),
      enabled: params.dataLabels,
      formatter: ['donut', 'pie', 'stackedBar', 'verticalStackedBar'].includes(params.chart_type)
        ? (val) => `${Number(parseFloat(val).toFixed(1))}%`
        : (val) =>
            `${val} ${
              !isNilOrBlank(params.chart_options) &&
              !isNilOrBlank(params.chart_options.y_axis) &&
              !isNilOrBlank(params.chart_options.y_axis.value_label)
                ? params.chart_options.y_axis.value_label
                : ''
            }`,
      offsetY: ['column', 'column_sort'].includes(params.chart_type) ? -15 : -1,
      offsetX: ['column', 'column_sort', 'line', 'spline', 'stackedBar', 'verticalStackedBar'].includes(
        params.chart_type
      )
        ? 0
        : 35,
      style: ['donut', 'pie', 'stackedBar', 'verticalStackedBar'].includes(params.chart_type)
        ? {
            fontSize: '10px',
            colors: ['#ffffff']
          }
        : {
            fontSize: '8px',
            colors: ['#304758']
          }
    },
    plotOptions: {
      bar: {
        horizontal: ['bar', 'bar_sort', 'stackedBar', 'verticalStackedBar'].includes(params.chart_type),
        dataLabels: ['bar', 'bar_sort', 'stackedBar', 'verticalStackedBar', 'column', 'column_sort'].includes(
          params.chart_type
        )
          ? {
              position: ['stackedBar', 'verticalStackedBar'].includes(params.chart_type) ? 'center' : 'top' // top, center, bottom
            }
          : undefined
        // dataLabels: {
        //   total: {
        //     enabled: true,
        //     offsetX: 0,
        //     style: {
        //       fontSize: '13px',
        //       fontWeight: 900
        //     }
        //   }
        // }
      }
    },
    stroke: {
      width: ['verticalStackedBar', 'stackedBar'].includes(params.chart_type) ? 0 : undefined,
      curve: ['stackedBar', 'verticalStackedBar', 'mixed', 'spline'].includes(params.chart_type)
        ? 'smooth'
        : 'straight',
      lineCap: 'round'
    },
    fill:
      params.chart_type === 'radar'
        ? {
            opacity: 0.1
          }
        : undefined,
    markers:
      params.chart_type === 'radar'
        ? {
            size: 3
          }
        : undefined,
    series: ['donut', 'pie'].includes(params.chart_type)
      ? !isNilOrBlank(chartParams) &&
        !isNilOrBlank(chartParams.series) &&
        chartParams.series.length > 0 &&
        chartParams.series.map((s) => s.data)
      : chartParams.series,
    labels: ['donut', 'pie'].includes(params.chart_type)
      ? !isNilOrBlank(chartParams) &&
        !isNilOrBlank(chartParams.series) &&
        chartParams.series.length > 0 &&
        chartParams.series.map((s) => s.name)
      : []
  };

  return initChartOptions;
};

export const chartReducer = (prevState, action) => {
  switch (action.type) {
    case 'SET_BLOCK':
      return {
        ...prevState,
        ...action.payload
      };
    case 'SET_PERIOD':
      return {
        ...prevState,
        currentPeriod: action.currentPeriod
      };
    case 'SET_PARAMS':
      return {
        ...prevState,
        params: action.payload
      };
    case 'SET_PYRAMID':
      return {
        ...prevState,
        pyramid: action.payload
      };
    default:
      return false;
  }
};

// export const setChartOptions = (params, series, categories = null, theme = undefined) => {
//   let chartOptions = {
//     colors:
//       theme !== undefined
//         ? [
//             theme.palette.primary.main,
//             theme.palette.chart.yellow[0],
//             theme.palette.chart.blue[0],
//             theme.palette.chart.violet[0],
//             theme.palette.chart.green[0],
//             theme.palette.chart.red[0]
//           ].concat(colors)
//         : colors,
//     subtitle: {
//       text: null
//     },
//     credits: {
//       enabled: false
//     },
//     plotOptions: {
//       column: {
//         borderRadius: 5
//       },
//       bar: {
//         borderRadius: 5
//       },
//       pie: {
//         allowPointSelect: true,
//         cursor: 'pointer',
//         dataLabels: {
//           enabled: false
//         },
//         showInLegend: true
//       }
//     }
//   };
//
//   switch (params.chart_type) {
//     case 'line':
//     case 'bar':
//     case 'column':
//     case 'spline':
//       chartOptions = {
//         ...chartOptions,
//         title: {
//           text: params.title,
//           style: { color: 'rgb(33, 43, 54)', fontWeight: 'bold' }
//         },
//         chart: {
//           type: params.chart_type, // column, line, bar, pie or spline
//           style: chartStyle
//         },
//         yAxis: {
//           labels:
//             !isNilOrBlank(params.chart_options) &&
//             !isNilOrBlank(params.chart_options.y_axis) &&
//             !isNilOrBlank(params.chart_options.y_axis.value_label)
//               ? {
//                   format: `{value}${params.chart_options.y_axis.value_label}`
//                 }
//               : {},
//           min:
//             !isNilOrBlank(params.chart_options) &&
//             !isNilOrBlank(params.chart_options.y_axis) &&
//             !isNilOrBlank(params.chart_options.y_axis.min)
//               ? params.chart_options.y_axis.min
//               : 0,
//           max:
//             !isNilOrBlank(params.chart_options) &&
//             !isNilOrBlank(params.chart_options.y_axis) &&
//             !isNilOrBlank(params.chart_options.y_axis.max)
//               ? params.chart_options.y_axis.max
//               : undefined,
//           title: {
//             text:
//               !isNilOrBlank(params.chart_options) &&
//               !isNilOrBlank(params.chart_options.y_axis) &&
//               !isNilOrBlank(params.chart_options.y_axis.title)
//                 ? params.chart_options.y_axis.title
//                 : undefined
//           }
//         },
//         xAxis: {
//           labels:
//             !isNilOrBlank(params.chart_options) &&
//             !isNilOrBlank(params.chart_options.x_axis) &&
//             !isNilOrBlank(params.chart_options.x_axis.value_label)
//               ? {
//                   format: `{value}${params.chart_options.x_axis.value_label}`
//                 }
//               : {},
//           min:
//             !isNilOrBlank(params.chart_options) &&
//             !isNilOrBlank(params.chart_options.x_axis) &&
//             !isNilOrBlank(params.chart_options.x_axis.min)
//               ? params.chart_options.x_axis.min
//               : 0,
//           max:
//             !isNilOrBlank(params.chart_options) &&
//             !isNilOrBlank(params.chart_options.x_axis) &&
//             !isNilOrBlank(params.chart_options.x_axis.max)
//               ? params.chart_options.x_axis.max
//               : undefined,
//           title: {
//             text:
//               !isNilOrBlank(params.chart_options) &&
//               !isNilOrBlank(params.chart_options.x_axis) &&
//               !isNilOrBlank(params.chart_options.x_axis.title)
//                 ? params.chart_options.x_axis.title
//                 : ''
//           },
//           categories,
//           lineWidth: 2
//         },
//         // series: series
//         series:
//           series === undefined
//             ? []
//             : series.map((s, si) => ({
//                 ...s,
//                 marker: {
//                   enabled: false
//                 },
//                 id: `series-${si}`
//               }))
//       };
//       break;
//     case 'pie':
//       chartOptions = {
//         ...chartOptions,
//         title: {
//           text: params.title,
//           style: { color: 'rgb(33, 43, 54)', fontWeight: 'bold' }
//         },
//         chart: {
//           type: params.chart_type, // column, line, pie, bar or spline
//           style: chartStyle
//         },
//         tooltip: {
//           pointFormat: `{series.name}: <b>${params.prefix !== null ? params.prefix : ''}{point.percentage:.1f}${
//             params.suffix !== null ? params.suffix : ''
//           }</b>`
//         },
//         accessibility: {
//           point: {
//             valueSuffix: params.suffix
//           }
//         },
//         series: [
//           {
//             name: params.title,
//             colorByPoint: true,
//             data: series.map((s) => ({
//               name: s.name,
//               y: s.data[0] !== undefined ? s.data[0] : null
//             }))
//           }
//         ]
//       };
//       break;
//     default:
//   }
//   return chartOptions;
// };

// export const cloudinaryUpload = (file, preset, url) => {
//   const formData = new FormData();
//   formData.append('file', file);
//   formData.append('upload_preset', preset);
//
//   return axios
//     .post(url, formData)
//     .then((resp) => ({
//       data: {
//         url: resp.data.secure_url,
//         public_id: resp.data.public_id,
//         type: resp.data.resource_type // or "video"
//       }
//     }))
//     .catch((error) => error);
// };

export const awsBucketUpload = async (folder, files) => {
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
  });
  const s3 = new AWS.S3({
    params: {
      Bucket: `${process.env.REACT_APP_PROJECT_NAME}-shahidi-bucket`
    },
    region: process.env.REACT_APP_AWS_REGION
  });

  const promises = files.map((file) =>
    s3
      .putObject({
        Bucket: `${process.env.REACT_APP_PROJECT_NAME}-shahidi-bucket`,
        Key: `${folder}/${Date.now()}-${file.name}`,
        Body: file
      })
      // .on('httpUploadProgress', (evt) => {
      //   console.log(`Uploading ${parseInt((evt.loaded * 100) / evt.total, 10)}%`);
      // })
      .promise()
  );

  // When all promises are done, this code is executed;
  const results = await Promise.all(promises).then((responses) =>
    responses.map((res) => {
      let url;
      if (res.$response.httpResponse.statusCode === 200) {
        const resReq = res.$response.data.$response.request.httpRequest;
        url = `${resReq.endpoint.protocol}//${resReq.endpoint.host}${resReq.path}`;
      }
      return url;
    })
  );

  return results.flat().filter((url) => url);
};

/* eslint-disable */

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

/* eslint-enable */

export const groupBy = (array, key) =>
  array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);

    return result;
  }, {});

export const chartStyle = {
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(',')
};

export const colors = [
  '#303f9f',
  '#c51162',
  '#388e3c',
  '#f7a35c',
  '#8085e9',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1',
  '#7cb5ec',
  '#212121',
  '#90ed7d',
  '#f57c00',
  '#1976d2',
  '#434348',
  '#d32f2f',
  '#4caf50',
  '#3f51b5',
  '#303030',
  '#81c784',
  '#ff9800',
  '#2196f3',
  '#f50057',
  '#deb76f',
  '#8a9959',
  '#f44336',
  '#39ca44',
  '#e57373',
  '#ffb74d',
  '#64b5f6',
  '#ff4081',
  '#7986cb',
  '#00ffff',
  '#fa86c4',
  '#9ad0b6',
  '#e0eca5',
  '#e3a4a7',
  '#081947'
];

export const materialIcons = (t) => [
  { value: 'la:dollar-sign', label: t(`icons.finance`) },
  { value: 'la:creative-commons-nc', label: t(`icons.noFinance`) },
  { value: 'medical-icon:i-nursery', label: t(`icons.ChildFriendly`) },
  { value: 'medical-icon:i-ambulance', label: t(`icons.ambulance`) },
  { value: 'medical-icon:i-billing', label: t(`icons.AttachMoney`) },
  { value: 'medical-icon:i-laboratory', label: t(`icons.laboratory`) },
  { value: 'medical-icon:i-health-education', label: t(`icons.councelling`) },
  { value: 'healthicons:hospital', label: t(`icons.Apartment`) },
  { value: 'medical-icon:i-family-practice', label: t(`icons.familyPlanning`) },
  { value: 'medical-icon:i-outpatient', label: t(`icons.outpatient`) },
  { value: 'healthicons:pills-4', label: t(`icons.pills`) },
  { value: 'healthicons:ambulatory-clinic', label: t(`icons.clinic`) },
  { value: 'healthicons:ancv', label: t(`icons.PregnantWoman`) }
];
