import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  list: null,
  parentables: null,
  selected: null,
  formOpen: false
};

const slice = createSlice({
  name: 'dataElementGroup',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getElementGroupsSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    getElementGroupsParentables(state, action) {
      state.isLoading = false;
      state.parentables = action.payload;
    },

    setSelectedElementGroup(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    // SET FORM OPEN
    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
      }
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFormOpen, setSelectedElementGroup } = slice.actions;

export function getElementGroup(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/data_element_groups/${id}`);
      dispatch(slice.actions.setSelectedElementGroup(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteElementGroup(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/data_element_groups/${id}`);
  };
}

export function getElementGroups(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/data_element_groups', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getElementGroupsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getElementGroupsForParents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/data_element_groups', {
        params: {
          paging: false
        }
      });

      const parentElements = response.data.data
        .filter((g) => g.parent === undefined || g.parent === null)
        .map((grp) => ({
          id: grp.id,
          name: grp.name
        }));

      dispatch(slice.actions.getElementGroupsParentables(parentElements));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateElementGroup(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/data_element_groups', { data_element_group: params });
      } else {
        await axios.put(`/api/data_element_groups/${params.id}`, { data_element_group: params });
      }
      dispatch(getElementGroups());
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
