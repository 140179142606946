import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { TextField, Checkbox, FormControlLabel, Autocomplete } from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

import { filterCheckedLeaves, filterCheckedAllNodes, filterTree, updateTree } from './utils';

import useLocales from '../../../hooks/useLocales';

// To be virtualized like here https://mui.com/material-ui/react-autocomplete/#Virtualize.js
// https://mui.com/material-ui/react-autocomplete/

/* Selection modes:
- selectAll : Node selection will auto-select it's children and leaves
- leavesOnly : Node selection will only select its leaves
- singleNode : Node selection has no effect on it's children and leaves. Every node is independant
*/

DropdownTreeAutoSelect.propTypes = {
  size: PropTypes.string,
  pyramid: PropTypes.array,
  fieldName: PropTypes.string,
  updatePyramid: PropTypes.func,
  mode: PropTypes.oneOf(['selectAll', 'leavesOnly', 'singleNode']),
  disabled: PropTypes.bool,
  selectorTreePlaceHolder: PropTypes.string,
  error: PropTypes.bool
};

const getCurrentValues = (pyramid, mode) => {
  switch (mode) {
    case 'leavesOnly':
      return filterCheckedLeaves(pyramid); // Node selection will only select its leaves
    case 'singleNode':
      return filterCheckedAllNodes(pyramid); // Node selection has no effect on it's children and leaves. Every node is independant
    default:
      return filterCheckedAllNodes(pyramid); // Node selection will auto-select it's children and leaves
  }
};

export default function DropdownTreeAutoSelect({
  size,
  pyramid,
  updatePyramid,
  mode,
  disabled,
  selectorTreePlaceHolder,
  error
}) {
  const { translate: t } = useLocales();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const currentValue = getCurrentValues(pyramid, mode);

  const onChange = (updates, mode) => {
    const upds = updateTree(cloneDeep(pyramid), updates, mode);
    const currentValues = getCurrentValues(upds, mode);
    updatePyramid({ tree: upds, selected: currentValues.map((currentValue) => currentValue.value) });
  };

  const renderTree = (option) => (
    <TreeItem
      key={option.value}
      nodeId={String(option.value)}
      label={
        <FormControlLabel
          control={
            <Checkbox
              onClick={() => onChange({ ...option, checked: !option.checked }, mode)}
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={option.checked}
            />
          }
          label={option.label}
        />
      }
    >
      {Array.isArray(option.children) ? option.children.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  return (
    <Autocomplete
      size={size === undefined ? 'small' : size}
      multiple
      disabled={disabled}
      limitTags={30}
      options={pyramid !== undefined && pyramid.length > 0 ? pyramid : []}
      disableCloseOnSelect
      value={currentValue}
      ListboxComponent={TreeView}
      ListboxProps={{
        defaultCollapseIcon: <RemoveOutlinedIcon fontSize="small" />,
        defaultExpandIcon: <AddOutlinedIcon fontSize="small" />,
        defaultEndIcon: <RemoveOutlinedIcon fontSize="small" color="disabled" />
      }}
      isOptionEqualToValue={(option, value) => filterTree(option, value.value)}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => renderTree(option)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('shared.entitySelectorTreeTitle', {
            totalStructures: currentValue.length
          })}
          placeholder={selectorTreePlaceHolder !== undefined ? selectorTreePlaceHolder : ''}
          error={error}
          helperText={error?.message}
        />
      )}
      onChange={(event, value, reason, details) => {
        // console.log('onChange', reason);
        if (reason === 'removeOption') {
          onChange({ ...details.option, checked: false }, mode);
        }
        if (reason === 'clear') {
          onChange({ ...pyramid[0], checked: false }, 'selectAll');
        }
      }}
      onInputChange={(event, value, reason) => {
        // console.log('onInputChange', reason);
        if (reason === 'clear') {
          onChange({ ...pyramid[0], checked: false }, 'selectAll');
        }
      }}
    />
  );
}
