import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  selected: null,
  dataSets: null,
  list: null
};

const slice = createSlice({
  name: 'rule',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedRule(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    setRuleDataSets(state, action) {
      state.isLoading = false;
      state.dataSets = action.payload;
    },

    getRulesSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedRule, getRulesSuccess } = slice.actions;

// ----------------------------------------------------------------------

export function getRule(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/rules/${id}`);
      dispatch(slice.actions.setSelectedRule(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteRule(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/rules/${id}`);
  };
}

export function getRulesResponse(params) {
  return axios
    .get('/api/rules', {
      params: {
        page: params !== undefined && params.page !== undefined ? params.page : 1,
        kind: params !== undefined && params.kind !== undefined ? params.kind : 'payment',
        paging: params !== undefined && params.paging !== undefined ? params.paging : true,
        per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
        search_text:
          params !== undefined &&
          params.searchText !== undefined &&
          params.searchText !== null &&
          params.searchText !== false
            ? params.searchText
            : undefined
      }
    })
    .then((response) => response.data)
    .catch((error) => error);
}

export function getRules(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await getRulesResponse(params);
    dispatch(slice.actions.getRulesSuccess(response));
  };
}

export function getDataSetsForPaymentRules(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/data_sets/payment_rules', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true
        }
      });

      dispatch(slice.actions.setRuleDataSets(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateRules(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/rules', { rule: params });
      } else {
        await axios.put(`/api/rules/${params.id}`, { rule: params });
      }
      dispatch(setSelectedRule(null));
      //  dispatch(getRules());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
