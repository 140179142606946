import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  currentPage: 1,
  selected: null,
  list: null,
  userRoles: null,
  resources: null,
  formOpen: false
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ROLE
    setSelectedRole(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    // GET MANAGE ROLES
    getRoleListSuccess(state, action) {
      state.isLoading = false;
      state.currentPage = action.payload.pager.current_page;
      state.list = action.payload;
    },

    getUserRoleListSuccess(state, action) {
      state.isLoading = false;
      state.userRoles = action.payload;
    },

    getResourcesSuccess(state, action) {
      state.isLoading = false;
      state.resources = action.payload;
    },

    // SET FORMOPEN
    setFormOpen(state, action) {
      state.isLoading = false;
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedRole } = slice.actions;

// ----------------------------------------------------------------------

export function setFormOpen(open) {
  return (dispatch) => {
    dispatch(slice.actions.setFormOpen(open));
    if (open === false) {
      dispatch(slice.actions.setSelectedRole(null));
      dispatch(slice.actions.getResourcesSuccess(null));
    }
  };
}

// ----------------------------------------------------------------------

export function getRole(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/roles/${id}`);
      dispatch(slice.actions.setSelectedRole(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRoles(params = undefined) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { currentPage } = getState().role;
    try {
      const response = await axios.get('/api/roles', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : currentPage,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getRoleListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserRoles() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/roles', {
        params: {
          page: 1,
          paging: false,
          per_page: undefined,
          search_text: undefined
        }
      });

      dispatch(slice.actions.getUserRoleListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getResources() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/roles/resources');

      dispatch(slice.actions.getResourcesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteRole(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/roles/${id}`);
      dispatch(getRoles());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function duplicateRole(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.post('/api/roles/duplicate/', { role: { id } });
  };
}

export function createUpdateRole(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/roles', { role: params });
      } else {
        await axios.put(`/api/roles/${params.id}`, { role: params });
      }
      dispatch(getRoles());
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
