import PropTypes from 'prop-types';
// material
// import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

const LOGO_MOBILE = 30;
const LOGO_DESKTOP = 60;

Logo.propTypes = {
  sx: PropTypes.object,
  footer: PropTypes.bool
};

export default function Logo({ sx, footer }) {
  //  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: { xs: LOGO_MOBILE, md: LOGO_DESKTOP }, height: { xs: LOGO_MOBILE, md: LOGO_DESKTOP }, ...sx }}>
      <img
        src={
          footer === true &&
          process.env.REACT_APP_FOOTER_LOGO !== undefined &&
          process.env.REACT_APP_FOOTER_LOGO !== null
            ? `/${process.env.REACT_APP_FOOTER_LOGO}`
            : `/${process.env.REACT_APP_LOGO}`
        }
        alt={process.env.REACT_APP_MAIN_TITLE}
      />
    </Box>
  );
}
