import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  selected: null,
  list: null,
  parentables: null,
  formOpen: false
};

const slice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedBank(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    getBanksSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    getBanksParentables(state, action) {
      state.isLoading = false;
      state.parentables = action.payload;
    },

    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
      }
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFormOpen, setSelectedBank } = slice.actions;

// ----------------------------------------------------------------------

export function getBanksForParents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/banks', {
        params: {
          paging: false
        }
      });

      const parentBanks = response.data.data.filter((g) => g.parent_id === undefined || g.parent_id === null);
      dispatch(slice.actions.getBanksParentables(parentBanks));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBank(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/banks/${id}`);
      dispatch(slice.actions.setSelectedBank(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBanks(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/banks', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          has_tree: params !== undefined && params.has_tree !== undefined ? params.has_tree : false,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getBanksSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateBank(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/banks', { bank: params });
      } else {
        await axios.put(`/api/banks/${params.id}`, { bank: params });
      }
      dispatch(getBanks({ has_tree: true }));
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteBank(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/banks/${id}`);
  };
}
