/* eslint no-eval: 0 */
/* eslint-disable */
// https://github.com/Zolmeister/js-solver/blob/master/js-solver.js
// https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Object_prototypes

export default class Solver {
  constructor(equations) {
    this.replacements = [
      {
        re: 'AND',
        res: '&&'
      },
      {
        re: 'OR',
        res: '||'
      },
      {
        re: 'ROUND',
        res: 'this.round'
      },
      {
        re: 'SUM',
        res: 'this.sum'
      },
      {
        re: 'AVG',
        res: 'this.avg'
      },
      {
        re: 'SAFE_DIV',
        res: 'this.safe_div'
      },
      {
        re: 'IF',
        res: 'this.if'
      },
      {
        re: 'ABS',
        res: 'this.abs'
      },
      {
        re: 'MIN',
        res: 'this.min'
      },
      {
        re: 'MAX',
        res: 'this.max'
      },
      {
        re: 'CONTAINS',
        res: 'this.contains'
      },
      {
        re: 'ARRAY',
        res: 'this.array'
      },
      {
        re: 'COUNT_WHERE',
        res: 'this.count_where'
      },
      {
        re: 'COUNT',
        res: 'this.count'
      },
      {
        re: 'RELEVANCE',
        res: 'this.relevance'
      },
      {
        re: 'ACCESS',
        res: 'this.access'
      },
      {
        re: 'SCORE_TABLE',
        res: 'this.score_table'
      }
    ];

    this.params = Object.keys(equations);
    this.equations = this.parseEquations(equations);

    this.round = (value, decimalPoints = 0) => parseFloat(value).toFixed(decimalPoints);
    this.sum = (...args) =>
      args
        .flat()
        .filter((arg) => arg)
        .reduce((a, b) => a + b, 0);
    this.avg = (...args) => {
      const cleanArgs = args.filter((arg) => arg);
      return cleanArgs.length > 0 ? cleanArgs.reduce((a, b) => a + b, 0) / cleanArgs.length : 0;
    };
    this.safe_div = (...args) => (args[1] > 0 ? args[0] / args[1] : 0);
    this.if = (...args) => (args[0] === true ? args[1] : args[2]);
    this.abs = (value) => value !== undefined && Math.abs(value);
    this.max = (...args) => Math.max(...args);
    this.min = (...args) => Math.min(...args);
    this.contains = (...args) => {
      const arg0 = args[0];
      const arg1 = args[1];
      return arg0 !== undefined && arg0.includes(arg1) ? 1 : 0;
    };

    this.array = (...args) => args;

    this.score_table = (...args) => -1;

    this.count_where = (...args) => {
      const flattenArgs = args.flat();
      const arg0 = [...flattenArgs].slice(0, -1);
      const arg1 = [...flattenArgs][[...flattenArgs].length - 1];
      if (arg0 !== undefined && arg1 !== undefined) {
        const filtered = arg0.filter((arg) => arg === arg1);
        return filtered !== null && filtered !== undefined ? filtered.length : 0;
      }
      return 0;
    };

    this.count = (...args) => args.length;

    this.relevance = (...args) => {
      const flattenArgs = args.flat();
      for (var i = flattenArgs.length - 1; i >= 0; i--) {
        if (flattenArgs[i] !== null && flattenArgs[i] !== undefined) {
          return flattenArgs[i];
        }
      }
      return null;
    };

    this.access = (...args) => {
      const arg0 = args[0];
      const arg1 = args[1];
      return arg0 !== undefined && arg1 !== undefined && arg0[arg1] !== undefined ? arg0[arg1] : 0;
    };
  }

  evalExec(expr) {
    return Function(`return (${expr})`)();
  }

  parseEquations(equs) {
    const equations = {};
    Object.entries(equs).forEach(([key, rawExpr]) => {
      let expr = rawExpr;
      this.replacements.forEach((repl) => {
        expr = expr.replace(repl.re, repl.res);
      });
      equations[key] = expr;
    });
    return equations;
  }

  solve(obj) {
    const out = {};
    let nullCount = this.params.length;
    let lastNull = 0;

    this.params.forEach((key) => {
      this.evalExec(key + '=undefined');
    });

    Object.keys(obj).forEach((k) => {
      if (this.params.indexOf(k) !== -1 && (obj[k] === 0 || obj[k])) {
        if (Array.isArray(obj[k])) {
          this.evalExec(k + '=[' + obj[k] + ']');
        } else {
          this.evalExec(k + '=' + obj[k]);
        }
        out[k] = obj[k];
      }
    });

    const equations = JSON.parse(JSON.stringify(this.equations));

    while (lastNull !== nullCount) {
      lastNull = nullCount;

      for (var eq in equations) {
        var result = eval(equations[eq]);
        if (result !== null && result !== 'NaN' && result !== undefined) {
          out[eq] = isNaN(result) ? '0' : '' + result;
          out[eq] = result;
          this.evalExec(eq + '=' + result);
          equations[eq] = undefined;
        }
      }
      nullCount = Object.keys(equations).length;
    }
    return out;
  }
}
