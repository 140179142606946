import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { isNilOrBlank } from '../../utils/core';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isEntityValuesLoading: false,
  filter: {
    formOpen: false,
    params: undefined,
    applyied: undefined
  },
  expandedRows: [],
  currentPage: 1,
  error: false,
  expandedRowsContents: {},
  list: null
};

const slice = createSlice({
  name: 'externalValue',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startEntityValueLoading(state) {
      state.isEntityValuesLoading = true;
    },
    stopEntityValueLoading(state) {
      state.isEntityValuesLoading = false;
    },
    setExpandedRows(state, action) {
      if (Array.isArray(action.payload)) {
        state.expandedRows = action.payload;
      } else {
        state.expandedRows.push(action.payload);
      }
    },
    setExpandedRowsContents(state, action) {
      state.expandedRowsContents = Object.assign(state.expandedRowsContents, action.payload);
    },

    setFilter(state, action) {
      state.filter = action.payload;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getExternalValuesListSuccess(state, action) {
      state.isLoading = false;
      state.currentPage = action.payload.pager.current_page;
      state.list = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setExpandedRows, setBulkExpandedRows, setExpandedRowsContents, setFilter } = slice.actions;

// ----------------------------------------------------------------------

export function setExternalValueStatus(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startEntityValueLoading());
    try {
      const response = await axios.post('/api/external/update/', params);
      dispatch(slice.actions.stopEntityValueLoading());
      // dispatch(getExternalValuesList());
      return response.data;
    } catch (error) {
      dispatch(slice.actions.stopEntityValueLoading());
      // dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}

export function getExternalValuesForEntity(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startEntityValueLoading());
    try {
      const response = await axios.get('/api/external/show', {
        params: {
          entity_id: params.entity_id,
          month: params.month,
          year: params.year
        }
      });
      dispatch(slice.actions.stopEntityValueLoading());
      return response.data;
    } catch (error) {
      dispatch(slice.actions.stopEntityValueLoading());
      return error;
    }
  };
}

// export function getExternalValuesForEntity(params) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startEntityValueLoading());
//     try {
//       const response = await axios.get('/api/external/show', {
//         params: {
//           entity_id: params.entity_id,
//           month: params.month,
//           year: params.year
//         }
//       });
//       dispatch(slice.actions.setSelectedEntityValues(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function getExternalValuesList(params = undefined) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { filter, currentPage } = getState().externalValue;
    try {
      const response = await axios.get('/api/external', {
        params: {
          page: !isNilOrBlank(params) && !isNilOrBlank(params.page) ? params.page : currentPage,
          paging: !isNilOrBlank(params) && !isNilOrBlank(params.paging) ? params.paging : true,
          per_page: !isNilOrBlank(params) && !isNilOrBlank(params.perPage) ? params.perPage : 10,
          search_text:
            !isNilOrBlank(params) && !isNilOrBlank(params.searchText) && params.searchText !== false
              ? params.searchText
              : undefined,
          ...filter.applyied
        }
      });

      dispatch(slice.actions.getExternalValuesListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
