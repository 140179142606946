import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import { Stack, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

FormOkCancelButtons.propTypes = {
  isSubmitting: PropTypes.bool,
  submitForm: PropTypes.func,
  okLabelProgress: PropTypes.string,
  cancelLabel: PropTypes.string,
  okLabel: PropTypes.string,
  onCancel: PropTypes.func
};

export default function FormOkCancelButtons({
  isSubmitting,
  submitForm,
  okLabelProgress,
  cancelLabel,
  okLabel,
  onCancel
}) {
  const navigate = useNavigate();

  return (
    <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
      <Button
        sx={{ textTransform: 'initial' }}
        onClick={() => {
          if (onCancel !== undefined) {
            onCancel();
          } else {
            navigate(-1);
          }
        }}
        disabled={isSubmitting}
        color="primary"
      >
        {cancelLabel}
      </Button>

      <LoadingButton
        sx={{ textTransform: 'initial' }}
        onClick={submitForm !== undefined && submitForm}
        type="submit"
        variant="contained"
        //  variant="outlined"
        loading={isSubmitting}
      >
        {isSubmitting ? okLabelProgress : okLabel}
      </LoadingButton>
    </Stack>
  );
}
