import React from 'react';
// utils
import axios from '../utils/axios';

const AnalyticsDataContext = React.createContext();

function AnalyticsDataProvider(props) {
  // const getScoreRanking = async (params) =>
  //   axios
  //     .get(`/api/data/ranking?entityId=${params.entityId}&year=${params.year}&quarter=${params.quarter}`)
  //     .then((response) => response.data)
  //     .catch((error) => console.log(error));

  const getEntities = async (params) =>
    axios
      .get(
        `/api/data/entities${
          params.entityId !== undefined && params.entityId !== '' ? `?entityId=${params.entityId}` : ''
        }${params.quarter !== undefined && params.quarter !== '' ? `&quarter=${params.quarter}` : ''}${
          params.year !== undefined && params.year !== '' ? `&year=${params.year}` : ''
        }`
      )
      .then((response) => response.data)
      .catch((error) => error);

  const getContracts = async (params) =>
    axios
      .get(
        `/api/data/contracts?quarter=${params.quarter}&year=${params.year}${
          params.listEntities !== undefined && params.listEntities !== '' ? `&list=${params.listEntities}` : ''
        }${params.groupSets !== undefined && params.groupSets !== '' ? `&group_sets=${params.groupSets}` : ''}${
          params.groups !== undefined && params.groups !== '' ? `&groups=${params.groups}` : ''
        }${params.currentUser !== undefined && params.currentUser !== '' ? `&current_user=${params.currentUser}` : ''}`
      )
      .then((response) => response.data)
      .catch((error) => error);

  const getPermutedGroupSets = async () =>
    axios
      .get(`/api/data/groupsets`)
      .then((response) => response.data)
      .catch((error) => error);

  // const getRootEntity = async () =>
  //   axios
  //     .get(`/api/data/root`)
  //     .then((response) => {
  //       const root = response.data;
  //       root.coordinates = JSON.parse(response.data.coordinates);
  //       return root;
  //     })
  //     .catch((error) => error);

  const getPyramid = async (userEntities = {}) =>
    axios
      .post(`/api/data/pyramid`, userEntities)
      .then((response) => response.data)
      .catch((error) => error);

  const getDefaultRole = async () =>
    axios
      .get(`/api/data/role`)
      .then((response) => response.data)
      .catch((error) => error);

  // const getBreadCrumbTrail = async (params) =>
  //   axios
  //     .get(
  //       `/api/data/breadcrumbs/${params.id !== undefined ? params.id : ''}${
  //         params.quarter !== undefined && params.quarter !== '' ? `&quarter=${params.quarter}` : ''
  //       }${params.year !== undefined && params.year !== '' ? `&year=${params.year}` : ''}`
  //     )
  //     .then((response) => response.data)
  //     .catch((error) => error);

  // const getPublishedPeriods = async () =>
  //   axios
  //     .get(`/api/data/periods`)
  //     .then((response) => response.data)
  //     .catch((error) => error);

  // const getWidgets = async () =>
  //   axios
  //     .get(`/api/data/widgets`)
  //     .then((response) => response.data)
  //     .catch((error) => error);

  const getElements = async () =>
    axios
      .get(`/api/data/elements`)
      .then((response) => response.data)
      .catch((error) => error);

  const getQuantityData = async (params) =>
    axios
      .get(
        `/api/data/?entityId=${params.entityId}&elementId=${params.elementId}&quarter=${params.quarter}&year=${params.year}&start_date=${params.startDate}&end_date=${params.endDate}`
      )
      .then((response) => response.data)
      .catch((error) => error);

  const getPreparedDashBoardBlock = async (params) =>
    axios
      .post(`/api/data/chart`, { formula_value: params })
      .then((response) => response.data)
      .catch((error) => error);

  const getDashBoardBlock = async (params) => {
    let response = null;
    const p = {
      id: params.id !== undefined ? params.id : undefined,
      format: params.format,
      kind: params.kind,
      fn: params.fn !== undefined ? params.fn : undefined,
      state_id: params.state_id !== undefined && params.state_id.length > 0 ? params.state_id : undefined,
      formula_id: params.formula_id !== undefined && params.formula_id.length > 0 ? params.formula_id : undefined,
      ids: params.ids !== undefined && params.ids.length > 0 ? params.ids : undefined,
      entity_id: params.entity_id !== undefined ? params.entity_id : undefined,
      start_date:
        params.period !== undefined && params.period.start !== undefined ? params.period.start.startDate : undefined,
      end_date: params.period !== undefined && params.period.end !== undefined ? params.period.end.endDate : undefined,

      start_quarter:
        params.period !== undefined && params.period.start !== undefined ? params.period.start.quarter : undefined,
      end_quarter:
        params.period !== undefined && params.period.end !== undefined ? params.period.end.quarter : undefined,

      start_year:
        params.period !== undefined && params.period.start !== undefined ? params.period.start.year : undefined,
      end_year: params.period !== undefined && params.period.start !== undefined ? params.period.end.year : undefined,

      cached: params.cached !== undefined ? params.cached : false,
      entity_group_by: params.entity_group_by !== undefined ? params.entity_group_by : 'Self',
      period_slider_type: params.period_slider_type !== undefined ? params.period_slider_type : 'Range',
      plot: params.plot !== undefined ? params.plot : 'entities_subject',
      period_type: params.period_type !== undefined ? params.period_type : 'quarter',
      plot_type: params.plot_type !== undefined ? params.plot_type : 'default',
      prefix: params.prefix !== undefined ? params.prefix : undefined,
      suffix: params.suffix !== undefined ? params.suffix : undefined,
      current_user: params.current_user !== undefined ? params.current_user : undefined
    };

    if (
      p.entity_id !== undefined &&
      p.start_date !== undefined &&
      p.end_date !== undefined &&
      p.ids !== undefined
      // && (p.state_id || p.formula_id)
    ) {
      response = await axios.post(`/api/data/chart`, { formula_value: p });
      //  console.log(response.data);
    }
    return response.data;
  };

  return (
    <AnalyticsDataContext.Provider
      value={{
        // getScoreRanking,
        // getWidgets,
        // getRootEntity,
        // getBreadCrumbTrail,
        getEntities,
        getContracts,
        getPermutedGroupSets,
        // getPublishedPeriods,
        getElements,
        getQuantityData,
        getDashBoardBlock,
        getPreparedDashBoardBlock,
        getPyramid,
        getDefaultRole
      }}
      {...props}
    />
  );
}

function useAnalytics() {
  const context = React.useContext(AnalyticsDataContext);
  if (context === undefined) {
    throw new Error(`useAnalytics must be used within a AnalyticsDataProvider`);
  }
  return context;
}

export { AnalyticsDataProvider, useAnalytics };
