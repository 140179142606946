import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  filter: {
    formOpen: false,
    params: undefined,
    applyied: undefined
  },
  currentPage: 1,
  error: false,
  selected: null,
  list: null,
  userPyramid: null,
  userPyramidIsLoading: false,
  tabIndex: '0',
  formOpen: false,
  crossAccessAssignment: {
    formOpen: false,
    roles: null,
    provinces: null
  },
  userRoles: null,
  trails: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startUserPyramidLoading(state) {
      state.userPyramidIsLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USER
    setSelectedUser(state, action) {
      // state.isLoading = false;
      state.selected = action.payload;
    },

    setSelectedUserPyramid(state, action) {
      state.userPyramidIsLoading = false;
      state.userPyramid = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.currentPage = action.payload.pager.current_page;
      state.list = action.payload;
    },
    // SET TABINDEX
    setTabIndex(state, action) {
      state.isLoading = false;
      state.tabIndex = action.payload;
    },

    setTrailsFilters(state, action) {
      state.isLoading = false;
      state.trails = { ...state.trails, isLoading: false, filters: action.payload };
    },

    startTrailsLoading(state) {
      state.isLoading = false;
      state.trails = { ...state.trails, isLoading: true };
    },

    setTrailsResults(state, action) {
      state.isLoading = false;
      state.trails = { ...state.trails, isLoading: false, results: action.payload };
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    setCrossAccessAssignment(state, action) {
      state.isLoading = false;
      state.crossAccessAssignment = action.payload;
    },
    // SET FORMOPEN
    setFormOpen(state, action) {
      state.isLoading = false;
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedUser, setFilter, setCrossAccessAssignment, setSelectedUserPyramid } = slice.actions;

// ----------------------------------------------------------------------

export function setFormOpen(open) {
  return (dispatch) => {
    dispatch(slice.actions.setFormOpen(open));
    if (open === false) {
      dispatch(slice.actions.setSelectedUser(null));
      dispatch(slice.actions.setSelectedUserPyramid(null));
    }
  };
}

// ----------------------------------------------------------------------

export function onTabChange(tabIndex) {
  return (dispatch) => {
    dispatch(slice.actions.setTabIndex(tabIndex));
  };
}

// ----------------------------------------------------------------------

export function getUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/users/${id}`);
      dispatch(
        getUserPyramid({
          // depth: 3,
          entities:
            response.data.user_entities.length > 0
              ? response.data.user_entities.map((userEntity) => userEntity.entity_id)
              : []
        })
      );
      dispatch(slice.actions.setSelectedUser(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList(params = undefined) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { filter, currentPage } = getState().user;
    try {
      const response = await axios.get('/api/users', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : currentPage,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined,
          ...filter.applyied
        }
      });

      dispatch(slice.actions.getUserListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete(`/api/users/${id}`);
      dispatch(getUserList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function passwordReset(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/users/reset/', { user: { id } });
      dispatch(setFormOpen(false));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
    return true;
  };
}

// ----------------------------------------------------------------------

export function createUpdateUser(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/users', params);
      } else {
        await axios.put(`/api/users/${params.id}`, { user: params });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserPyramid(userEntities = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startUserPyramidLoading());
    try {
      const response = await axios.post('/api/entities/pyramid', userEntities);
      dispatch(slice.actions.setSelectedUserPyramid(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTrailsFilters() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/users/audit_filters');
      dispatch(slice.actions.setTrailsFilters(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function fetchTrails(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startTrailsLoading());
    try {
      const response = await axios.post('/api/users/trails', params);
      dispatch(slice.actions.setTrailsResults(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function resendConfirmationEmail(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startTrailsLoading());
    try {
      await axios.post('/api/users/reconfirm', params);
      dispatch(getUserList());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCrossAccessAssignment() {
  return async (dispatch) => {
    dispatch(slice.actions.startTrailsLoading());
    try {
      const response = await axios.get('/api/users/crossaccess');
      dispatch(slice.actions.setCrossAccessAssignment({ ...response.data, formOpen: true }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
