import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { publishedPeriodFormatter } from '../../utils/core';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  quarters: null,
  contracts: null,
  groupSets: null,
  selectedGroupSets: null,
  publishedPeriods: null,
  primary: null,
  columnTitles: null,
  rows: null,
  values: null,
  entities: null,
  period: null,
  collapseAll: new Array(50).fill(false),
  rowExpanded: new Array(50).fill(false)
};

const slice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setQuarters(state, action) {
      state.isLoading = false;
      state.quarters = action.payload;
    },

    setGroupSets(state, action) {
      state.isLoading = false;
      state.groupSets = action.payload;
    },

    setCollapseAll(state, action) {
      state.isLoading = false;
      state.collapseAll = action.payload;
    },

    setRowExpanded(state, action) {
      state.isLoading = false;
      state.rowExpanded = action.payload;
    },

    setPeriod(state, action) {
      state.isLoading = false;
      state.period = action.payload;
    },

    setPublishedPeriods(state, action) {
      state.isLoading = false;
      state.publishedPeriods = action.payload;
    },

    setSelectedGroupSets(state, action) {
      state.isLoading = false;
      state.selectedGroupSets = action.payload;
    },

    setPrimary(state, action) {
      state.isLoading = false;
      state.primary = action.payload;
    },

    setColumnTitles(state, action) {
      state.isLoading = false;
      state.columnTitles = action.payload;
    },

    setRows(state, action) {
      state.isLoading = false;
      state.rows = action.payload;
    },

    setValues(state, action) {
      state.isLoading = false;
      state.values = action.payload;
    },

    setEntities(state, action) {
      state.isLoading = false;
      state.entities = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setPeriod, setSelectedGroupSets, setRowExpanded } = slice.actions;

// ----------------------------------------------------------------------

export function getQuarters() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/data_files/quarters');

      const formattedResp = response.data.reverse().map(publishedPeriodFormatter);
      dispatch(
        slice.actions.setPeriod({
          start: formattedResp[formattedResp.length - 1],
          end: formattedResp[formattedResp.length - 1]
        })
      );

      dispatch(slice.actions.setPublishedPeriods(formattedResp));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContracts(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/data/contracts', {
        params: {
          quarter: params.quarter,
          year: params.year,
          list: params?.listEntities || undefined,
          group_sets: params?.groupSets || undefined,
          groups: params?.groups || undefined,
          current_user: params?.currentUser || undefined
        }
      });

      if (response.data !== undefined) {
        dispatch(slice.actions.setCollapseAll(new Array(response.data.rows.length).fill(false)));
        dispatch(slice.actions.setRowExpanded(new Array(response.data.rows.length).fill(false)));
        dispatch(slice.actions.setPrimary(response.data.primary));
        dispatch(
          slice.actions.setColumnTitles(
            response.data.columns.map((title) => ({
              ...title,
              align: 'right'
            }))
          )
        );

        dispatch(slice.actions.setRows(response.data.rows));
        dispatch(slice.actions.setValues(response.data.values));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEntities(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/data/contracts', {
        params: {
          quarter: params.quarter,
          year: params.year,
          list: true,
          groups: params?.groups || undefined,
          current_user: params?.currentUser || undefined
        }
      });

      dispatch(slice.actions.setEntities(response.data.entities));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPermutedGroupSets(defaultGroupSes = {}) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/data/groupsets');

      const groupSets = response.data.map((combi) => ({
        value: `${combi[0].id},${combi[1].id}`,
        primary: combi[0].name,
        secondary: combi[1].name
      }));
      const selectedGroupSets =
        defaultGroupSes.length === 0
          ? `${response.data[0][0].id},${response.data[0][1].id}`
          : defaultGroupSes.join(',');

      dispatch(slice.actions.setSelectedGroupSets(selectedGroupSets));
      dispatch(slice.actions.setGroupSets(groupSets));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
