import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  selected: null,
  list: null,
  parentables: null,
  formOpen: false
};

const slice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedGroup(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    getGroupsSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    getGroupsParentables(state, action) {
      state.isLoading = false;
      state.parentables = action.payload;
    },

    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
      }
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFormOpen, setSelectedGroup } = slice.actions;

// ----------------------------------------------------------------------

export function getGroupsForParents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/groups', {
        params: {
          paging: false
        }
      });

      const parentGroups = response.data.data
        .filter((g) => g.parent === undefined && g.selectable === false)
        .map((grp) => ({
          value: grp.id,
          label: grp.name
        }));

      dispatch(slice.actions.getGroupsParentables(parentGroups));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGroup(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/groups/${id}`);
      dispatch(slice.actions.setSelectedGroup(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGroups(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/groups', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getGroupsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateGroup(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/groups', { group: params });
      } else {
        await axios.put(`/api/groups/${params.id}`, { group: params });
      }
      dispatch(getGroups());
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteGroup(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/groups/${id}`);
  };
}
