import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { useTheme } from '@mui/material/styles';
import { Stack, IconButton, InputAdornment, Box, Typography, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../hooks/useAuth';
// import useIsMountedRef from '../../hooks/useIsMountedRef';
import useLocales from '../../hooks/useLocales';
// redux
import { useAnalytics } from '../../contexts/AnalyticsDataProvider';
// components
import Iconify from '../Iconify';
import { FormProvider, RHFTextField } from '../hook-form';
import DropdownTreeAutoSelect from '../shared/EntitySelector/DropdownTreeAutoSelect';

// ----------------------------------------------------------------------

RegisterForm.propTypes = {
  setForm: PropTypes.func
};

export default function RegisterForm({ setForm }) {
  const theme = useTheme();
  const { register } = useAuth();
  const { translate: t } = useLocales();
  const { getPyramid, getDefaultRole } = useAnalytics();
  // const isMountedRef = useIsMountedRef();
  const [showPassword, setShowPassword] = useState(false);
  const [pyramid, setPyramid] = useState(null);
  const [defaultRole, setDefaultRole] = useState(undefined);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required(t('users.listFormUserNameValidation')),
    job_title: Yup.string().required(t('users.listFormUserJobTitleValidation')),
    phone_number: Yup.string().required(t('users.listFormUserPhoneNumberValidation')),
    email: Yup.string().email(t('login.invalidEmailFormat')).required(t('login.invalidEmailRequired')),
    password: Yup.string()
      .notOneOf([Yup.ref('email')], t('login.passwordEmailSame'))
      .min(
        8,
        t('login.passwordLengthError', {
          minNumber: 8
        })
      )
      .required(t('login.passwordRequired')),
    password_confirmation: Yup.string()
      .required(t('login.confirmPasswordRequied'))
      .oneOf([Yup.ref('password'), null], t('login.passwordsMissmatch')),
    user_entities_attributes: Yup.array().min(1, t('login.signUpEntitySelector'))
  });

  const defaultValues = {
    name: '',
    job_title: '',
    phone_number: '',
    email: '',
    password: '',
    password_confirmation: '',
    user_entities_attributes: [],
    admin_created: false
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods;

  const values = watch();

  const onSubmit = async (data) => {
    const resp = await register({
      ...data,
      is_active: false,
      role_id: defaultRole.id
    });
    if (resp === true) {
      setForm({ signIn: false, signUp: false, reset: false, resetForm: false });
      setValue('user_entities_attributes', []);
      reset();
    }
  };

  const loadRegistrationParams = async () => {
    const promises = [getPyramid(), getDefaultRole()];

    await Promise.all(promises)
      .then(([pyramidResp, defaultRoleResp]) => {
        setPyramid(pyramidResp);
        setDefaultRole(defaultRoleResp);
      })
      .catch(() => {});
  };

  const setPyramidState = (updates) => {
    setPyramid(updates);
  };

  useEffect(
    () => {
      if (pyramid !== null) {
        const currentValues = [...values.user_entities_attributes];

        const interObjects = currentValues
          .filter((currentValue) => pyramid.selected.includes(currentValue.entity_id))
          .map((currentValue) =>
            currentValue.id !== undefined ? { ...currentValue, _destroy: undefined } : { ...currentValue }
          );

        const addedObjects = pyramid.selected
          .filter(
            (selectedNodesId) => !currentValues.map((currentValue) => currentValue.entity_id).includes(selectedNodesId)
          )
          .map((selectedNodesId) => ({ entity_id: selectedNodesId }));

        const removedObjects = currentValues
          .filter((currentValue) => !pyramid.selected.includes(currentValue.entity_id))
          .map((currentValue) => currentValue.id !== undefined && { ...currentValue, _destroy: true })
          .filter((currentValue) => currentValue);

        setValue('user_entities_attributes', [...interObjects, ...addedObjects, ...removedObjects]);
      }
    },
    [pyramid] // eslint-disable-line
  );

  useEffect(() => {
    loadRegistrationParams();
  }, []); // eslint-disable-line

  return (
    <Box
      sx={{
        margin: theme.spacing(0, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      }}
      children={
        <>
          <Typography
            component="div"
            variant="subtitle1"
            color="primary"
            sx={{
              marginBottom: theme.spacing(3),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignText: 'center'
            }}
          >
            {t('login.signUpTitle')}
          </Typography>

          {(defaultRole === undefined || pyramid === null) && (
            <Typography
              component="div"
              variant="subtitle1"
              color="primary"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignText: 'center'
              }}
            >
              <CircularProgress size={32} />
            </Typography>
          )}
          {defaultRole !== undefined && defaultRole.id === null && (
            <Typography
              gutterBottom
              component="p"
              variant="body2"
              sx={{
                wordWrap: 'break-word',
                alignItems: 'center',
                alignText: 'center',
                color: 'text.secondary'
              }}
            >
              {t('login.signUpMissingDefaultUserRole')}
            </Typography>
          )}
          {pyramid !== null && defaultRole !== undefined && defaultRole.id !== null && (
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <RHFTextField disabled={isSubmitting} size="small" name="name" label={t('users.listFormUserName')} />
                <RHFTextField
                  disabled={isSubmitting}
                  size="small"
                  name="job_title"
                  label={t('users.listFormUserJobTitle')}
                />
                <RHFTextField
                  disabled={isSubmitting}
                  size="small"
                  name="phone_number"
                  label={t('users.listFormUserPhoneNumber')}
                />
                <RHFTextField disabled={isSubmitting} size="small" name="email" label={t('login.emailAddress')} />

                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                  <RHFTextField
                    size="small"
                    name="password"
                    label={t('login.password')}
                    disabled={isSubmitting}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <RHFTextField
                    size="small"
                    name="password_confirmation"
                    label={t('users.listFormUserPassWordConfirm')}
                    disabled={isSubmitting}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                            <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Stack>

                <DropdownTreeAutoSelect
                  mode="singleNode"
                  size="small"
                  fieldName="user_entities_attributes"
                  pyramid={pyramid.tree}
                  updatePyramid={setPyramidState}
                  selectorTreePlaceHolder={t('shared.entitySelectorTreePlaceHolder')}
                  disabled={isSubmitting}
                  error={errors.user_entities_attributes}
                />

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                  {t('login.signUpButton')}
                </LoadingButton>
              </Stack>
            </FormProvider>
          )}
        </>
      }
    />
  );
}
