import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isWarming: false,
  isSampling: false,
  isSearching: false,
  isSaving: false,
  samplingActiveStep: 0,
  error: false,
  selected: null,
  entities: null,
  cache: null,
  list: null
};

const slice = createSlice({
  name: 'sample',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      // state.isWarming = true;
      state.isLoading = true;
    },
    startWarning(state) {
      state.isWarming = true;
    },
    startSaving(state) {
      state.isSaving = true;
    },
    stopWarning(state) {
      state.isWarming = false;
    },
    stopSaving(state) {
      state.isSaving = false;
    },
    startSampling(state) {
      state.isSampling = true;
    },
    startSearching(state) {
      state.isSearching = true;
    },
    setSamplingActiveStep(state, action) {
      state.samplingActiveStep = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCacheSuccess(state, action) {
      state.isLoading = false;
      state.cache = action.payload;
    },

    getListSuccess(state, action) {
      state.isSampling = false;
      state.list = action.payload;
    },
    getFacilitySearchSuccess(state, action) {
      state.isSearching = false;
      state.entities = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSamplingActiveStep } = slice.actions;

// ----------------------------------------------------------------------
export function refreshCache(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startWarning());
    try {
      const response = await axios.post('/api/samples/warm', { sample: { id } });
      dispatch(slice.actions.getCacheSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createList(sampleSize) {
  return async (dispatch) => {
    dispatch(slice.actions.startSampling());
    try {
      const response = await axios.post('/api/samples', { sample: sampleSize });
      dispatch(slice.actions.getListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function persistSample(finalList) {
  return async (dispatch) => {
    dispatch(slice.actions.startSaving());
    try {
      const response = await axios.put('/api/samples', { sample: finalList });
      dispatch(slice.actions.stopSaving());
      dispatch(slice.actions.getCacheSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCache() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { samplingActiveStep } = getState().sample;
    try {
      const response = await axios.get('/api/samples');

      if (response.data.progress !== null && response.data.progress.percentage === 100) {
        if (samplingActiveStep === 0 || samplingActiveStep >= 2) {
          if ((response.data.sample !== null && response.data.sample.length > 0) || samplingActiveStep === 3) {
            dispatch(slice.actions.setSamplingActiveStep(3));
          } else {
            dispatch(slice.actions.setSamplingActiveStep(2));
          }
        } else {
          dispatch(slice.actions.setSamplingActiveStep(1));
        }
        dispatch(slice.actions.stopWarning());
      }
      if (response.data.data !== null) {
        dispatch(slice.actions.getListSuccess(response.data.data));
      }
      dispatch(slice.actions.getCacheSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchFacilities(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startSearching());
    try {
      const response = await axios.post('/api/samples/search', { sample: params });
      dispatch(slice.actions.getFacilitySearchSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
