import PropTypes from 'prop-types';
import * as Yup from 'yup';
// import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useLocales from '../../hooks/useLocales';

// components
import { FormProvider, RHFTextField } from '../hook-form';

// ----------------------------------------------------------------------

ResetPasswordForm.propTypes = {
  setForm: PropTypes.func
};

export default function ResetPasswordForm({ setForm }) {
  const theme = useTheme();
  const { passwordResetRequest } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { translate: t } = useLocales();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t('login.invalidEmailFormat')).required(t('login.invalidEmailRequired'))
  });

  const methods = useForm({
    resolver: yupResolver(ResetPasswordSchema),
    defaultValues: { email: '' }
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async (data) => {
    if (isMountedRef.current) {
      const resp = await passwordResetRequest({
        email: data.email
      });
      if (resp !== undefined) {
        setForm({ signIn: false, signUp: false, reset: false, resetForm: false });
        reset();
      }
    }
  };

  return (
    <Box
      sx={{
        margin: theme.spacing(0, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      }}
      children={
        <>
          <Typography
            component="div"
            variant="subtitle1"
            color="primary"
            sx={{
              marginBottom: theme.spacing(3),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignText: 'center'
            }}
          >
            {t('login.forgotPasswordLabel')}
          </Typography>

          <Typography
            variant="caption"
            color="primary"
            sx={{
              fontWeight: 'medium',
              fontStyle: 'italic',
              mb: 3
            }}
          >
            {t('login.passwordResetInstruction')}
          </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <RHFTextField name="email" label={t('login.emailAddress')} disabled={isSubmitting} />

              <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                {t('login.requestPasswordButton')}
              </LoadingButton>
            </Stack>
          </FormProvider>
        </>
      }
    />
  );
}
