import Iconify from '../components/Iconify';
// routes
import { PATH_PAGE, PATH_ADMIN } from '../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

export default function useNavConfig(t) {
  const defaultItems = [
    {
      title: t('home.mainMenuHome'),
      icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
      path: '/'
    },
    {
      title: t('home.mainMenuNews'),
      icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
      path: '/posts'
    },
    {
      title: t('home.mainMenuDocuments'),
      icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
      path: '/documents'
    },
    {
      title: t('home.mainMenuLogin'),
      icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
      path: PATH_ADMIN.root,
      button: true
    }
  ];

  const menuConfig = {
    default: [...defaultItems],
    lesotho: [
      {
        title: t('home.mainMenuHome'),
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/'
      },
      {
        title: t('home.mainMenuNews'),
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: PATH_PAGE.components
      },
      {
        title: t('home.mainMenuDocuments'),
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: PATH_PAGE.components
      },
      {
        title: t('home.mainMenuManagement'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: PATH_ADMIN.root
      }
    ],
    kyrgyz: [
      {
        title: t('home.mainMenuHome'),
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/'
      },
      {
        title: t('home.mainMenuAbout'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: '/about'
      },
      {
        title: t('home.mainMenuNews'),
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/news'
      },
      {
        title: t('home.mainMenuManagement'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: PATH_ADMIN.root
      }
    ],
    comoros: [
      {
        title: t('home.mainMenuHome'),
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/'
      },
      {
        title: t('home.mainMenuNews'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: '/posts'
      },
      {
        title: t('home.mainMenuDocuments'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: '/documents'
      },
      {
        title: t('home.mainMenuManagement'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: PATH_ADMIN.root
      }
    ],
    djibouti: [
      {
        title: t('home.mainMenuHome'),
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/'
      },
      // {
      //   title: t('home.mainMenuNews'),
      //   icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
      //   path: '/posts'
      // },
      // {
      //   title: t('home.mainMenuDocuments'),
      //   icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
      //   path: '/documents'
      // },
      {
        title: t('home.mainMenuManagement'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: PATH_ADMIN.root
      }
    ],
    kobikisa: [
      {
        title: t('home.mainMenuHome'),
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/'
      },
      // {
      //   title: t('home.mainMenuAbout'),
      //   icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
      //   path: '/about'
      // },
      {
        title: t('home.mainMenuNews'),
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/posts'
      },
      {
        title: t('home.mainMenuDocuments'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: '/documents'
      },
      {
        title: t('home.mainMenuManagement'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: PATH_ADMIN.root
      }
    ],
    tchad: [
      {
        title: t('home.mainMenuHome'),
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/'
      },
      {
        title: t('home.mainMenuNews'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: '/posts'
      },
      {
        title: t('home.mainMenuDocuments'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: '/documents'
      },
      {
        title: t('home.mainMenuManagement'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: PATH_ADMIN.root
      }
    ],
    cambodia: [
      {
        title: t('home.mainMenuHome'),
        icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
        path: '/'
      },
      {
        title: t('home.mainMenuLogin'),
        icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
        path: PATH_ADMIN.root,
        button: true
      }
    ]
  };
  return menuConfig[process.env.REACT_APP_PROJECT_NAME] === undefined
    ? menuConfig.default
    : menuConfig[process.env.REACT_APP_PROJECT_NAME];
}
