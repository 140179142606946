import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  selected: null,
  list: null,
  parentables: null,
  formOpen: false
};

const slice = createSlice({
  name: 'level',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedLevel(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    getLevelsSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    getLevelsParentables(state, action) {
      state.isLoading = false;
      state.parentables = action.payload;
    },

    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
      }
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFormOpen, setSelectedLevel } = slice.actions;

// ----------------------------------------------------------------------

export function getLevelsForParents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/stages', {
        params: {
          paging: false
        }
      });

      const parentLevels = response.data.data.map((level) => ({
        id: level.id,
        name: level.name,
        entitiesCount: level.entities_count
      }));

      dispatch(slice.actions.getLevelsParentables(parentLevels));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLevel(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/stages/${id}`);
      dispatch(slice.actions.setSelectedLevel(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getLevels(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/stages', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          start: params !== undefined && params.start !== undefined ? params.start : undefined,
          operational_only:
            params !== undefined && params.operationalOnly !== undefined ? params.operationalOnly : undefined,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });
      if (params !== undefined && params.setDefault !== undefined && params.setDefault === true) {
        const defaultLevel = response.data.data.find((level) => level.data_capture === true);
        dispatch(slice.actions.setSelectedLevel(defaultLevel));
      }

      dispatch(slice.actions.getLevelsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateLevel(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/stages', { stage: params });
      } else {
        await axios.put(`/api/stages/${params.id}`, { stage: params });
      }
      dispatch(getLevels());
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteLevel(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/stages/${id}`);
  };
}
