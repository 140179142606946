import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, AppBar, CardHeader, Toolbar, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
import useNavConfig from '../../hooks/useNavConfig';
// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
import useLocales from '../../hooks/useLocales';
// components
import Logo from '../../components/Logo';
//
import NavMobile from './nav/mobile/NavMobile';
import NavDesktop from './nav/desktop/NavDesktop';
// import navConfig from './MenuConfig';

import LanguagePopover from '../LanguagePopover';

// ----------------------------------------------------------------------

export default function Header() {
  const { translate: t } = useLocales();
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);
  const carouselRef = useRef(null);
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');
  const navConfig = useNavConfig(t);
  const availableLanguages = process.env.REACT_APP_AVAILABLE_LOCALE.split('|');

  return (
    <AppBar
      ref={carouselRef}
      color="transparent"
      sx={{
        boxShadow: 0,
        height: {
          md: HEADER.H_MAIN_DESKTOP - 6
        },
        ...(!isOffset && {
          ...bgBlur({ color: theme.palette.background.default })
        })
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16
            }
          })
        }}
      >
        <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box
            component="div"
            children={
              <CardHeader
                sx={{
                  padding: 0
                }}
                disableTypography={false}
                titleTypographyProps={{
                  component: 'h2',
                  color: 'textPrimary',
                  variant: 'subtitle1',
                  fontSize: { xs: 13, md: 17 }
                }}
                subheaderTypographyProps={{
                  color: 'textSecondary',
                  variant: 'caption',
                  fontSize: { xs: 8, md: 13 }
                }}
                avatar={
                  <RouterLink to="/">
                    <Logo />
                  </RouterLink>
                }
                title={t(`shared.projectMainTitle.${process.env.REACT_APP_PROJECT_NAME}`)}
                subheader={t(`shared.projectMainSubTitle.${process.env.REACT_APP_PROJECT_NAME}`)}
              />
            }
          />
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            {availableLanguages.length > 1 && (
              <Box
                component="div"
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  mr: 5,
                  display: {
                    md: 'flex'
                  }
                }}
              >
                <LanguagePopover availableLanguages={availableLanguages} />
              </Box>
            )}
            {isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />}
            {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
          </Box>
        </Container>
      </Toolbar>
      {/* <Box
        sx={{
          padding: 0.4,
          bgcolor: 'primary.dark',
          height: { xs: APP_BAR_NAV_MOBILE, md: APP_BAR_NAV_DESKTOP }
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <EntityNavigator />
        </Container>
      </Box> */}
      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx
      }}
      {...other}
    />
  );
}
