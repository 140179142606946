import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, Autocomplete } from '@mui/material';

// ----------------------------------------------------------------------

RHFAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
};

export default function RHFAutocomplete({ name, label, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          fullWidth
          multiple={field.multiple}
          value={field.value}
          renderInput={(params) => <TextField error={!!error} helperText={error?.message} {...params} label={label} />}
          {...other}
        />
      )}
    />
  );
}
