import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  list: null,
  parentables: null,
  selected: null,
  formOpen: false
};

const slice = createSlice({
  name: 'groupSet',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getGroupSetsSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    getGroupSetParentables(state, action) {
      state.isLoading = false;
      state.parentables = action.payload;
    },

    setSelectedGroupSet(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    // SET FORM OPEN
    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
      }
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFormOpen, setSelectedGroupSet } = slice.actions;

export function getGroupSet(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/group_sets/${id}`);
      dispatch(slice.actions.setSelectedGroupSet(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteGroupSet(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/group_sets/${id}`);
  };
}

export function getGroupSets(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/group_sets', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          principal_only: params !== undefined && params.principalOnly !== undefined ? params.principalOnly : undefined,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getGroupSetsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGroupSetsForParents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/group_sets', {
        params: {
          paging: false
        }
      });

      const parentGroupSets = response.data.data
        .filter((g) => g.parent === undefined || g.parent === null)
        .map((grp) => ({
          id: grp.id,
          name: grp.name
        }));

      dispatch(slice.actions.getGroupSetParentables(parentGroupSets));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateGroupSet(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/group_sets', { group_set: params });
      } else {
        await axios.put(`/api/group_sets/${params.id}`, { group_set: params });
      }
      dispatch(getGroupSets());
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
