import { createSlice } from '@reduxjs/toolkit';
// utils
// import { isWithinInterval } from 'date-fns';
import axios from '../../utils/axios';

// import { updateTree } from '../../utils/core';

const initialState = {
  isLoading: false,
  currentPage: 1,
  error: false,
  list: null,
  selected: null,
  frequencies: null,
  tabIndex: '0',
  formOpen: false,
  entitySelector: {
    formOpen: false,
    pyramid: null,
    values: {
      data_set_term_id: null,
      data_set_id: null,
      start_date: '',
      end_date: '',
      groups: null,
      associated_entities: null
    },
    isLoading: false,
    error: { hasError: undefined, errorMessage: undefined },
    isSubmitting: false
  },
  rule: null,
  paymentRule: null
};

const slice = createSlice({
  name: 'dataSet',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    pyramidStartLoading(state) {
      state.entitySelector = { ...state.entitySelector, isLoading: true };
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setDataSetsSuccess(state, action) {
      state.isLoading = false;
      state.currentPage = action.payload.pager.current_page;
      state.list = action.payload;
    },

    setSelectedDataSet(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    setFrequencies(state, action) {
      state.isLoading = false;
      state.frequencies = action.payload;
    },

    setTabIndex(state, action) {
      state.isLoading = false;
      state.tabIndex = action.payload;
    },

    setRule(state, action) {
      state.isLoading = false;
      state.rule = action.payload;
    },

    setPaymentRule(state, action) {
      state.isLoading = false;
      state.paymentRule = action.payload;
    },

    setPyramidSuccess(state, action) {
      state.isLoading = false;
      state.entitySelector = { ...state.entitySelector, isLoading: false, pyramid: action.payload };
    },

    setEntitySelectorValues(state, action) {
      state.entitySelector = { ...state.entitySelector, isLoading: false, values: action.payload };
    },

    setEntitySelectorSubmitting(state, action) {
      state.entitySelector = { ...state.entitySelector, isLoading: false, isSubmitting: action.payload };
    },

    entitySelectorHasError(state, action) {
      state.isLoading = false;
      state.entitySelector = { ...state.entitySelector, isLoading: false, error: action.payload };
    },

    // SET FORM OPEN
    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
        state.frequencies = null;
      }
      state.formOpen = action.payload;
    },

    // SET FORM OPEN
    setEntityFormOpen(state, action) {
      if (action.payload === false) {
        state.selected = null;
        state.frequencies = null;
        state.entitySelector = {
          ...state.entitySelector,
          pyramid: null,
          values: {
            data_set_term_id: null,
            data_set_id: null,
            start_date: '',
            end_date: '',
            groups: null,
            associated_entities: null
          }
        };
      }
      state.entitySelector = { ...state.entitySelector, isLoading: false, formOpen: action.payload };
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  setTabIndex,
  setFormOpen,
  setPyramidSuccess,
  setEntityFormOpen,
  setSelectedDataSet,
  setEntitySelectorValues,
  setEntitySelectorSubmitting,
  entitySelectorHasError
  //  resetRuleVariables
} = slice.actions;

export function getDataSets(params = undefined) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { currentPage } = getState().dataSet;
    try {
      const response = await axios.get('/api/data_sets', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : currentPage,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.setDataSetsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFrequencies() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/frequencies?page=1');

      dispatch(slice.actions.setFrequencies(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteDataSet(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/data_sets/${id}`);
  };
}

export function getDataSet(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/data_sets/${id}`);
      dispatch(slice.actions.setSelectedDataSet(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRule(dataSetId, ruleId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await axios.get(`/api/data_sets/${dataSetId}`);

      if (resp !== undefined && resp.data !== undefined) {
        const rule = resp.data.rules.find((rule) => rule.id === ruleId);

        const ruleState = {
          dataSet: resp.data,
          rule,
          formulas: resp.data.formulas.filter((formula) => formula.rule_id === ruleId),
          data_set_rules: resp.data.data_set_rules.find((rule) => rule.rule_id === ruleId)
        };

        dispatch(slice.actions.setRule(ruleState));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateDataSet(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/data_sets', { data_set: params });
      } else {
        await axios.put(`/api/data_sets/${params.id}`, { data_set: params });
      }
      dispatch(getDataSets());
      dispatch(setFormOpen(false));
      dispatch(setEntityFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteDataSetEntities(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post('/api/data_sets/dissociate_entities', params);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateFormula(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/rules', { rule: params });
      } else {
        await axios.put(`/api/rules/${params.id}`, { rule: params });
      }
      dispatch(slice.actions.setRule(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPyramid(params) {
  return async (dispatch) => {
    dispatch(slice.actions.pyramidStartLoading());
    try {
      const response = await axios.post('/api/entities/pyramid', params);
      dispatch(slice.actions.setPyramidSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.entitySelectorHasError(error));
    }
  };
}

export function getDataSetPyramid(id, linkId, startDate, endDate) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/data_sets/${id}`);
      dispatch(slice.actions.setSelectedDataSet(response.data));

      const associatedEntities = response.data.associated_entities.find(
        (associated) =>
          associated.id === linkId && associated.start_date === startDate && associated.end_date === endDate
      );

      if (associatedEntities !== undefined) {
        dispatch(
          getPyramid({
            start_date: associatedEntities.start_date,
            end_date: associatedEntities.end_date,
            entities: associatedEntities.entities.map((entity) => entity.entity_id)
          })
        );
        dispatch(
          setEntitySelectorValues({
            data_set_term_id: associatedEntities.id,
            data_set_id: id,
            start_date: associatedEntities.start_date,
            end_date: associatedEntities.end_date,
            groups: null,
            associated_entities: associatedEntities.entities
          })
        );
      } else {
        dispatch(
          getPyramid({
            entities: []
          })
        );

        dispatch(
          setEntitySelectorValues({
            data_set_term_id: null,
            data_set_id: id,
            start_date: '',
            end_date: '',
            groups: null,
            associated_entities: []
          })
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// const collectVariables = (ds, startDate, endDate, kind) => {
//   const variables = ds.variables.filter(
//     (variable) =>
//       variable.kind === kind &&
//       isWithinInterval(new Date(startDate), { start: new Date(variable.start_date), end: new Date(variable.end_date) })
//   );
//
//   return endDate === null
//     ? variables
//     : variables.filter((variable) =>
//         isWithinInterval(new Date(endDate), { start: new Date(variable.start_date), end: new Date(variable.end_date) })
//       );
// };
