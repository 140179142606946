import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { isNilOrBlank } from '../../utils/core';

const initialState = {
  isLoading: false,
  currentPage: 1,
  filter: {
    formOpen: false,
    params: undefined,
    applyied: undefined
  },
  error: false,
  selected: null,
  parentables: null,
  list: null,
  tabIndex: '0',
  formOpen: false
};

const slice = createSlice({
  name: 'dataElement',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedElement(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    getElementsSuccess(state, action) {
      state.isLoading = false;
      // console.log(action.payload);
      state.currentPage = action.payload.pager.current_page;
      state.list = action.payload;
    },

    getElementsParentables(state, action) {
      state.isLoading = false;
      state.parentables = action.payload;
    },

    setTabIndex(state, action) {
      state.isLoading = false;
      state.tabIndex = action.payload;
    },

    setFilter(state, action) {
      state.isLoading = false;
      state.filter = action.payload;
    },

    // SET FORMOPEN
    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
      }
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { setFormOpen, setSelectedElement, setFilter, setTabIndex } = slice.actions;

export function getElement(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/data_elements/${id}`);
      dispatch(slice.actions.setSelectedElement(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteElement(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/data_elements/${id}`);
  };
}

export function getElements(params = undefined) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.startLoading());
    const { filter, currentPage } = getState().dataElement;
    try {
      const response = await axios.get('/api/data_elements', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : currentPage,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          data_set_id: params !== undefined && params.dataSetId !== undefined ? params.dataSetId : undefined,
          start_date: params !== undefined && params.startDate !== undefined ? params.startDate : undefined,
          end_date: params !== undefined && params.endDate !== undefined ? params.endDate : undefined,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          has_tree: params !== undefined && params.has_tree !== undefined ? params.has_tree : false,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined,
          ...filter.applyied
        }
      });

      dispatch(slice.actions.getElementsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getElementsForParents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/data_elements', {
        params: {
          paging: false
        }
      });

      const parentElements = response.data.data
        .filter((dataElt) => isNilOrBlank(dataElt.parent_id))
        .map((dataElt) => ({
          id: dataElt.id,
          name: dataElt.name,
          dataSets: dataElt.associated_data_sets
        }));

      dispatch(slice.actions.getElementsParentables(parentElements));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateDataElements(params, liveEditor) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (isNilOrBlank(params.id)) {
        await axios.post('/api/data_elements', { data_element: params });
      } else {
        await axios.put(`/api/data_elements/${params.id}`, { data_element: params });
      }
      dispatch(getElements({ has_tree: liveEditor === undefined }));
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
