import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  selected: null,
  dataSetStates: null,
  dataElements: null,
  parentElements: null,
  incentiveType: 'Straight',
  incentiveForm: null,
  list: null
};

const slice = createSlice({
  name: 'incentive',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedIncentive(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    setIncentiveFormValues(state, action) {
      state.isLoading = false;
      state.incentiveForm = action.payload;
    },

    setIncentiveDataElements(state, action) {
      state.isLoading = false;
      state.dataElements = action.payload;
    },

    setIncentiveParentElements(state, action) {
      state.isLoading = false;
      state.parentElements = action.payload;
    },

    setIncentiveType(state, action) {
      state.isLoading = false;
      state.incentiveType = action.payload;
    },

    setDataSetStates(state, action) {
      state.isLoading = false;
      state.dataSetStates = action.payload;
    },

    getIncentivesSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startLoading,
  setSelectedIncentive,
  setIncentiveFormValues,
  setDataSetStates,
  setIncentiveDataElements,
  setIncentiveParentElements,
  setIncentiveType,
  hasError
} = slice.actions;

// ----------------------------------------------------------------------

export function getIncentives(params = undefined) {
  return async (dispatch) => {
    dispatch(setSelectedIncentive(null));
    dispatch(setIncentiveFormValues(null));
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/incentives', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getIncentivesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteIncentive(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/incentives/${id}`);
  };
}

export function getIncentive(id) {
  return async (dispatch) => {
    dispatch(setSelectedIncentive(null));
    dispatch(setIncentiveFormValues(null));
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/incentives/${id}`);
      dispatch(slice.actions.setSelectedIncentive(response.data));
      dispatch(
        slice.actions.setIncentiveFormValues({
          id: response.data.id,
          name: response.data.name,
          data_set_id: response.data.data_set.id,
          state_id: response.data.state.id,
          groups: response.data.groups,
          params: response.data.params.reduce((values, cur) => {
            values[cur.data_element_id] = cur.value;
            return values;
          }, {}),
          start_date: response.data.start_date,
          end_date: response.data.end_date
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateIncentives(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/incentives', { incentive: params });
      } else {
        await axios.put(`/api/incentives/${params.id}`, { incentive: params });
      }
      dispatch(setSelectedIncentive(null));
      dispatch(setIncentiveFormValues(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
