import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { isNilOrBlank } from '../../utils/core';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  selected: null,
  exportableFormulas: null,
  formOpen: false,
  // parameters: null,
  list: null
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedInvoice(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    setExportableFormulas(state, action) {
      state.isLoading = false;
      state.exportableFormulas = action.payload;
    },

    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    // SET FORMOPEN
    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
      }
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectedInvoice, setFormOpen, setExportableFormulas } = slice.actions;

// ----------------------------------------------------------------------

export function getInvoiceTypeRaw(id) {
  return isNilOrBlank(id)
    ? {
        id: undefined,
        name: '',
        title: '',
        stage_id: 1,
        frequency_id: 1,
        sequence: 0,
        skip_elements: false,
        is_active: true,
        layout: 'portrait',
        signatories: [],
        start_date: '',
        end_date: '',
        available_variables: [],
        exported_formulas: []
      }
    : axios
        .get(`/api/invoices/${id}`)
        .then((response) => response.data)
        .catch((error) => error);
}

export function getInvoiceType(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const response = await getInvoiceTypeRaw(id);
    dispatch(slice.actions.setSelectedInvoice(response));
  };
}

export function deleteInvoiceType(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/invoices/${id}`);
  };
}

export function getInvoiceTypes(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/invoices', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getInvoicesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateInvoiceTypes(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/invoices', { invoice: params });
      } else {
        await axios.put(`/api/invoices/${params.id}`, { invoice: params });
      }
      dispatch(setSelectedInvoice(null));
      dispatch(getInvoiceTypes());
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
