import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// hooks
import useActiveLink from '../../../../hooks/useActiveLink';
//
import { NavItem } from './NavItem';

// ----------------------------------------------------------------------

NavList.propTypes = {
  item: PropTypes.object,
  isOffset: PropTypes.bool
};

export default function NavList({ item, isOffset }) {
  const { pathname } = useLocation();

  const [openPopover, setOpenPopover] = useState(null);

  const { path, children } = item;

  const { active, isExternalLink } = useActiveLink(path, false);

  useEffect(() => {
    if (openPopover) {
      handleClosePopover();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleClick = (event) => {
    if (children) {
      handleOpenPopover(event);
    }
  };

  return (
    <NavItem
      item={item}
      isOffset={isOffset}
      active={active}
      open={Boolean(openPopover)}
      isExternalLink={isExternalLink}
      onClick={handleClick}
    />
  );
}
