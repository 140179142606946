import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, IconButton, InputAdornment, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';

// components
import Iconify from '../Iconify';
import { FormProvider, RHFTextField } from '../hook-form';

// ----------------------------------------------------------------------

NewPasswordForm.propTypes = {
  resetHeaders: PropTypes.object
};

export default function NewPasswordForm({ resetHeaders }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const { updatePassword } = useAuth();
  const { translate: t } = useLocales();

  const [showPassword, setShowPassword] = useState(false);

  const VerifyCodeSchema = Yup.object().shape({
    email: Yup.string().email(t('login.invalidEmailFormat')).required(t('login.invalidEmailRequired')),
    password: Yup.string()
      .notOneOf([Yup.ref('email')], t('login.passwordEmailSame'))
      .min(
        8,
        t('login.passwordLengthError', {
          minNumber: 8
        })
      )
      .required(t('login.passwordRequired')),
    confirmPassword: Yup.string()
      .required(t('login.confirmPasswordRequied'))
      .oneOf([Yup.ref('password'), null], t('login.passwordsMissmatch'))
  });

  const defaultValues = {
    email: resetHeaders.uid,
    password: '',
    confirmPassword: ''
  };

  const methods = useForm({
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting }
  } = methods;

  const onSubmit = async (data) => {
    if (isMountedRef.current) {
      const resp = await updatePassword({
        auth: { password: data.password, password_confirmation: data.confirmPassword },
        headers: resetHeaders
      });
      if (resp === true) {
        navigate('/auth/login', { replace: true });
        reset();
      }
    }
  };

  return (
    <Box
      sx={{
        margin: theme.spacing(0, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch'
      }}
      children={
        <>
          <Typography
            component="div"
            variant="subtitle1"
            color="primary"
            sx={{
              marginBottom: theme.spacing(3),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignText: 'center'
            }}
          >
            {t('login.resetPasswordTitle')}
          </Typography>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <RHFTextField name="email" label={t('login.emailAddress')} disabled />

              <RHFTextField
                autoFocus
                name="password"
                label={t('login.password')}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <RHFTextField
                name="confirmPassword"
                label={t('login.confirmPassword')}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                sx={{ mt: 3 }}
              >
                {t('login.updatePasswordButton')}
              </LoadingButton>
            </Stack>
          </FormProvider>
        </>
      }
    />
  );
}
