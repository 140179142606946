import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import {
  List,
  Badge,
  Typography,
  ListItemText,
  ListItem,
  ListItemButton,
  IconButton,
  Box,
  Divider,
  Collapse
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

// components
import { PromisePool } from '@supercharge/promise-pool';
import Iconify from '../../components/Iconify';
// import Scrollbar from '../../components/scrollbar/Scrollbar';
import { Scrollbar } from '../../components/scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { IconButtonAnimate } from '../../components/animate';
import Label from '../../components/Label';
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
import { useDispatch, useSelector } from '../../redux/store';
import {
  notificationCheck,
  setNotificationItems,
  notificationKeys,
  notificationFix
} from '../../redux/slices/dashboard';
import { isNilOrBlank } from '../../utils/core';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { translate: t } = useLocales();
  const { ability } = useAuth();
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.dashboard);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const fixConsistency = (key, title) => {
    if (window.confirm(t('dashboard.notificationFixConfirm', { title }))) {
      dispatch(notificationFix(key));
      // setOpen(null);
    }
  };

  const notificationCheckes = async (checks = []) => {
    const { results, errors } = await PromisePool.for(checks)
      .withConcurrency(1)
      .process(async (check) => dispatch(notificationCheck(check)));
    console.log(errors);
    dispatch(setNotificationItems(results.filter((r) => r).flat()));
  };

  useEffect(() => {
    if (ability.can('consistency', 'FormulaValue')) {
      dispatch(notificationKeys());
    }
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    const dataRefreshInternal = setInterval(() => {
      if (ability.can('consistency', 'FormulaValue') && !isNilOrBlank(notifications?.checks)) {
        notificationCheckes(notifications?.checks);
      }
    }, 250000);
    return () => {
      clearInterval(dataRefreshInternal);
    };
  }, [notifications]); // eslint-disable-line

  return (
    ability.can('consistency', 'FormulaValue') &&
    !isNilOrBlank(notifications) &&
    !isNilOrBlank(notifications.items) &&
    notifications.items.length > 0 && (
      <>
        <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen}>
          <Badge badgeContent={notifications.items.length} color="error">
            <Iconify icon="eva:bell-fill" width={20} height={20} />
          </Badge>
        </IconButtonAnimate>
        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1" color="primary">
                {t('dashboard.notificationTitle')}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('dashboard.notificationSubTitle', { count: notifications.items.length })}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ borderStyle: 'dashed' }} />
          <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
            <List disablePadding>
              {notifications.items.map((notification, i) =>
                !isNilOrBlank(notification.details) ? (
                  <ExpandableNotificationItem
                    key={notification.key}
                    notification={notification}
                    showDevider={i + 1 !== notifications.items.length}
                  />
                ) : (
                  <NotificationItem
                    key={notification.key}
                    fixFuction={fixConsistency}
                    notification={notification}
                    showDevider={i + 1 !== notifications.items.length}
                  />
                )
              )}
            </List>
          </Scrollbar>
        </MenuPopover>
      </>
    )
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  showDevider: PropTypes.bool,
  fixFuction: PropTypes.func,
  notification: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    level: PropTypes.string
  })
};

function NotificationItem({ notification, fixFuction, showDevider }) {
  return (
    <ListItem
      divider={showDevider}
      sx={{
        py: 1.5,
        mt: '1px'
      }}
      secondaryAction={
        <IconButton onClick={() => fixFuction(notification.key, notification.title)} sx={{ ml: 4 }}>
          <Iconify icon="eva:checkmark-circle-outline" width={20} height={20} />
        </IconButton>
      }
    >
      <ListItemText
        primary={<Label color={notification.level}>{notification.title}</Label>}
        secondary={
          <Typography component="span" variant="caption" sx={{ color: 'text.secondary' }}>
            {notification.description}
          </Typography>
        }
      />
    </ListItem>
  );
}

// ----------------------------------------------------------------------

ExpandableNotificationItem.propTypes = {
  showDevider: PropTypes.bool,
  notification: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    level: PropTypes.string,
    details: PropTypes.array
  })
};

function ExpandableNotificationItem({ notification, showDevider }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        divider={showDevider}
        sx={{
          py: 1.5,
          mt: '1px'
        }}
      >
        <ListItemText
          primary={<Label color={notification.level}>{notification.title}</Label>}
          secondary={
            <Typography component="span" variant={open ? 'subtitle2' : 'caption'} sx={{ color: 'text.secondary' }}>
              {notification.description}
            </Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          {notification.details.map((detail, dI) => (
            <ListItem divider disableGutters key={`detail-${dI}-${detail.item}`} sx={{ pl: 3 }}>
              <ListItemText
                primary={
                  <Typography component="span" variant="caption" sx={{ color: 'text.secondary' }}>
                    {detail.item}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}
