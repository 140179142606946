import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
// config
import { HEADER, NAV } from '../../config';
//
import DashboardNavbar from './DashboardNavbar';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick'
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.H_MOBILE + 24,
  paddingBottom: HEADER.H_MOBILE + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.H_MAIN_DESKTOP + 20,
    paddingBottom: HEADER.H_MAIN_DESKTOP + 20,
    width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter
    }),
    ...(collapseClick && {
      marginLeft: NAV.W_DASHBOARD_MINI
    })
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { isInitialized } = useAuth();

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 }
      }}
    >
      {isInitialized && <DashboardNavbar />}
      <MainStyle>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
