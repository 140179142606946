import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  formOpen: false,
  selected: null,
  currentStepIndex: null,
  operations: null,
  list: null
};

const slice = createSlice({
  name: 'workFlow',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedWorkFlow(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    setWorkFlowOperations(state, action) {
      state.isLoading = false;
      state.operations = action.payload;
    },

    getWorkFlowSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    // SET FORMOPEN
    setFormOpen(state, action) {
      state.isLoading = false;
      state.currentStepIndex = action.payload.index;
      state.formOpen = action.payload.status;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFormOpen, setSelectedWorkFlow } = slice.actions;

// ----------------------------------------------------------------------

export function getWorkFlow(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/work_flows/${id}`);
      dispatch(slice.actions.setSelectedWorkFlow(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteWorkFlow(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/work_flows/${id}`);
  };
}

export function getWorkFlows(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/work_flows', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getWorkFlowSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWorkFlowOperations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/work_flows/operations');
      dispatch(slice.actions.setWorkFlowOperations(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateWorkFlow(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/work_flows', { work_flow: params });
      } else {
        await axios.put(`/api/work_flows/${params.id}`, { work_flow: params });
      }
      dispatch(setSelectedWorkFlow(null));
      dispatch(getWorkFlows());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
