import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  IconButton,
  InputAdornment
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '../../../components/hook-form';

import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';

import FormOkCancelButtons from '../../../components/shared/FormOkCancelButtons';
import Iconify from '../../../components/Iconify';

UserProfileForm.propTypes = {
  userInfo: PropTypes.object,
  formOpen: PropTypes.bool,
  handleDialogFormOpen: PropTypes.func
};

export default function UserProfileForm({ userInfo, formOpen, handleDialogFormOpen }) {
  const { updateProfile } = useAuth();
  const { translate: t } = useLocales();
  const [tabIndex, setTabIndex] = useState('0');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const isMountedRef = useIsMountedRef();

  const handleClose = () => {
    handleDialogFormOpen(false);
  };

  const handleTabChange = (tIndex) => {
    setTabIndex(tIndex);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('users.listFormUserNameValidation')),
    job_title: Yup.string().required(t('users.listFormUserJobTitleValidation')),
    phone_number: Yup.string().required(t('users.listFormUserPhoneNumberValidation')),
    email: Yup.string()
      .email(t('users.listFormUserEmailFormatValidation'))
      .required(t('users.listFormUserEmailValidation')),
    // password: Yup.string().min(8, t('users.listFormUserPassWordValidation')),
    password: Yup.string().matches(/^(|.{8,})$/, {
      excludeEmptyString: true,
      message: t('users.listFormUserPassWordValidation')
    }),
    password_confirmation: Yup.string().oneOf([Yup.ref('password')], t('users.listFormUserPassWordConfValidation'))
  });

  const defaultValues = useMemo(
    () => ({
      name: userInfo.name,
      job_title: userInfo.job_title === undefined ? '' : userInfo.job_title,
      phone_number: userInfo.phone_number === undefined ? '' : userInfo.phone_number,
      email: userInfo.email === undefined ? '' : userInfo.email,
      password: '',
      password_confirmation: ''
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userInfo]
  );

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods;

  const values = watch();

  const onSubmit = async (values) => {
    const cleanValues = { ...values };
    if (cleanValues.password === '') {
      delete cleanValues.password;
      delete cleanValues.password_confirmation;
    }
    if (isMountedRef.current) {
      await updateProfile(cleanValues, handleClose, t);
      reset(defaultValues);
    }
  };

  return (
    <FormProvider methods={methods}>
      <TabContext value={String(tabIndex)}>
        <Dialog open={formOpen} onClose={handleClose} aria-labelledby="form-dialog-title" scroll="paper">
          <DialogTitle id="form-dialog-title">
            {values.name}
            <TabList
              onChange={(e, v) => {
                handleTabChange(v);
              }}
            >
              <Tab sx={{ textTransform: 'initial' }} label={t('users.listMenuGeneral')} value="0" />
              <Tab sx={{ textTransform: 'initial' }} label={t('users.listMenuLogin')} value="1" />
            </TabList>
          </DialogTitle>
          <DialogContent dividers>
            <Box
              sx={
                {
                  // pt: 2,
                  // mt: 2
                }
              }
            >
              <TabPanel value="0">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <RHFTextField
                      required
                      fullWidth
                      size="small"
                      disabled={isSubmitting}
                      name="name"
                      label={t('users.listFormUserName')}
                      error={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <RHFTextField
                      required
                      fullWidth
                      size="small"
                      disabled={isSubmitting}
                      name="job_title"
                      label={t('users.listFormUserJobTitle')}
                      error={errors.job_title}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <RHFTextField
                      required
                      fullWidth
                      size="small"
                      disabled={isSubmitting}
                      name="phone_number"
                      label={t('users.listFormUserPhoneNumber')}
                      error={errors.phone_number}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value="1">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <RHFTextField
                      required
                      fullWidth
                      size="small"
                      disabled={isSubmitting}
                      name="email"
                      type="email"
                      label={t('users.listFormUserEmailAddress')}
                      error={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <RHFTextField
                      fullWidth
                      size="small"
                      disabled={isSubmitting}
                      name="password"
                      label={t('users.listFormUserPassWord')}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      error={errors.password}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <RHFTextField
                      fullWidth
                      size="small"
                      disabled={isSubmitting}
                      name="password_confirmation"
                      label={t('users.listFormUserPassWordConfirm')}
                      type={showPasswordConfirmation ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                              edge="end"
                            >
                              <Iconify icon={showPasswordConfirmation ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                      error={errors.password_confirmation}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </Box>
          </DialogContent>
          <DialogActions>
            <FormOkCancelButtons
              isSubmitting={isSubmitting}
              submitForm={handleSubmit(onSubmit)}
              okLabelProgress={t('shared.formSaving')}
              okLabel={t('shared.formSave')}
              cancelLabel={t('shared.formCancel')}
              onCancel={handleClose}
            />
          </DialogActions>
        </Dialog>
      </TabContext>
    </FormProvider>
  );
}
