import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  months: null,
  period: null,
  status: null
};

const slice = createSlice({
  name: 'publication',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setMonths(state, action) {
      state.isLoading = false;
      state.months = action.payload;
    },

    setPeriod(state, action) {
      state.isLoading = false;
      state.period = action.payload;
    },

    setStatus(state, action) {
      state.isLoading = action.payload.status === 'running';
      state.status = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setPeriod } = slice.actions;

export function getMonths(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/data_files/months', {
        params: {
          include_quarters:
            params !== undefined && params.includeQuarters !== undefined && params.includeQuarters === true
              ? 1
              : undefined
        }
      });
      dispatch(slice.actions.setMonths(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDataPublicationStatus(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/data/status/', params);
      dispatch(slice.actions.setStatus(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function publishData(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/data/publish', params);
      dispatch(slice.actions.setStatus(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function unPublishData(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/data/unpublish', { formula_value: params });
      dispatch(slice.actions.setStatus(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
