import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { getParentElements } from '../../utils/core';

// const { Solver } = require('../../utils/solver');
import Solver from '../../utils/solver';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  id: undefined,
  data_set_id: null,
  entity_id: null,
  data_set_name: '',
  entity_name: '',
  entity_path: '',
  data_file_period: '',
  comment: null,
  month: null,
  quarter: null,
  year: null,
  output_value: null,
  auto_output: false,
  equationSolver: null,
  form: null,
  dataValues: null,
  dataValuesIds: null,
  isSubmitting: false,
  incentivesName: null,
  parentDataElements: null,
  outputValueError: null,
  infoDialog: { open: false, message: null },
  activeStep: 0
};

const slice = createSlice({
  name: 'dataEntry',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET ALL
    setAll(state, action) {
      return {
        ...state,
        ...action.payload
      };
    },

    setInfoDialog(state, action) {
      state.infoDialog = action.payload;
    },

    setActiveStep(state, action) {
      state.activeStep = action.payload;
    },

    // SET ALL
    setAllClear(state) {
      return {
        ...state,
        ...initialState
      };
    },

    // SET SOLVER
    setSolver(state, action) {
      state.isLoading = false;
      state.form = action.payload;
    },

    // SET FORM
    setForm(state, action) {
      state.isLoading = false;
      state.equationSolver = action.payload;
    },

    // SET COMMENT
    setComment(state, action) {
      state.isLoading = false;
      state.comment = action.payload;
    },

    // SET OUTPUT
    setOutPut(state, action) {
      state.isLoading = false;
      state.output_value = action.payload;
    },

    // SET OUTPUT ERROR
    setError(state, action) {
      state.isLoading = false;
      state.outputValueError = action.payload;
    },

    // SET IS SUBMITTING
    setIsSubmitting(state, action) {
      state.isLoading = false;
      state.isSubmitting = action.payload;
    },

    // SET VALUES
    setDataValues(state, action) {
      state.isLoading = false;
      state.dataValues = action.payload;
    },

    // SET VALUES
    setDataValuesIds(state, action) {
      state.isLoading = false;
      state.dataValuesIds = action.payload;
    },

    // RECALCULATE THE WHOLE THING
    resolveEntry(state) {
      state.isLoading = false;
      try {
        state.dataValues = state.equationSolver.solve(state.dataValues);
        state.error = false;
      } catch (error) {
        state.error = 'Error!! Verify your input';
      }
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setComment,
  setInfoDialog,
  setActiveStep,
  setOutPut,
  setDataValues,
  resolveEntry,
  setError,
  setIsSubmitting,
  setAllClear
} = slice.actions;

// ----------------------------------------------------------------------

export function getDataFile(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/data_files/edit/${params.fileId}/${params.entityId}/${params.month}/${params.year}/${params.dataSetId}`
      );

      const equationSolver = new Solver({
        ...response.data.entry_form.data_elements
          .map((dataElement) =>
            dataElement.states.filter((state) => state.disabled === false && state.kind === 'input')
          )
          .flat()
          .map((state) => state.token)
          .reduce((inputs, cur) => {
            inputs[cur] = '';
            return inputs;
          }, {}),
        ...Object.keys(response.data.tokenized_input).reduce((values, cur) => {
          values[cur] = '';
          return values;
        }, {}),
        ...response.data.entry_form.equations
      });

      const incentivesName = response.data.entry_form.data_elements[0].states
        .filter((state) => state.disabled === true && state.kind === 'input')
        .map((state) => state.name)
        .join(',');

      const parentDataElements = getParentElements(response.data.entry_form.data_elements);
      const payload = {
        id: response.data.id,
        data_set_id: response.data.data_set_id,
        entity_id: response.data.entity_id,
        month: response.data.month,
        quarter: response.data.quarter,
        year: response.data.year,
        output_value: response.data.output_value,
        auto_output: response.data.auto_output,
        data_set_name: response.data.data_set_name,
        entity_name: response.data.entity_name,
        entity_path: response.data.entity_path,
        data_file_period: response.data.data_file_period,
        comment: response.data.data_file_comment,
        completed: response.data.completed,
        form: response.data.entry_form,
        dataValues: equationSolver.solve(equationSolver.solve(response.data.tokenized_input)), // Needs refresh as to calculate the file on first load
        dataValuesIds: response.data.tokenized_input_ids,
        equationSolver,
        incentivesName,
        parentDataElements,
        isLoading: false,
        error: false
      };

      dispatch(slice.actions.setAll(payload));
      dispatch(slice.actions.setError(null));
      dispatch(slice.actions.resolveEntry(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function dataSetLiveEditor(dataSetId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/data_sets/live_edit/${dataSetId}`);
      const equationSolver = new Solver({
        ...response.data.entry_form.data_elements
          .map((dataElement) =>
            dataElement.states.filter((state) => state.disabled === false && state.kind === 'input')
          )
          .flat()
          .map((state) => state.token)
          .reduce((inputs, cur) => {
            inputs[cur] = '';
            return inputs;
          }, {}),
        ...Object.keys(response.data.tokenized_input).reduce((values, cur) => {
          values[cur] = '';
          return values;
        }, {}),
        ...response.data.entry_form.equations
      });

      const incentivesName = response.data.entry_form.data_elements[0].states
        .filter((state) => state.disabled === true && state.kind === 'input')
        .map((state) => state.name)
        .join(',');

      const parentDataElements = getParentElements(response.data.entry_form.data_elements);
      const payload = {
        id: response.data.id,
        data_set_id: response.data.data_set_id,
        entity_id: response.data.entity_id,
        month: response.data.month,
        quarter: response.data.quarter,
        year: response.data.year,
        output_value: response.data.output_value,
        auto_output: response.data.auto_output,
        data_set_name: response.data.data_set_name,
        entity_name: response.data.entity_name,
        entity_path: response.data.entity_path,
        data_file_period: response.data.data_file_period,
        comment: response.data.data_file_comment,
        completed: response.data.completed,
        form: response.data.entry_form,
        dataValues: equationSolver.solve(equationSolver.solve(response.data.tokenized_input)), // Needs refresh as to calculate the file on first load
        dataValuesIds: response.data.tokenized_input_ids,
        equationSolver,
        incentivesName,
        parentDataElements,
        isLoading: false,
        error: false
      };

      dispatch(slice.actions.setAll(payload));
      dispatch(slice.actions.setError(null));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
