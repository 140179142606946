import { m } from 'framer-motion';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled, Box, Stack, Drawer, Button, Divider, Link, Typography, IconButton } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import { Scrollbar } from '../../components/scrollbar';
import { useBoolean } from '../../hooks/useBoolean';
import Avatar from '../../components/Avatar';
import Iconify from '../../components/Iconify';
import { varHover } from '../../components/animate';
import AdminMenu from './AdminMenu';
import UserProfileForm from '../../pages/admin/users/UserProfileForm';
import LanguagePopover from '../LanguagePopover';

import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
import { isNilOrBlank } from '../../utils/core';

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

export default function AdminMenuDrawer() {
  const drawer = useBoolean();
  const { translate: t } = useLocales();
  const { user, ability, logOut } = useAuth();
  const [userProfileFormOpen, setUserProfileFormOpen] = useState(false);
  const availableLanguages = process.env.REACT_APP_AVAILABLE_LOCALE.split('|');

  return (
    !isNilOrBlank(user) &&
    user !== {} && (
      <>
        <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          onClick={drawer.onTrue}
          sx={{ mr: 1, color: 'text.primary' }}
        >
          <Iconify icon={'eva:menu-2-fill'} />
        </IconButton>

        <Drawer
          open={drawer.value}
          onClose={drawer.onFalse}
          anchor="right"
          /*  slotProps={{ backdrop: { invisible: true } }} ?? */
          PaperProps={{ sx: { width: 1, maxWidth: 360 } }}
        >
          <Stack sx={{ p: 0 }}>
            <Stack direction="column">
              {!isNilOrBlank(user) && user !== {} && (
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    if (ability.can('profile', 'User')) {
                      drawer.onFalse();
                      setUserProfileFormOpen(true);
                    }
                  }}
                  underline="none"
                  component={RouterLink}
                  to=""
                >
                  {!isNilOrBlank(user.name) && (
                    <AccountStyle>
                      <Avatar>{`${user.name.split(' ')[0][0]}${
                        user.name.split(' ')[1] !== undefined ? user.name.split(' ')[1][0] : ''
                      }`}</Avatar>
                      <Box sx={{ ml: 0.5 }}>
                        <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                          {user.name}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                          {user.job_title}
                        </Typography>
                      </Box>
                    </AccountStyle>
                  )}
                </Link>
              )}

              <Stack
                sx={{ mt: 0.5, mb: 0.5, mx: 1 }}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={2}
              >
                <Button
                  color="inherit"
                  size="small"
                  onClick={drawer.onFalse}
                  component={RouterLink}
                  to="/"
                  startIcon={<HomeOutlinedIcon />}
                >
                  {t('adminMenu.backToPublicLink')}
                </Button>
                <Button
                  onClick={() => {
                    if (window.confirm(t('adminMenu.logOutConfirmMessage'))) {
                      drawer.onFalse();
                      logOut();
                    }
                  }}
                  color="inherit"
                  size="small"
                  startIcon={<LogoutOutlinedIcon />}
                >
                  {t('adminMenu.logoutLink')}
                </Button>
              </Stack>
              <Divider />
            </Stack>
          </Stack>
          <Scrollbar>
            <AdminMenu onItemClick={drawer.onFalse} />
          </Scrollbar>
          <Box sx={{ p: 1 }}>
            {availableLanguages.length > 1 && <LanguagePopover availableLanguages={availableLanguages} />}
          </Box>
        </Drawer>
        {!isNilOrBlank(user) && userProfileFormOpen === true && (
          <UserProfileForm
            userInfo={user}
            handleDialogFormOpen={setUserProfileFormOpen}
            formOpen={userProfileFormOpen}
          />
        )}
      </>
    )
  );
}
