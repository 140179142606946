import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  list: null,
  selected: null,
  formOpen: false
};

const slice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getClientListSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },
    setSelectedClient(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },
    // SET FORM OPEN
    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
      }
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFormOpen, setSelectedClient } = slice.actions;

// ----------------------------------------------------------------------

export function lookup(params) {
  const result = axios
    .post('/api/clients/search/', { client: params })
    .then((response) =>
      response === null || response.data === null
        ? []
        : response.data.map((data) => ({
            id: data.id,
            name: data.name,
            details: data.ancestors === undefined ? undefined : data.ancestors.map((ance) => ance.name).join(' >>> ')
          }))
    )
    .catch((error) => error);

  return result;
}

export function getClients(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/clients', {
        params: {
          page: params?.page || 1,
          paging: params?.paging || true,
          per_page: params?.perPage || 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getClientListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClient(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/clients/${id}`);
      dispatch(slice.actions.setSelectedClient(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateClient(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/clients', { client: params });
      } else {
        await axios.put(`/api/clients/${params.id}`, { client: params });
      }
      dispatch(getClients());
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function getPyramid(params) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/entities/pyramid', params);
//       dispatch(slice.actions.getPyramidSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
