// @mui
import { Box, Container, Typography } from '@mui/material';
// routes
import { Link as ScrollLink } from 'react-scroll';

// components
import Logo from '../../components/Logo';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

export default function Footer() {
  const { translate: t } = useLocales();

  return (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default'
      }}
    >
      <Container>
        <ScrollLink to="move_top" spy smooth>
          <Logo footer sx={{ mb: 1, mx: 'auto', cursor: 'pointer' }} />
        </ScrollLink>

        <Typography variant="caption" component="p">
          {`© ${new Date().getFullYear()} - ${t(`shared.projectMainTitle.${process.env.REACT_APP_PROJECT_NAME}`)}`}
        </Typography>
        <Typography variant="caption" component="p">
          {`${t(`shared.projectMainSubTitle.${process.env.REACT_APP_PROJECT_NAME}`)}`}
        </Typography>
      </Container>
    </Box>
  );
}
