import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/admin');
  return (
    <Suspense
      fallback={
        <LoadingScreen
          isDashboard={isDashboard}
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'confirm/:confirmationToken',
          element: <Login />
        },
        {
          path: 'reset/:resetToken',
          element: <Login />
        }
      ]
    },

    // Dashboard Routes
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/admin/dashboard" replace />, index: true },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'datafiles/:fileId/:entityId/:month/:year/:dataSetId', element: <DataEntry /> },
        { path: 'datafiles/new/', element: <DataEntryWizard /> },
        { path: 'datafiles/external', element: <ExternalValuesList /> },
        { path: 'datafiles', element: <DataFilesList /> },
        { path: 'validation', element: <MetroStation /> },
        { path: 'validation/:ruleId/:entityId/:month/:quarter/:year/', element: <EntityValidation /> },
        { path: 'exports', element: <ExportsList /> },
        { path: 'samples', element: <SampleSelection /> },
        { path: 'invoices', element: <InvoiceBrowser /> },
        { path: 'invoices/:invoiceId/:entityId/:period/:year', element: <InvoiceBrowser /> },

        { path: 'publish/posts', element: <PostsList /> },
        { path: 'publish/posts/new/:kind', element: <Form /> },
        { path: 'publish/posts/edit/:kind/:postId/', element: <Form /> },
        { path: 'publish/data', element: <DataCMS /> },

        { path: 'settings/states', element: <StatesList /> },
        { path: 'settings/elements', element: <ElementsList /> },
        { path: 'settings/elementgroups', element: <ElementGroupsList /> },
        { path: 'settings/datasets', element: <DataSetsList /> },
        { path: 'settings/customfrequencies', element: <CustomFrenquenciesList /> },
        { path: 'settings/datasets/rule/:kind/:dataSetId/:ruleId', element: <ElementDataSetRuleForm /> },
        { path: 'settings/datasets/rule/:kind/:dataSetId', element: <ElementDataSetRuleForm /> },
        { path: 'settings/datasets/edit/:dataSetId', element: <DataElementLiveEdit /> },
        { path: 'settings/paymentrules', element: <PaymentRulesList /> },
        { path: 'settings/paymentrules/edit/:ruleId', element: <PaymentRuleForm /> },
        { path: 'settings/paymentrules/new', element: <PaymentRuleForm /> },
        { path: 'settings/incentives', element: <IncentivesList /> },
        { path: 'settings/incentives/edit/:incentiveId', element: <IncentivesFrm /> },
        { path: 'settings/incentives/new', element: <IncentivesFrm /> },
        { path: 'settings/invoicetypes', element: <InvoiceTypesList /> },
        { path: 'settings/integration', element: <IntegrationList /> },
        { path: 'settings/workflows', element: <WorkflowsList /> },
        { path: 'settings/workflows/edit/:workFlowId', element: <WorkFlowForm /> },
        { path: 'settings/workflows/new', element: <WorkFlowForm /> },

        { path: 'acl/users', element: <UsersList /> },
        { path: 'acl/users/trails', element: <UsersTrails /> },
        { path: 'acl/roles', element: <RolesList /> },
        { path: 'pyramid/levels', element: <LevelsList /> },
        { path: 'pyramid/entities', element: <EntitiesList /> },
        { path: 'pyramid/grpsets', element: <GroupSetsList /> },
        { path: 'pyramid/groups', element: <GroupsList /> },
        { path: 'pyramid/banks', element: <BanksList /> }
        //  {
        //  path: 'blog',
        //  children: [
        //      { element: <Navigate to="/admin/blog/posts" replace /> }
        //  { path: 'posts', element: <BlogPosts /> }
        //  { path: 'post/:title', element: <BlogPost /> }
        //  { path: 'new-post', element: <BlogNewPost /> }
        //    ]
        //  },
        //  {
        //    path: 'mail',
        //    children: [
        //      { element: <Navigate to="/admin/mail/all" replace /> }
        // { path: 'label/:customLabel', element: <Mail /> },
        // { path: 'label/:customLabel/:mailId', element: <Mail /> },
        // { path: ':systemLabel', element: <Mail /> },
        // { path: ':systemLabel/:mailId', element: <Mail /> }
        //    ]
        //  },
        //  {
        //  path: 'chat',
        // children: [
        //    { element: <Chat /> },
        //    { path: 'new', element: <Chat /> },
        //  { path: ':conversationKey', element: <Chat /> }
        //  ]
        //  }
        //  { path: 'calendar', element: <Calendar /> },
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'posts/:tag', element: <PostView /> },
        { path: 'posts', element: <Contents /> },
        { path: 'documents', element: <Contents /> },
        { path: 'about', element: <Contents /> },
        //      { path: 'contact-us', element: <Contact /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
        // {
        //   path: 'components',
        //   children: [
        //     { element: <ComponentsOverview /> },
        //     // FOUNDATIONS
        //     { path: 'color', element: <Color /> },
        //     { path: 'typography', element: <Typography /> },
        //     { path: 'shadows', element: <Shadows /> },
        //     { path: 'grid', element: <Grid /> },
        //     { path: 'icons', element: <Icons /> },
        //     // MATERIAL UI
        //     { path: 'accordion', element: <Accordion /> },
        //     { path: 'alert', element: <Alert /> },
        //     { path: 'autocomplete', element: <Autocomplete /> },
        //     { path: 'avatar', element: <Avatar /> },
        //     { path: 'badge', element: <Badge /> },
        //     { path: 'breadcrumbs', element: <Breadcrumb /> },
        //     { path: 'buttons', element: <Buttons /> },
        //     { path: 'checkbox', element: <Checkbox /> },
        //     { path: 'chip', element: <Chip /> },
        //     { path: 'dialog', element: <Dialog /> },
        //     { path: 'label', element: <Label /> },
        //     { path: 'list', element: <List /> },
        //     { path: 'menu', element: <Menu /> },
        //     { path: 'pagination', element: <Pagination /> },
        //     { path: 'pickers', element: <Pickers /> },
        //     { path: 'popover', element: <Popover /> },
        //     { path: 'progress', element: <Progress /> },
        //     { path: 'radio-button', element: <RadioButtons /> },
        //     { path: 'rating', element: <Rating /> },
        //     { path: 'slider', element: <Slider /> },
        //     { path: 'snackbar', element: <Snackbar /> },
        //     { path: 'stepper', element: <Stepper /> },
        //     { path: 'switch', element: <Switches /> },
        //     { path: 'table', element: <Table /> },
        //     { path: 'tabs', element: <Tabs /> },
        //     { path: 'textfield', element: <Textfield /> },
        //     { path: 'timeline', element: <Timeline /> },
        //     { path: 'tooltip', element: <Tooltip /> },
        //     { path: 'transfer-list', element: <TransferList /> },
        //     { path: 'tree-view', element: <TreeView /> },
        //     { path: 'data-grid', element: <DataGrid /> },
        //     // EXTRA COMPONENTS
        //     { path: 'chart', element: <Charts /> },
        //     { path: 'map', element: <Map /> },
        //     { path: 'editor', element: <Editor /> },
        //     { path: 'copy-to-clipboard', element: <CopyToClipboard /> },
        //     { path: 'upload', element: <Upload /> },
        //     { path: 'carousel', element: <Carousel /> },
        //     { path: 'multi-language', element: <MultiLanguage /> },
        //     { path: 'animate', element: <Animate /> },
        //     { path: 'mega-menu', element: <MegaMenu /> },
        //     { path: 'form-validation', element: <FormValidation /> }
        //   ]
        // }
      ]
    },
    {
      path: '/:entityId',
      element: <MainLayout />,
      children: [{ element: <HomePage /> }]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// Dashboard
const ExportsList = Loadable(lazy(() => import('../pages/admin/exports/ExportsList')));
const SampleSelection = Loadable(lazy(() => import('../pages/admin/sample/SampleSelection')));
const Form = Loadable(lazy(() => import('../pages/admin/cms/Form')));
const PostsList = Loadable(lazy(() => import('../pages/admin/cms/PostsList')));
const DataFilesList = Loadable(lazy(() => import('../pages/admin/datafiles/DataFilesList')));
const ExternalValuesList = Loadable(lazy(() => import('../pages/admin/externalvalues/ExternalValuesList')));
const DataEntry = Loadable(lazy(() => import('../pages/admin/datafiles/DataEntry')));
const DataEntryWizard = Loadable(lazy(() => import('../pages/admin/datafiles/wizard/DataEntryWizard')));
const Dashboard = Loadable(lazy(() => import('../pages/admin/dashboard/Dashboard')));
const UsersTrails = Loadable(lazy(() => import('../pages/admin/users/UsersTrails')));
const UsersList = Loadable(lazy(() => import('../pages/admin/users/UsersList')));
const RolesList = Loadable(lazy(() => import('../pages/admin/roles/RolesList')));
const StatesList = Loadable(lazy(() => import('../pages/admin/states/StatesList')));
const ElementsList = Loadable(lazy(() => import('../pages/admin/dataelements/ElementsList')));
const ElementGroupsList = Loadable(lazy(() => import('../pages/admin/dataelementgroups/ElementGroupsList')));
const DataSetsList = Loadable(lazy(() => import('../pages/admin/datasets/DataSetsList')));
const CustomFrenquenciesList = Loadable(lazy(() => import('../pages/admin/customFrenquencies/CustomFrenquenciesList')));
const ElementDataSetRuleForm = Loadable(lazy(() => import('../pages/admin/rules/ElementDataSetRuleForm')));
const DataElementLiveEdit = Loadable(lazy(() => import('../pages/admin/datasets/liveeditor/DataElementLiveEdit')));
const LevelsList = Loadable(lazy(() => import('../pages/admin/levels/LevelsList')));
const EntitiesList = Loadable(lazy(() => import('../pages/admin/entities/EntitiesList')));
const GroupSetsList = Loadable(lazy(() => import('../pages/admin/groupsets/GroupSetsList')));
const GroupsList = Loadable(lazy(() => import('../pages/admin/groups/GroupsList')));
const BanksList = Loadable(lazy(() => import('../pages/admin/banks/BanksList')));
const PaymentRulesList = Loadable(lazy(() => import('../pages/admin/rules/PaymentRulesList')));
const PaymentRuleForm = Loadable(lazy(() => import('../pages/admin/rules/PaymentRuleForm')));
const IncentivesList = Loadable(lazy(() => import('../pages/admin/incentives/IncentivesList')));
const IncentivesFrm = Loadable(lazy(() => import('../pages/admin/incentives/IncentivesFrm')));
const InvoiceTypesList = Loadable(lazy(() => import('../pages/admin/invoices/InvoiceTypesList')));
const DataCMS = Loadable(lazy(() => import('../pages/admin/cms/DataCMS')));
const InvoiceBrowser = Loadable(lazy(() => import('../pages/admin/invoices/InvoiceBrowser')));
const IntegrationList = Loadable(lazy(() => import('../pages/admin/integration/IntegrationList')));
const WorkflowsList = Loadable(lazy(() => import('../pages/admin/workflows/WorkflowsList')));
const WorkFlowForm = Loadable(lazy(() => import('../pages/admin/workflows/WorkFlowForm')));
const MetroStation = Loadable(lazy(() => import('../pages/admin/validation/MetroStation')));
const EntityValidation = Loadable(lazy(() => import('../pages/admin/validation/EntityValidation')));

// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));

//
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const PostView = Loadable(lazy(() => import('../pages/PostView')));
const Contents = Loadable(lazy(() => import('../pages/public/shared/contents/Contents')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
