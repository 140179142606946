import axios from 'axios';
import { defaultLang } from '../config';
// ----------------------------------------------------------------------
const locale = window.localStorage.getItem(`${process.env.REACT_APP_PROJECT_NAME}-i18nextLng`) || defaultLang.value;
const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API}/${locale}`
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
