import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  selected: null,
  list: null,
  formOpen: false
};

const slice = createSlice({
  name: 'state',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET STATES
    getStatesSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
      }
      state.formOpen = action.payload;
    },

    setSelectedState(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFormOpen, getStatesSuccess, setSelectedState } = slice.actions;

// ----------------------------------------------------------------------

export function getState(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/states/${id}`);
      dispatch(slice.actions.setSelectedState(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteState(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/states/${id}`);
  };
}

export function createUpdateState(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/states', { state: params });
      } else {
        await axios.put(`/api/states/${params.id}`, { state: params });
      }
      dispatch(getStates());
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStates(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/states', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getStatesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
