import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  selected: null,
  list: null,
  formOpen: false
};

const slice = createSlice({
  name: 'customFrequency',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSelectedCustomFrequency(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
    },

    getCustomFrequencySuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    setFormOpen(state, action) {
      state.isLoading = false;
      if (action.payload === false) {
        state.selected = null;
      }
      state.formOpen = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setFormOpen, setSelectedCustomFrequency } = slice.actions;

// ----------------------------------------------------------------------

export function getCustomFrequency(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/custom_frequencies/${id}`);
      dispatch(slice.actions.setSelectedCustomFrequency(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCustomFrequencies(params = undefined) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/custom_frequencies', {
        params: {
          page: params !== undefined && params.page !== undefined ? params.page : 1,
          paging: params !== undefined && params.paging !== undefined ? params.paging : true,
          per_page: params !== undefined && params.perPage !== undefined ? params.perPage : 10,
          search_text:
            params !== undefined &&
            params.searchText !== undefined &&
            params.searchText !== null &&
            params.searchText !== false
              ? params.searchText
              : undefined
        }
      });

      dispatch(slice.actions.getCustomFrequencySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUpdateCustomFrequency(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (params.id === undefined || params.id === '') {
        await axios.post('/api/custom_frequencies', { custom_frequency: params });
      } else {
        await axios.put(`/api/custom_frequencies/${params.id}`, { custom_frequency: params });
      }
      dispatch(getCustomFrequencies());
      dispatch(setFormOpen(false));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function duplicateCustomFrequency(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.post('/api/custom_frequencies/duplicate/', { custom_frequency: { id } });
  };
}

export function deleteCustomFrequency(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await axios.delete(`/api/custom_frequencies/${id}`);
  };
}
