import React from 'react';
import PropTypes from 'prop-types';
import { Stack, TextField, Box } from '@mui/material';
import { isBefore, isSameDay } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormContext, Controller } from 'react-hook-form';

RHFDateRangePicker.propTypes = {
  startDateField: PropTypes.string,
  endDateField: PropTypes.string,
  startText: PropTypes.string,
  endText: PropTypes.string,
  separatorLabel: PropTypes.string,
  inputFormat: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  inputViews: PropTypes.array,
  inputSize: PropTypes.string,
  startDateValue: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  onDateChange: PropTypes.func
};

export default function RHFDateRangePicker({
  startDateField,
  endDateField,
  startText,
  endText,
  separatorLabel,
  inputFormat,
  inputViews,
  inputSize,
  startDateValue,
  disabled,
  required,
  minDate,
  maxDate,
  onDateChange
}) {
  const {
    control,
    formState: { isSubmitting }
  } = useFormContext();

  return (
    <Stack
      direction="row"
      divider={<Box sx={{ paddingTop: 1, textAlign: 'center' }}>{separatorLabel}</Box>}
      spacing={2}
    >
      <Controller
        name={startDateField}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            disableMaskedInput
            disabled={disabled || isSubmitting}
            label={startText}
            inputFormat={inputFormat}
            openTo="year"
            views={inputViews === undefined ? ['year', 'month', 'day'] : inputViews}
            minDate={
              minDate !== undefined && minDate !== null && minDate !== '' ? new Date(minDate) : new Date('2010-01-01')
            }
            maxDate={maxDate !== undefined && maxDate !== null && maxDate !== '' ? new Date(maxDate) : undefined}
            value={field.value}
            onChange={(startDate) => onDateChange(startDateField, startDate)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required={required}
                size={inputSize}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        )}
      />

      <Controller
        name={endDateField}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            disableMaskedInput
            disabled={startDateValue === null || startDateValue === '' || disabled || isSubmitting}
            label={endText}
            inputFormat={inputFormat}
            openTo="year"
            views={inputViews === undefined ? ['year', 'month', 'day'] : inputViews}
            minDate={new Date(startDateValue)}
            maxDate={maxDate !== undefined && maxDate !== null && maxDate !== '' ? new Date(maxDate) : undefined}
            value={field.value === '' ? null : field.value}
            onChange={(newEndDateValue) => {
              const startDateVal = new Date(startDateValue);
              const endDateVal = new Date(newEndDateValue);

              if (isBefore(startDateVal, endDateVal) || isSameDay(startDateVal, endDateVal)) {
                onDateChange(endDateField, newEndDateValue);
              } else {
                onDateChange(endDateField, '');
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                required={required}
                size={inputSize}
                error={!!error}
                helperText={error?.message}
              />
            )}
          />
        )}
      />
    </Stack>
  );
}
