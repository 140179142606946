import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  publishedPeriods: null,
  entities: null,
  groupSets: null,
  selectedGroupSets: null,
  rootEntity: null,
  breadCrumbTrail: null,
  widgets: null,
  elements: null,
  collapseAll: null,
  rowExpanded: null
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SET PERIODS
    setPublishedPeriods(state, action) {
      state.isLoading = false;
      state.publishedPeriods = action.payload;
    },

    setContracts(state, action) {
      state.isLoading = false;
      state.contracts = action.payload;
    },

    setSelectedGroupSets(state, action) {
      state.isLoading = false;
      state.selectedGroupSets = action.payload;
    },

    setGroupSets(state, action) {
      state.isLoading = false;
      state.groupSets = action.payload;
    },

    setCollapseAll(state, action) {
      state.isLoading = false;
      state.collapseAll = action.payload;
    },

    setRowExpanded(state, action) {
      state.isLoading = false;
      state.rowExpanded = action.payload;
    }

    // // CREATE EVENT
    // createEventSuccess(state, action) {
    //   const newEvent = action.payload;
    //   state.isLoading = false;
    //   state.events = [...state.events, newEvent];
    // },
    //
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getPublishedPeriods() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/data/periods');
      dispatch(slice.actions.setPublishedPeriods(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// export function createEvent(newEvent) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/calendar/events/new', newEvent);
//       dispatch(slice.actions.createEventSuccess(response.data.event));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
//
// // ----------------------------------------------------------------------
//
// export function updateEvent(eventId, updateEvent) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/api/calendar/events/update', {
//         eventId,
//         updateEvent
//       });
//       dispatch(slice.actions.updateEventSuccess(response.data.event));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
//
// // ----------------------------------------------------------------------
//
// export function deleteEvent(eventId) {
//   return async (dispatch) => {
//     dispatch(slice.actions.startLoading());
//     try {
//       await axios.post('/api/calendar/events/delete', { eventId });
//       dispatch(slice.actions.deleteEventSuccess({ eventId }));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }
//
// // ----------------------------------------------------------------------
//
// export function selectRange(start, end) {
//   return async (dispatch) => {
//     dispatch(
//       slice.actions.selectRange({
//         start: start.getTime(),
//         end: end.getTime()
//       })
//     );
//  };
// }

//
// import React from "react";
// import wretch from "wretch";
//
// const AnalyticsDataContext = React.createContext();
//
// function AnalyticsDataProvider(props) {
//   const getScoreRanking = async params => {
//     return await wretch(
//       process.env.REACT_APP_API +
//         "/api/data/ranking?entityId=" +
//         params.entityId +
//         "&year=" +
//         params.year +
//         "&quarter=" +
//         params.quarter
//     )
//       .get()
//       .json()
//       .catch(error => error);
//   };
//
//   const getEntities = async params => {
//     return await wretch(
//       process.env.REACT_APP_API +
//         "/api/data/entities" +
//         (params.entityId !== undefined && params.entityId !== ""
//           ? "?entityId=" + params.entityId
//           : "") +
//         (params.quarter !== undefined && params.quarter !== ""
//           ? "&quarter=" + params.quarter
//           : "") +
//         (params.year !== undefined && params.year !== ""
//           ? "&year=" + params.year
//           : "")
//     )
//       .get()
//       .json()
//       .catch(error => error);
//   };
//
//   const getPermutedGroupSets = async () => {
//     return await wretch(process.env.REACT_APP_API + "/api/data/groupsets")
//       .post()
//       .json()
//       .catch(error => error);
//   };
//
//   const getRootEntity = async () => {
//     return await wretch(process.env.REACT_APP_API + "/api/data/root")
//       .get()
//       .json()
//       .catch(error => error);
//   };
//
//   const getBreadCrumbTrail = async params => {
//     return await wretch(
//       process.env.REACT_APP_API +
//         "/api/data/breadcrumbs/" +
//         (params.id !== undefined ? params.id : "") +
//         (params.quarter !== undefined && params.quarter !== ""
//           ? "&quarter=" + params.quarter
//           : "") +
//         (params.year !== undefined && params.year !== ""
//           ? "&year=" + params.year
//           : "")
//     )
//       .get()
//       .json()
//       .catch(error => error);
//   };
//
//   const getPublishedPeriods = async () => {
//     return await wretch(process.env.REACT_APP_API + "/api/data/periods")
//       .get()
//       .json()
//       .catch(error => error);
//   };
//
//   const getWidgets = async params => {
//     return await wretch(process.env.REACT_APP_API + "/api/data/widgets")
//       .get()
//       .json()
//       .catch(error => error);
//   };
//
//   // const listPublishedContents = async (params = {}) => {
//   //   return await wretch(
//   //     process.env.REACT_APP_API +
//   //       "/api/published?page=" +
//   //       (params.page === undefined ? 1 : params.page) +
//   //       "&paging=" +
//   //       (params.paging === undefined ? true : params.paging) +
//   //       "&per_page=" +
//   //       (params.perPage === undefined ? 10 : params.perPage) +
//   //       (params.searchText !== undefined &&
//   //       params.searchText !== null &&
//   //       params.searchText !== false
//   //         ? "&search_text=" + params.searchText
//   //         : "")
//   //   )
//   //     .get()
//   //     .json()
//   //     .catch(error => error);
//   // };
//
//   const getElements = async () => {
//     return await wretch(process.env.REACT_APP_API + "/api/data/elements")
//       .get()
//       .json()
//       .catch(error => error);
//   };
//
//   const getQuantityData = async params => {
//     return await wretch(
//       process.env.REACT_APP_API +
//         "/api/data/?entityId=" +
//         params.entityId +
//         "&elementId=" +
//         params.elementId +
//         "&quarter=" +
//         params.quarter +
//         "&year=" +
//         params.year +
//         "&start_date=" +
//         params.startDate +
//         "&end_date=" +
//         params.endDate
//     )
//       .get()
//       .json()
//       .catch(error => error);
//   };
//
//   const getDashBoardBlock = async params => {
//     // console.log(params.period.start);
//     const p = {
//       id: params.id !== undefined ? params.id : undefined,
//       format: params.format,
//       kind: params.kind,
//       fn: params.fn !== undefined ? params.fn : undefined,
//       state_id:
//         params.state_id !== undefined && params.state_id.length > 0
//           ? params.state_id
//           : undefined,
//       formula_id:
//         params.formula_id !== undefined && params.formula_id.length > 0
//           ? params.formula_id
//           : undefined,
//       ids:
//         params.ids !== undefined && params.ids.length > 0
//           ? params.ids
//           : undefined,
//       entity_id: params.entity_id !== undefined ? params.entity_id : undefined,
//       start_date:
//         params.period !== undefined && params.period.start !== undefined
//           ? params.period.start.startDate
//           : undefined,
//       end_date:
//         params.period !== undefined && params.period.end !== undefined
//           ? params.period.end.endDate
//           : undefined,
//
//       start_quarter:
//         params.period !== undefined && params.period.start !== undefined
//           ? params.period.start.quarter
//           : undefined,
//       end_quarter:
//         params.period !== undefined && params.period.end !== undefined
//           ? params.period.end.quarter
//           : undefined,
//
//       start_year:
//         params.period !== undefined && params.period.start !== undefined
//           ? params.period.start.year
//           : undefined,
//       end_year:
//         params.period !== undefined && params.period.start !== undefined
//           ? params.period.end.year
//           : undefined,
//
//       cached: params.cached !== undefined ? params.cached : false,
//       entity_group_by:
//         params.entity_group_by !== undefined ? params.entity_group_by : "Self",
//       period_slider_type:
//         params.period_slider_type !== undefined
//           ? params.period_slider_type
//           : "Range",
//       plot: params.plot !== undefined ? params.plot : "entities_subject",
//       period_type:
//         params.period_type !== undefined ? params.period_type : "quarter",
//       plot_elements:
//         params.plot_elements !== undefined ? params.plot_elements : true,
//       prefix: params.prefix !== undefined ? params.prefix : undefined,
//       suffix: params.suffix !== undefined ? params.suffix : undefined
//     };
//
//     if (
//       p.entity_id !== undefined &&
//       p.start_date !== undefined &&
//       p.end_date !== undefined &&
//       p.ids !== undefined
//       // && (p.state_id || p.formula_id)
//     ) {
//       return await wretch(process.env.REACT_APP_API + "/api/data/chart")
//         .json({ formula_value: p })
//         .post()
//         .json()
//         .catch(error => error);
//     }
//   };
//
//   return (
//     <AnalyticsDataContext.Provider
//       value={{
//         getScoreRanking,
//         getWidgets,
//         getRootEntity,
//         getBreadCrumbTrail,
//         getEntities,
//         getContracts,
//         getPermutedGroupSets,
//         getPublishedPeriods,
//         getElements,
//         getQuantityData,
//         getDashBoardBlock
//         // listPublishedContents
//       }}
//       {...props}
//     />
//   );
// }
//
// function useAnalytics() {
//   const context = React.useContext(AnalyticsDataContext);
//   if (context === undefined) {
//     throw new Error(`useAnalytics must be used within a AnalyticsDataProvider`);
//   }
//   return context;
// }
//
// export { AnalyticsDataProvider, useAnalytics };
//
