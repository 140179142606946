import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { publishedPeriodFormatter } from '../../utils/core';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  quarters: null,
  // expected: null,
  // completeness: null,
  year: null,
  period: null,
  // headers: null,
  missingEntities: null,
  missingPeriod: null,
  collapseAll: new Array(50).fill(false),
  rowExpanded: new Array(50).fill(false)
};

const slice = createSlice({
  name: 'reporting',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setQuarters(state, action) {
      state.isLoading = false;
      state.quarters = action.payload;
    },

    // setExpected(state, action) {
    //   state.isLoading = false;
    //   state.expected = action.payload;
    // },

    // setCompleteness(state, action) {
    //   state.isLoading = false;
    //   state.completeness = action.payload;
    // },

    setYear(state, action) {
      state.isLoading = false;
      state.year = action.payload;
    },

    // setHeaders(state, action) {
    //   state.isLoading = false;
    //   state.headers = action.payload;
    // },

    setMissingEntities(state, action) {
      state.isLoading = false;
      state.missingEntities = action.payload;
    },

    startMissingEntitiesPerStateLoading(state, action) {
      state.isLoading = true;
      state.missingEntities =
        state.missingEntities !== null &&
        state.missingEntities[action.payload.category_id] !== undefined &&
        state.missingEntities[action.payload.category_id][action.payload.data_set_id] !== undefined
          ? {
              ...state.missingEntities,
              [action.payload.category_id]: {
                ...state.missingEntities[action.payload.category_id],
                [action.payload.data_set_id]: {
                  ...state.missingEntities[action.payload.category_id][action.payload.data_set_id],
                  isLoading: true
                }
              }
            }
          : {
              [action.payload.category_id]: {
                [action.payload.data_set_id]: {
                  isLoading: true
                }
              }
            };
    },

    setMissingEntitiesPerState(state, action) {
      state.isLoading = false;
      state.missingEntities =
        state.missingEntities !== null &&
        state.missingEntities[action.payload.category_id] !== undefined &&
        state.missingEntities[action.payload.category_id][action.payload.data_set_id] !== undefined &&
        state.missingEntities[action.payload.category_id][action.payload.data_set_id][action.payload.state_id] !==
          undefined
          ? {
              ...state.missingEntities,
              [action.payload.category_id]: {
                ...state.missingEntities[action.payload.category_id],
                [action.payload.data_set_id]: {
                  ...state.missingEntities[action.payload.category_id][action.payload.data_set_id],
                  [action.payload.state_id]: {
                    completess: action.payload.response
                  },
                  isLoading: false
                }
              }
            }
          : {
              [action.payload.category_id]: {
                [action.payload.data_set_id]: {
                  [action.payload.state_id]: {
                    completess: action.payload.response
                  },
                  isLoading: false
                }
              }
            };
    },

    // setMissingPeriod(state, action) {
    //   state.isLoading = false;
    //   state.missingPeriod = action.payload;
    // },

    setCollapseAll(state, action) {
      state.isLoading = false;
      state.collapseAll = action.payload;
    },

    setRowExpanded(state, action) {
      state.isLoading = false;
      state.rowExpanded = action.payload;
    },

    setPeriod(state, action) {
      state.isLoading = false;
      state.period = action.payload;
    }
    // GET MONTHS
    // getMonths(state, action) {
    //   state.isLoading = false;
    //   state.months = action.payload;
    // }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setYear, setRowExpanded, setCollapseAll } = slice.actions;

// ----------------------------------------------------------------------

export function getExpected(params) {
  return async () => {
    const response = await axios.post('/api/data_files/expected', params);
    return response.data;
  };
}

export function getQuarters() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/data_files/quarters');

      const formattedResp = response.data.reverse().map(publishedPeriodFormatter);

      dispatch(slice.actions.setQuarters(formattedResp));
      // const response = await axios.get('/api/data_files/quarters');
      // const qUs = response.data.map((q) => ({
      //   quarterLabel: q.label,
      //   quarter: q.quarter,
      //   month: q.month,
      //   year: q.year,
      //   startDate: q.start_date,
      //   endDate: q.end_date
      // }));
      //
      // // dispatch(getCompleteness(qUs[0]));
      // dispatch(slice.actions.setYear(qUs[0].year));
      // dispatch(slice.actions.setQuarters(qUs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMissingCompleteness(params) {
  return async (dispatch) => {
    dispatch(
      slice.actions.startMissingEntitiesPerStateLoading({
        category_id: params.category_id,
        data_set_id: params.data_set_id
      })
    );
    try {
      const response = await axios.post('/api/data/completess/missing', { formula_value: params });
      dispatch(
        slice.actions.setMissingEntitiesPerState({
          response: response.data,
          category_id: params.category_id,
          data_set_id: params.data_set_id,
          state_id: params.state_id
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
