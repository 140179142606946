// import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axios';

// ----------------------------------------------------------------------

// const isValidToken = (accessToken) => {
//   if (!accessToken) {
//     return false;
//   }
//
//   const decoded = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//
//   return decoded.exp > currentTime;
// };

//  const handleTokenExpired = (exp) => {
//   let expiredTimer;

//   window.clearTimeout(expiredTimer);
//   const currentTime = Date.now();
//   const timeLeft = exp * 1000 - currentTime;
//   console.log(timeLeft);
//   expiredTimer = window.setTimeout(() => {
//     console.log('expired');
//     // You can do what ever you want here, like show a notification
//   }, timeLeft);
// };

const setSession = (params) => {
  if (params) {
    localStorage.setItem('authParams', JSON.stringify(params));
    axios.defaults.headers.common.Authorization = `Bearer ${params.tokens.tokenType}`;
    axios.defaults.headers.common['access-token'] = params.tokens.accessToken;
    axios.defaults.headers.common['token-type'] = params.tokens.tokenType;
    axios.defaults.headers.common.client = params.tokens.client;
    axios.defaults.headers.common.expiry = params.tokens.expiry;
    axios.defaults.headers.common.uid = params.tokens.uid;
    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken);
    // handleTokenExpired(exp);
  } else {
    localStorage.removeItem('authParams');
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common['access-token'];
    delete axios.defaults.headers.common['token-type'];
    delete axios.defaults.headers.common.client;
    delete axios.defaults.headers.common.expiry;
    delete axios.defaults.headers.common.uid;
  }
};

export { setSession, verify, sign };
