// material
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Stack, AppBar, Toolbar, CardHeader } from '@mui/material';
// hooks
import NotificationsPopover from './NotificationsPopover';
import AdminMenuDrawer from './AdminMenuDrawer';
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';
import { isNilOrBlank } from '../../utils/core';
// components
import Logo from '../../components/Logo';

// utils
import { bgBlur } from '../../utils/cssStyles';

// config
import { HEADER } from '../../config';

const RootStyle = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  height: HEADER.H_MOBILE,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.H_DASHBOARD_DESKTOP,
    width: `calc(100% - ${0}px)`
  }
}));

export default function DashboardNavbar() {
  const { translate: t } = useLocales();
  const { user } = useAuth();

  return (
    <RootStyle>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 }
        }}
      >
        <Box
          component="div"
          children={
            <CardHeader
              sx={{
                padding: 0
              }}
              disableTypography={false}
              titleTypographyProps={{
                component: 'h2',
                color: 'textPrimary',
                variant: 'subtitle1'
              }}
              subheaderTypographyProps={{
                color: 'textSecondary',
                variant: 'caption'
              }}
              avatar={
                <RouterLink to="/">
                  <Logo />
                </RouterLink>
              }
              title={t(`shared.projectMainTitle.${process.env.REACT_APP_PROJECT_NAME}`)}
              subheader={t(`shared.projectMainSubTitle.${process.env.REACT_APP_PROJECT_NAME}`)}
            />
          }
        />
        <Box sx={{ flexGrow: 1 }} />

        {!isNilOrBlank(user) && (
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            <NotificationsPopover />
            <AdminMenuDrawer />
          </Stack>
        )}
      </Toolbar>
    </RootStyle>
  );
}
