// routes
import { PATH_ADMIN } from '../routes/paths';
import useAuth from './useAuth';
import useLocales from './useLocales';
// ----------------------------------------------------------------------

export default function useAdminMenuConfig() {
  const { translate: t } = useLocales();
  const { ability } = useAuth();
  return [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: t('adminMenu.dataManagement'),
      items: [
        ...(ability.can('index', 'Dashboard')
          ? [{ title: t('adminMenu.dashboardLink'), path: PATH_ADMIN.dataManagement.dashboard }]
          : []),
        ...(ability.can('index', 'DataFile')
          ? [{ title: t('adminMenu.dataEntryLink'), path: PATH_ADMIN.dataManagement.dataFiles }]
          : []),
        ...(ability.can('validation', 'DataFile')
          ? [{ title: t('adminMenu.dataValidationLink'), path: PATH_ADMIN.dataManagement.validation }]
          : []),
        ...(ability.can('index', 'Invoice') &&
        ability.can('calculate', 'Invoice') &&
        ability.can('parameters', 'Invoice')
          ? [{ title: t('adminMenu.invoicingLink'), path: PATH_ADMIN.dataManagement.invoices }]
          : []),
        ...(ability.can('index', 'Export')
          ? [{ title: t('adminMenu.exportsLink'), path: PATH_ADMIN.dataManagement.exports }]
          : []),
        ...(ability.can('index', 'Sample') &&
        ability.can('create', 'Sample') &&
        ability.can('warm', 'Sample') &&
        ability.can('search', 'Sample')
          ? [{ title: t('adminMenu.samplesLink'), path: PATH_ADMIN.dataManagement.samples }]
          : [])
      ]
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: t('adminMenu.dataAdministration'),
      items: [
        ...(ability.can('index', 'Content') &&
        ability.can('show', 'Content') &&
        ability.can('update', 'Content') &&
        ability.can('create', 'Content') &&
        ability.can('kinds', 'Content') &&
        ability.can('destroy', 'Content') &&
        ability.can('publish', 'FormulaValue') &&
        ability.can('unpublish', 'FormulaValue') &&
        ability.can('status', 'FormulaValue')
          ? [
              {
                title: t('adminMenu.cmsLink'),
                path: PATH_ADMIN.cms.root,
                children: [
                  { title: t('contents.contentsMenuCMS'), path: PATH_ADMIN.cms.posts },
                  { title: t('contents.contentsMenuPublication'), path: PATH_ADMIN.cms.data }
                ]
              }
            ]
          : []),
        ...(ability.can('index', 'Content') &&
        ability.can('show', 'Content') &&
        ability.can('update', 'Content') &&
        ability.can('create', 'Content') &&
        ability.can('kinds', 'Content') &&
        ability.can('destroy', 'Content') &&
        !(
          ability.can('publish', 'FormulaValue') &&
          ability.can('unpublish', 'FormulaValue') &&
          ability.can('status', 'FormulaValue')
        )
          ? [
              {
                title: t('adminMenu.cmsLink'),
                path: PATH_ADMIN.cms.root,
                children: [{ title: t('contents.contentsMenuCMS'), path: PATH_ADMIN.cms.posts }]
              }
            ]
          : []),
        ...(ability.can('publish', 'FormulaValue') &&
        ability.can('unpublish', 'FormulaValue') &&
        ability.can('status', 'FormulaValue') &&
        !(
          ability.can('index', 'Content') &&
          ability.can('show', 'Content') &&
          ability.can('update', 'Content') &&
          ability.can('create', 'Content') &&
          ability.can('kinds', 'Content') &&
          ability.can('destroy', 'Content')
        )
          ? [
              {
                title: t('adminMenu.cmsLink'),
                path: PATH_ADMIN.cms.root,
                children: [{ title: t('contents.contentsMenuPublication'), path: PATH_ADMIN.cms.data }]
              }
            ]
          : []),
        ...(ability.can('index', 'DataSet') &&
        ability.can('create', 'DataSet') &&
        ability.can('update', 'DataSet') &&
        ability.can('destroy', 'DataSet')
          ? [
              {
                title: t('adminMenu.dataSettingsLink'),
                path: PATH_ADMIN.dataSettings.root,
                children: [
                  ...(ability.can('index', 'DataElement') &&
                  ability.can('create', 'DataElement') &&
                  ability.can('update', 'DataElement') &&
                  ability.can('destroy', 'DataElement') &&
                  ability.can('show', 'DataElement')
                    ? [{ title: t('adminMenu.dataElementsLink'), path: PATH_ADMIN.dataSettings.elements }]
                    : []),
                  ...(ability.can('index', 'DataSet') &&
                  ability.can('create', 'DataSet') &&
                  ability.can('update', 'DataSet') &&
                  ability.can('destroy', 'DataSet') &&
                  ability.can('show', 'DataSet')
                    ? [{ title: t('adminMenu.fileTypesLink'), path: PATH_ADMIN.dataSettings.dataSets }]
                    : []),
                  ...(ability.can('index', 'State') &&
                  ability.can('index', 'DataSet') &&
                  ability.can('index', 'CustomFrequency') &&
                  ability.can('create', 'CustomFrequency') &&
                  ability.can('update', 'CustomFrequency') &&
                  ability.can('destroy', 'CustomFrequency') &&
                  ability.can('show', 'CustomFrequency')
                    ? [{ title: t('adminMenu.customFrequenciesLink'), path: PATH_ADMIN.dataSettings.customFrequencies }]
                    : []),
                  ...(ability.can('index', 'Incentive') &&
                  ability.can('create', 'Incentive') &&
                  ability.can('update', 'Incentive') &&
                  ability.can('destroy', 'Incentive') &&
                  ability.can('show', 'Incentive')
                    ? [{ title: t('adminMenu.incentivesLink'), path: PATH_ADMIN.dataSettings.incentives }]
                    : []),
                  ...(ability.can('index', 'Rule') &&
                  ability.can('create', 'Rule') &&
                  ability.can('update', 'Rule') &&
                  ability.can('destroy', 'Rule') &&
                  ability.can('show', 'Rule')
                    ? [{ title: t('adminMenu.paymentRulesLink'), path: PATH_ADMIN.dataSettings.paymentRules }]
                    : []),
                  ...(ability.can('index', 'State') &&
                  ability.can('create', 'State') &&
                  ability.can('update', 'State') &&
                  ability.can('destroy', 'State') &&
                  ability.can('show', 'State')
                    ? [{ title: t('adminMenu.statesLink'), path: PATH_ADMIN.dataSettings.states }]
                    : []),
                  ...(ability.can('index', 'DataElementGroup') &&
                  ability.can('create', 'DataElementGroup') &&
                  ability.can('update', 'DataElementGroup') &&
                  ability.can('destroy', 'DataElementGroup') &&
                  ability.can('show', 'DataElementGroup')
                    ? [{ title: t('adminMenu.dataElementGroupsLink'), path: PATH_ADMIN.dataSettings.elementGroups }]
                    : []),
                  ...(ability.can('index', 'Invoice') &&
                  ability.can('create', 'Invoice') &&
                  ability.can('update', 'Invoice') &&
                  ability.can('show', 'Invoice')
                    ? [{ title: t('adminMenu.invoiceSetupLink'), path: PATH_ADMIN.dataSettings.invoicesTypes }]
                    : []),
                  ...(ability.can('index', 'WorkFlow') &&
                  ability.can('create', 'WorkFlow') &&
                  ability.can('update', 'WorkFlow') &&
                  ability.can('show', 'WorkFlow')
                    ? [{ title: t('adminMenu.workFlowSetupLink'), path: PATH_ADMIN.dataSettings.workFlows }]
                    : []),
                  ...(ability.can('index', 'Client') && ability.can('update', 'Client') && ability.can('show', 'Client')
                    ? [{ title: t('adminMenu.integrationLink'), path: PATH_ADMIN.dataSettings.integration }]
                    : [])
                ]
              }
            ]
          : []),
        ...(ability.can('index', 'Entity') &&
        ability.can('create', 'Entity') &&
        ability.can('update', 'Entity') &&
        ability.can('destroy', 'Entity') &&
        ability.can('show', 'Entity')
          ? [
              {
                title: t('adminMenu.pyramidLink'),
                path: PATH_ADMIN.pyramidSettings.root,
                children: [
                  ...(ability.can('index', 'Entity') &&
                  ability.can('create', 'Entity') &&
                  ability.can('update', 'Entity') &&
                  ability.can('destroy', 'Entity') &&
                  ability.can('show', 'Entity')
                    ? [{ title: t('adminMenu.entitiesLink'), path: PATH_ADMIN.pyramidSettings.entities }]
                    : []),
                  ...(ability.can('index', 'Group') &&
                  ability.can('create', 'Group') &&
                  ability.can('update', 'Group') &&
                  ability.can('destroy', 'Group') &&
                  ability.can('show', 'Group')
                    ? [{ title: t('adminMenu.groupsLink'), path: PATH_ADMIN.pyramidSettings.groups }]
                    : []),
                  ...(ability.can('index', 'GroupSet') &&
                  ability.can('create', 'GroupSet') &&
                  ability.can('update', 'GroupSet') &&
                  ability.can('destroy', 'GroupSet') &&
                  ability.can('show', 'GroupSet')
                    ? [{ title: t('adminMenu.groupSetsLink'), path: PATH_ADMIN.pyramidSettings.groupSets }]
                    : []),
                  ...(ability.can('index', 'Bank') &&
                  ability.can('create', 'Bank') &&
                  ability.can('update', 'Bank') &&
                  ability.can('destroy', 'Bank') &&
                  ability.can('show', 'Bank')
                    ? [{ title: t('adminMenu.banksLink'), path: PATH_ADMIN.pyramidSettings.banks }]
                    : []),
                  ...(ability.can('index', 'Stage') &&
                  ability.can('create', 'Stage') &&
                  ability.can('update', 'Stage') &&
                  ability.can('destroy', 'Stage') &&
                  ability.can('show', 'Stage')
                    ? [{ title: t('adminMenu.levelsLink'), path: PATH_ADMIN.pyramidSettings.levels }]
                    : [])
                ]
              }
            ]
          : []),
        ...(ability.can('index', 'User') &&
        ability.can('create', 'User') &&
        ability.can('update', 'User') &&
        ability.can('destroy', 'User')
          ? [
              {
                title: t('adminMenu.aclLink'),
                path: PATH_ADMIN.aclSettings.root,
                children: [
                  ...(ability.can('index', 'User') &&
                  ability.can('create', 'User') &&
                  ability.can('update', 'User') &&
                  ability.can('destroy', 'User')
                    ? [{ title: t('adminMenu.usersLink'), path: PATH_ADMIN.aclSettings.users }]
                    : []),
                  ...(ability.can('index', 'Role') &&
                  ability.can('create', 'Role') &&
                  ability.can('update', 'Role') &&
                  ability.can('destroy', 'Role') &&
                  ability.can('show', 'Role')
                    ? [{ title: t('adminMenu.rolesLink'), path: PATH_ADMIN.aclSettings.roles }]
                    : [])
                ]
              }
            ]
          : [])
      ]
    }
  ];
}
