import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';

// material
import { styled, useTheme } from '@mui/material/styles';
import { Box, Link, Typography, Grid, Paper, Avatar } from '@mui/material';
// routes
// import { PATH_AUTH } from '../../routes/paths';
// hooks
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';

// components
import Page from '../../components/Page';
import { LoginForm, RegisterForm, ResetPasswordForm, NewPasswordForm } from '../../components/authentication';
import LanguagePopover from '../../layouts/LanguagePopover';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

// ----------------------------------------------------------------------

export default function Login() {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const { confirm, validateToken } = useAuth();
  const { translate: t } = useLocales();
  const [form, setForm] = useState({ signIn: true, signUp: false, reset: false, resetForm: false });
  const [resetHeaders, setResetHeaders] = useState(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const availableLanguages = process.env.REACT_APP_AVAILABLE_LOCALE.split('|');

  const confirmUserAccount = async (params) => {
    // setForm({ signIn: false, signUp: false, reset: false });
    setIsConfirming(true);
    const resp = await confirm(params);
    if (resp === true) {
      setIsConfirming(false);
      // setForm({ signIn: true, signUp: false, reset: false, resetForm: false });
      navigate('/auth/login', { replace: true });
    }
  };

  const validatePasswordResetToken = async (params) => {
    setIsResetting(true);
    const resp = await validateToken(params);
    if (resp !== undefined) {
      setResetHeaders(resp);
      setIsResetting(false);
      setForm({ signIn: false, signUp: false, reset: false, resetForm: true });
    }
  };

  useEffect(
    () => {
      if (params.confirmationToken !== null && params.confirmationToken !== undefined) {
        confirmUserAccount({ token: params.confirmationToken });
      }
      if (params.resetToken !== null && params.resetToken !== undefined) {
        validatePasswordResetToken({ token: params.resetToken });
      }
    },
    [params] // eslint-disable-line
  );

  return (
    <RootStyle
      title={`${t(`shared.projectMainTitle.${process.env.REACT_APP_PROJECT_NAME}`)} - ${t(
        `shared.projectMainSubTitle.${process.env.REACT_APP_PROJECT_NAME}`
      )}`}
    >
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={false} sm={5} md={7} />
        <Grid item xs={12} sm={7} md={5} component={Paper} elevation={6} square>
          {availableLanguages.length > 1 && (
            <Box
              component="div"
              sx={{
                alignSelf: 'flex-end',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                mt: 1,
                mx: 2,
                display: {
                  md: 'flex'
                }
              }}
            >
              <LanguagePopover availableLanguages={availableLanguages} />
            </Box>
          )}
          <Box
            sx={{
              mt: 8,
              mb: 2,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Avatar
              sx={{ width: theme.spacing(12), height: theme.spacing(12) }}
              variant="rounded"
              src={`/${process.env.REACT_APP_LOGO}`}
            />
            <Typography component="h1" variant="subtitle1">
              {t(`shared.projectMainTitle.${process.env.REACT_APP_PROJECT_NAME}`)}
            </Typography>
            <Typography
              gutterBottom
              component="p"
              variant="subtitle2"
              sx={{
                wordWrap: 'break-word',
                alignItems: 'center',
                alignText: 'center',
                color: 'text.secondary'
              }}
            >
              {t(`shared.projectMainSubTitle.${process.env.REACT_APP_PROJECT_NAME}`)}
            </Typography>
          </Box>
          {form.signIn && <LoginForm isConfirming={isConfirming} isResetting={isResetting} />}
          {form.signUp && <RegisterForm setForm={setForm} />}
          {form.reset && <ResetPasswordForm setForm={setForm} />}
          {form.resetForm && <NewPasswordForm resetHeaders={resetHeaders} />}
          <Box
            sx={{
              margin: theme.spacing(5, 4),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            {process.env.REACT_APP_USER_REGISTER !== undefined && process.env.REACT_APP_USER_REGISTER === 'OPEN' && (
              <>
                {(form.signIn || form.reset) && (
                  <Typography variant="body2">
                    {t('login.signUpLabel')} {''}
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => setForm({ signIn: false, signUp: true, reset: false, resetForm: false })}
                    >
                      {t('login.signUpTitle')}
                    </Link>
                  </Typography>
                )}
                {(form.signUp || form.reset) && (
                  <Typography variant="body2">
                    {t('login.signInLabel')} {''}
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => setForm({ signIn: true, signUp: false, reset: false, resetForm: false })}
                    >
                      {t('login.signInTitle')}
                    </Link>
                  </Typography>
                )}
                {(form.signUp || form.signIn) && (
                  <Typography variant="body2">
                    {t('login.forgotPasswordLabel')} {''}
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => setForm({ signIn: false, signUp: false, reset: true, resetForm: false })}
                    >
                      {t('login.forgotPasswordLink')}
                    </Link>{' '}
                  </Typography>
                )}
              </>
            )}
            <Link component={RouterLink} variant="body2" to="/">
              {t('login.backToPublicLink')}
            </Link>
          </Box>
        </Grid>
      </Grid>
    </RootStyle>
  );
}
