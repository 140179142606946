// @mui
import { enUS, fr, km } from 'date-fns/locale';

// routes
import { PATH_ADMIN } from './routes/paths';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const mapConfig = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_ADMIN.dataManagement.dashboard; // as '/dashboard/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 70,
  H_DASHBOARD_DESKTOP: 70,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 70,
  H_DASHBOARD_ITEM_SUB: 40,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,

  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22
};

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  // themeColorPresets: 'default',
  themeColorPresets: process.env.REACT_APP_THEME_CODE,
  themeColor: process.env.REACT_APP_THEME_CODE,
  themeStretch: false
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS
  },
  {
    label: 'Français',
    value: 'fr',
    systemValue: fr
  },
  {
    label: 'Khmer',
    value: 'km',
    systemValue: km
  }
];

export const defaultLang = allLangs.find((lang) => lang.value === process.env.REACT_APP_LOCALE);
