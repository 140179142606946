import { createContext, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Ability } from '@casl/ability';
import PropTypes from 'prop-types';
import useLocales from '../hooks/useLocales';
// utils
import axios from '../utils/axios';

// import { MIconButton } from '../components/@material-extend';
import { IconButtonAnimate } from '../components/animate';
import Iconify from '../components/Iconify';
import { setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  ability: new Ability([]),
  user: null,
  authParams: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, authParams, ability } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      authParams,
      ability
    };
  },
  LOGIN: (state, action) => {
    const { user, authParams, ability } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      authParams,
      ability
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    ability: new Ability([])
  })
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  //  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const { pathname } = useLocation();
  const { translate: t } = useLocales();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const initialize = async () => {
      try {
        const authParams = JSON.parse(window.localStorage.getItem('authParams')); // why windows?

        if (authParams !== null && authParams !== undefined) {
          axios.defaults.headers.common['access-token'] = authParams.tokens.accessToken;
          axios.defaults.headers.common['token-type'] = authParams.tokens.tokenType;
          axios.defaults.headers.common.client = authParams.tokens.client;
          axios.defaults.headers.common.expiry = authParams.tokens.expiry;
          axios.defaults.headers.common.uid = authParams.tokens.uid;
          //  if (pathname.includes('/admin') || pathname.includes('/auth')) {
          await axios
            .post('/api/users/me')
            .then((response) => {
              if ([200, 201].includes(response.status)) {
                // console.log(authParams.userInfo.permissions);
                dispatch({
                  type: 'INITIALIZE',
                  payload: {
                    isAuthenticated: true,
                    user: response.data,
                    authParams,
                    ability: new Ability(authParams.userInfo.permissions)
                  }
                });

                setSession(authParams); // Why do I need this again ?
              }
            })
            .catch((error) => {
              console.log(error);
              window.localStorage.removeItem('authParams');
              if (pathname.includes('/admin') || pathname.includes('/auth')) {
                prossessedResponse({ status: 401, message: t('APIcompound.unauthorized'), code: 'warning' });
              }
            });
          // }
          // else {
          //   dispatch({
          //     type: 'INITIALIZE',
          //     payload: {
          //       isAuthenticated: false,
          //       user: null
          //     }
          //   });
          // }
        } else {
          if (pathname.includes('/admin') || pathname.includes('/auth')) {
            prossessedResponse({ status: 401, message: t('APIcompound.missingTokens'), code: 'warning' });
          }
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              ability: new Ability([])
            }
          });
        }
      } catch (err) {
        // console.error(err);

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            ability: new Ability([])
          }
        });
      }
    };

    initialize();
  }, []); // eslint-disable-line

  // const axiosWithAuthParams = (params = undefined) => {
  //   const p = params === undefined ? state.authParams : params;
  //
  //   axios.defaults.headers.common['access-token'] = p.accessToken;
  //   axios.defaults.headers.common['token-type'] = p.tokenType;
  //   axios.defaults.headers.common.client = p.client;
  //   axios.defaults.headers.common.expiry = p.expiry;
  //   axios.defaults.headers.common.uid = p.uid;
  //
  //   return axios;
  // };

  const prossessedResponse = (response) => {
    if ([401].includes(response.status)) {
      enqueueSnackbar(response.message, {
        variant: response.code,
        action: (key) => (
          <IconButtonAnimate size="small" onClick={() => closeSnackbar(key)}>
            <Iconify icon={'eva:close-fill'} />
          </IconButtonAnimate>
        )
      });

      setSession(null);
      localStorage.removeItem('authParams'); // Double removal as it was removed by setSession
      dispatch({ type: 'LOGOUT' });

      return [];
    }
    if ([201, 200, 422].includes(response.status)) {
      enqueueSnackbar(response.message, {
        variant: response.code,
        action: (key) => (
          <IconButtonAnimate size="small" onClick={() => closeSnackbar(key)}>
            <Iconify icon={'eva:close-fill'} />
          </IconButtonAnimate>
        )
      });
    }
    return response;
  };

  const logIn = async (params) => {
    await axios
      .post('/api/users/sign_in', params)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          const authParams = {
            tokens: {
              accessToken: response.headers['access-token'],
              tokenType: response.headers['token-type'],
              client: response.headers.client,
              expiry: response.headers.expiry,
              uid: response.headers.uid
            },
            userInfo: response.data.data
          };
          setSession(authParams);
          prossessedResponse({ status: 200, message: t('APIcompound.connectSuccess'), code: 'success' });
          dispatch({
            type: 'LOGIN',
            payload: {
              user: response.data.data,
              authParams,
              ability: new Ability(authParams.userInfo.permissions)
            }
          });
        }

        // console.log(response.data);
        // console.log(response.status);
        // console.log(response.statusText);
        // console.log(response.headers);
        // console.log(response.config);
        // if (isMountedRef.current) {
        //   setSubmitting(false);
        // }
      })
      .catch((error) => {
        //  console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //  JSON.parse(response.message).errors.join(". ")
          // console.log('Error', error.errors.join('. '));

          enqueueSnackbar(error.errors.join('. '), {
            variant: 'error',
            action: (key) => (
              <IconButtonAnimate size="small" onClick={() => closeSnackbar(key)}>
                <Iconify icon={'eva:close-fill'} />
              </IconButtonAnimate>
            )
          });
        }
      });
  };

  const logOut = async () => {
    await axios
      .delete('/api/users/sign_out')
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          setSession(null);
          dispatch({ type: 'LOGOUT' });
          prossessedResponse({ status: 200, message: t('APIcompound.disconnectSuccess'), code: 'info' });
        }
      })
      .catch((error) => {
        // console.log(error);
        if (error.response) {
          // console.log(error.response);
        } else if (error.request) {
          // console.log(error.request);
        } else {
          // console.log('Error', error.errors.join('. '));
          enqueueSnackbar(error.errors.join('. '), {
            variant: 'error',
            action: (key) => (
              <IconButtonAnimate size="small" onClick={() => closeSnackbar(key)}>
                <Iconify icon={'eva:close-fill'} />
              </IconButtonAnimate>
            )
          });
        }
      });
  };

  const register = async (params) =>
    axios
      .post('/api/users', params)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          prossessedResponse({ status: 200, message: t('APIcompound.registerUserSuccess'), code: 'success' });
        }
        return true;
      })
      .catch((error) => {
        // console.log(error);
        if (error.response) {
          // console.log(error.response);
        } else if (error.request) {
          // console.log(error.request);
        } else {
          // console.log(Object.values(error.errors).flat());
          enqueueSnackbar(`(${params.email}) ${Object.values(error.errors).flat().join('. ')}`, {
            variant: 'error',
            action: (key) => (
              <IconButtonAnimate size="small" onClick={() => closeSnackbar(key)}>
                <Iconify icon={'eva:close-fill'} />
              </IconButtonAnimate>
            )
          });
        }
        return false;
      });

  const confirm = async (params) =>
    axios
      .get(`/api/users/confirmation?confirmation_token=${params.token}`)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          prossessedResponse({ status: 200, message: t('APIcompound.confirmUserSuccess'), code: 'success' });
        }
        return true;
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          // console.log(error.response);
        } else if (error.request) {
          // console.log(error.request);
        } else {
          // console.log('Error', error.errors.join('. '));
          enqueueSnackbar(error.errors.join('. '), {
            variant: 'error',
            action: (key) => (
              <IconButtonAnimate size="small" onClick={() => closeSnackbar(key)}>
                <Iconify icon={'eva:close-fill'} />
              </IconButtonAnimate>
            )
          });
        }
        return false;
      });

  const validateToken = async (params) =>
    axios
      .get(`/api/users/password/edit?reset_password_token=${params.token}`)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          prossessedResponse({ status: 200, message: t('APIcompound.resetPasswordFormSuccess'), code: 'success' });
        }
        return response.data;
      })
      .catch((error) => {
        // console.log(error);
        if (error.response) {
          // console.log(error.response);
        } else if (error.request) {
          // console.log(error.request);
        } else {
          // console.log('Error', error.errors.join('. '));
          enqueueSnackbar(error.errors.join('. '), {
            variant: 'error',
            action: (key) => (
              <IconButtonAnimate size="small" onClick={() => closeSnackbar(key)}>
                <Iconify icon={'eva:close-fill'} />
              </IconButtonAnimate>
            )
          });
        }
        return error.errors;
      });

  const passwordResetRequest = async (params) =>
    axios
      .post('/api/users/password', params)
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          prossessedResponse({ status: 200, message: t('APIcompound.passwordResetSuccess'), code: 'success' });
        }
        return true;
      })
      .catch((error) => {
        //  console.log(error);
        if (error.response) {
          // console.log(error.response);
        } else if (error.request) {
          // console.log(error.request);
        } else {
          // console.log('Error', error.errors.join('. '));
          enqueueSnackbar(error.errors.join('. '), {
            variant: 'error',
            action: (key) => (
              <IconButtonAnimate size="small" onClick={() => closeSnackbar(key)}>
                <Iconify icon={'eva:close-fill'} />
              </IconButtonAnimate>
            )
          });
        }
        return false;
      });

  const updatePassword = async (params) =>
    axios
      .put('/api/users/password', params.auth, {
        headers: {
          Authorization: 'Bearer Bearer',
          'access-token': params.headers['access-token'],
          'token-type': 'Bearer',
          client: params.headers.client,
          expiry: params.headers.expiry,
          uid: params.headers.uid
        }
      })
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          prossessedResponse({ status: 200, message: t('APIcompound.passwordUpdateSuccess'), code: 'success' });
        }
        return true;
      })
      .catch((error) => {
        //  console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          //  JSON.parse(response.message).errors.join(". ")
          // console.log('Error', error.errors.join('. '));

          enqueueSnackbar(error.errors.join('. '), {
            variant: 'error',
            action: (key) => (
              <IconButtonAnimate size="small" onClick={() => closeSnackbar(key)}>
                <Iconify icon={'eva:close-fill'} />
              </IconButtonAnimate>
            )
          });
        }
        return false;
      });

  const updateProfile = async (params, handleFormClose, t) => {
    await axios
      .post('/api/users/profile', { user: params })
      .then((response) => {
        if ([200, 201].includes(response.status)) {
          handleFormClose();
          enqueueSnackbar(t('APIcompound.profileUpdateSuccess'), {
            variant: 'success',
            action: (key) => (
              <IconButtonAnimate size="small" onClick={() => closeSnackbar(key)}>
                <Iconify icon={'eva:close-fill'} />
              </IconButtonAnimate>
            )
          });

          setTimeout(async () => {
            await logOut(t);
          }, 6000);
        }
      })
      .catch((error) => {
        console.log(error);
        prossessedResponse({ status: 401, message: t('APIcompound.unauthorized'), code: 'warning' });
      });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logIn,
        logOut,
        register,
        confirm,
        passwordResetRequest,
        validateToken,
        updateProfile,
        updatePassword,
        prossessedResponse
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
